import { Typography } from "@material-ui/core";
import React from "react";

interface StepContentProps {
  title: string;
  message: string;
}
export const StepContent = ({ title, message }: StepContentProps) => {
  return (
    <div
      style={{
        backgroundColor: "#000",
        opacity: "70%",
        color: "#fff",
        borderRadius: "16px",
        padding: "25px 16px",
        textAlign: "center",
      }}
    >
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body1">{message}</Typography>
    </div>
  );
};
