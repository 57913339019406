import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { inputClasses } from "app/tccc-theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogOverActions: {
      justifyContent: "center",
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
  })
);
export const MessageDialog = ({ message, link, text, setIsDialogActive }: { message: string; link: string; text:string; setIsDialogActive: (value: boolean) => void }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  
  const onClickHandler = () => {
    setIsDialogActive(false);
    link.length > 0 
      ? window.location.assign(link)
      :setIsOpen(false);
  } 
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <DialogContentText color={"textPrimary"}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogOverActions}>
        <Button
          className={classes.mobileStylePrimary}
          color="primary"
          onClick={() => onClickHandler()}
        >
          {text}
        </Button>
      </DialogActions>
      </Dialog>
  );
};
