import { Grid, makeStyles, Typography } from "@material-ui/core";
import CartIllustration from "assets/cart.svg";
import AlcoholesIcon from "assets/icons/alcoholes.svg";
import CombosIcon from "assets/icons/combos.svg";
import LacteosIcon from "assets/icons/lacteos.svg";
import GlobeIllustration from "assets/globe_transparent.svg";
import AmericaIllustration from "assets/america.svg";
import AfricaIllustration from "assets/africa.svg";
import CokeLoading from "assets/coke_loading.svg";
import "assets/loader.css";
import { default as React } from "react";

export enum LoaderType {
  Products = 1, //Pending delete after VIS definition
  Checkout = 2, //Pending delete after VIS definition
  Address = 3, //Pending delete after VIS definition
  Authentication = 4
}

const isMobile = window.screen.width <= 768;
const useStyles = makeStyles((theme) => ({
  fpContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    zIndex: 99999,
    background: "#717171AD",
    alignContent: "center",
  },
  fpLoader: {
    top: "10%",
    left: "",
    zIndex: 1000,
    position: "relative",
  },
  caption: {
    left: "0px",
    zIndex: 1000,
    position: "fixed",
    color: "white",
    textAlign: "center",
    width: "100%",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  cartImage: {
    width: isMobile ? "180px" : "200px",
    margin: "auto",
  },
  authenticationImage: {
    width: isMobile ? "70px" : "120px",
    margin: "auto",
  },
  cartProducts: {
    height: "20px",
    width: "70px",
    left: "120px",
    right: "0px",
    top: isMobile ? "150px" : "160px",
    position: "absolute",
    margin: "auto",
    zIndex: -1,
  },
  cartStock: {
    width: isMobile ? "25px" : "30px",
  },
  cartAdd: {
    width: isMobile ? "30px" : "35px",
    height: isMobile ? "30px" : "35px",
  },
  globe: {
    height: isMobile ? "124px" : "138px",
    width: isMobile ? "150px" : "155px",
    left: "0px",
    right: "0px",
    top: "53px",
    position: "absolute",
    margin: "auto",
    zIndex: -1,
    backgroundColor: "white",
    borderRadius: "150px"
  },
  africa: {
    right: isMobile ? "5px" : "-1px",
    top: isMobile ? "9px" : "10px",
    position: "absolute",
    width: isMobile ? "77px" : "85px",
  },
  america: {
    left: isMobile ? "13px" : "9px",
    position: "absolute",
    width: isMobile ? "77px" : "85px",
  },
}));

export const FullPageLoader = ({ text, type }: { text: string, type:LoaderType }) => {
  const classes = useStyles();

  var loader: any = undefined;
  switch(type) {
    case LoaderType.Address:
      loader = (
        <div id="loader">
          <Grid item className={classes.cartImage}>
            <div className={classes.globe}>
              <img id="africa" className={classes.africa} alt="cart" src={AfricaIllustration} />
              <img id="america" className={classes.america} alt="cart" src={AmericaIllustration} />
            </div>
            <img className={classes.cartImage} alt="globe" src={GlobeIllustration} />
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.caption}>
              {text}
            </Typography>
          </Grid>
        </div>
      )
      break;
    case LoaderType.Authentication:
      loader = (
        <div id="loader">
          <Grid item className={classes.authenticationImage}>
            <div>
              <img id="authetication" alt="cart" src={CokeLoading} />  
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h5" className={classes.caption}>
              {text}
            </Typography>
          </Grid>
        </div>
      )
      break;
    case LoaderType.Checkout:
    case LoaderType.Products:
    default:
      loader = (
        <div id="loader">
          <Grid item className={classes.cartImage}>
            <div className={classes.cartProducts}>
              <img id="product1" className={classes.cartStock} alt="cart" src={AlcoholesIcon} />
              <img id="product2" className={classes.cartStock} alt="cart" src={LacteosIcon} />
            </div>
            <img id="product" className={classes.cartAdd} alt="cart" src={CombosIcon} />
            <img className={classes.cartImage} alt="cart" src={CartIllustration} />
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.caption}>
              {text}
            </Typography>
          </Grid>
        </div>
      )
      break;
  }
  return (
    <Grid className={classes.fpContainer}>
      {loader}
    </Grid>
  );
};
