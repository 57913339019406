import { Tooltip, Typography, makeStyles } from "@material-ui/core";
import { ReturnableTypes } from "models/returnableType";
import React from "react";

const useStyles = makeStyles((theme) => ({
  returnableLiquid: {
    backgroundColor: "#9AECB8",
    color: "black",
    borderRadius: "4px",
    textTransform: "none",
    height: "100%",
    padding: "3px",
  },
  returnableStarterPack: {
    backgroundColor: `#FFD673`,
    color: "white",
    borderRadius: "4px",
    padding: "3px",
    textTransform: "none",
    height: "100%",
  },
  text: {
    textAlign: "left",
    justifyContent: "left",
    color: "#202020",
    textTransform: "none",
    whiteSpace: "nowrap",
    lineHeight: "15px",
  },
}));

export const ReturnableBadge = ({ returnableType }: { returnableType: ReturnableTypes }) => {
  const classes = useStyles();

  const tooltipTexts = {
    Liquid:
      "El precio incluye solo líquido, no incluye envase. Si ya tienes envases retornables ahorra en cada pedido. Para más información consulta nuestro menú de FAQ (Retornables) o nuestras políticas de envío.",
    StarterPack:
      "El precio incluye líquido más envase. Para más información consulta nuestro menú de FAQ (Retornables) o nuestras políticas de envío.",
    Container: undefined,
  };

  const badgeTexts = {
    Liquid: "Solo Líquido",
    StarterPack: "Líquido + Envase",
    Container: undefined,
  };

  const tooltipClasses = {
    Liquid: classes.returnableLiquid,
    StarterPack: classes.returnableStarterPack,
    Container: undefined,
  };

  return (
    <Tooltip
      className={tooltipClasses[returnableType]}
      title={<Typography variant="caption">{tooltipTexts[returnableType]}</Typography>}
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={3000}
    >
      <Typography variant="subtitle2" className={classes.text}>
        {badgeTexts[returnableType]}
      </Typography>
    </Tooltip>
  );
};
