import { Grid, SwipeableDrawer, Toolbar, makeStyles } from "@material-ui/core";
import timesIcon from "assets/cancelIcon.svg";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { CategoryDrawerList } from "./CategoryDrawerList";
import { LogoMain } from "./LogoMain";

const useStyles = makeStyles((theme) => ({
  list: {
  },
  logo: {
    height: "29.01px",
    width: "199px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "280px",
    },
  },
}));

export interface CategoryDrawerHandle {
  toggle: (open: boolean) => void;
  isDrawerOpen: boolean;
}
export const CategoryDrawer = forwardRef<CategoryDrawerHandle>(({}, ref) => {
  const classes = useStyles();
  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const drawerRef = useRef();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  useImperativeHandle(ref, () => ({
    toggle: (open: boolean) => {
      setIsDrawerOpen(open);
    },
    isDrawerOpen,
  }));

  const getAddressListHeight = () => {
    const addressList = document.getElementById("address-list");
    if (addressList) {
      return addressList.clientHeight;
    }
    return 0;
  };

  const handleCategorySelected = () => {
    setIsDrawerOpen(false);
  };

  return (
    <SwipeableDrawer
      ModalProps={{
        BackdropProps: {
          style: {
            height: `calc(100% - ${getAddressListHeight() + 1}px)`,
            top: getAddressListHeight() + 1,
          },
        },
      }}
      PaperProps={{
        style: {
          height: `calc(100% - ${getAddressListHeight() + 1}px)`,
          top: getAddressListHeight() + 1,
          backgroundColor: "#fafafa",
          boxShadow: "none",
          width: "75%",
          maxWidth: "340px",
          minWidth: "270px"
        },
      }}
      ref={drawerRef}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="left"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div className={classes.list} role="presentation">
        <Toolbar style={{ paddingTop: "8px", paddingLeft: "8px", paddingRight: "16px" }}>
          <Grid
            container
            direction="row"
            style={{ marginTop: "10px" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <LogoMain className={classes.logo} />
            </Grid>
            <Grid
              item
              style={{
                marginTop: "auto"
              }}
            >
              <img alt="close" onClick={toggleDrawer(false)} src={timesIcon} />
            </Grid>
          </Grid>
        </Toolbar>
        <CategoryDrawerList
          onCategorySelected={() => {
            handleCategorySelected();
          }}
        />
      </div>
    </SwipeableDrawer>
  );
});
