import { useScroll } from "features/hooks/useScroll";
import { ProductDetail } from "features/main/ProductDetail";
import React, { useEffect } from "react";

export const ProductDetailsPage = () => {
  const { scrollToTop } = useScroll();
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <ProductDetail />
    </>
  );
};
