import './sdk/cds-sdk-v6.0.0.min';

const socialSettingsStyle ={
  format: 'one column',
  fontColor: '#000000',
  fontFamily: 'arial',
  backgroundColor: '#FFFFFF',
  width: '340',
  modalBorderColor: '#000000',
  modalBorderRadius: '7',
  modalBorderWidth: '4',
  modalBorderOpacity: '0.200000003',
  buttonBorderColor: '#FFFFFF',
  buttonBorderRadius: '4',
  buttonBackgroundStyle: 'white'
};

const overlayConfig = {
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  animation: 'https://mcstaging.coca-colaentuhogar.com/static/version1629822532/frontend/FortyFour/mx-cceth/es_MX/images/loader-1.gif'
};

export const getConfigCDS = (country_id: string) => {
  const CDS_SITE_NAME = process.env['REACT_APP_CDS_SITE_NAME_' + country_id] ? process.env['REACT_APP_CDS_SITE_NAME_' + country_id] : '';
  const CDS_SITE_ID = process.env['REACT_APP_CDS_SITE_ID_' + country_id] ? process.env['REACT_APP_CDS_SITE_ID_' + country_id] : '';
  const CDS_API_KEY = process.env['REACT_APP_CDS_API_KEY_' + country_id] ? process.env['REACT_APP_CDS_API_KEY_' + country_id] : '';
  const CDS_REGION = process.env['REACT_APP_CDS_REGION_' + country_id] ? process.env['REACT_APP_CDS_REGION_' + country_id] : '';
  const CDS_ENVIRONMENT = process.env['REACT_APP_CDS_ENVIRONMENT_' + country_id] ? process.env['REACT_APP_CDS_ENVIRONMENT_' + country_id] : '';
  const CDS_CIAM_CLIENT_ID = process.env['REACT_APP_CDS_CIAM_CLIENT_ID_' + country_id] ? process.env['REACT_APP_CDS_CIAM_CLIENT_ID_' + country_id] : '';
  const CDS_RE_CAPTCHA_KEY = process.env['REACT_APP_CDS_RE_CAPTCHA_KEY_' + country_id] ? process.env['REACT_APP_CDS_RE_CAPTCHA_KEY_' + country_id]: ''; // this key is generated by recapchat google account bounteous team -kornchai.anujhun@fortyfour.com- if you want to replace it, contact kornchai
  const CDS_BUNDLE_HOST = process.env['REACT_APP_CDS_BUNDLE_HOST_' + country_id] ? process.env['REACT_APP_CDS_BUNDLE_HOST_' + country_id] : '';
  const CDS_BUNDLE_PATH = process.env['REACT_APP_CDS_BUNDLE_PATH_' + country_id] ? process.env['REACT_APP_CDS_BUNDLE_PATH_' + country_id] : '';
  const CDS_CONFIG_BUCKET_PATH = process.env['REACT_APP_CDS_CONFIG_BUCKET_PATH_' + country_id] ? process.env['REACT_APP_CDS_CONFIG_BUCKET_PATH_' + country_id] : '';
  const CDS_LOCALE = process.env['REACT_APP_CDS_LOCALE_' + country_id] ? process.env['REACT_APP_CDS_LOCALE_' + country_id] : '';
  //const CDS_SECURE_PROXY_ID = process.env['REACT_APP_CDS_SECURE_PROXY_ID_' + country_id] ? process.env['REACT_APP_CDS_SECURE_PROXY_ID_' + country_id] : '';
  const CDS_SOCIAL_NATIVE = process.env['REACT_APP_CDS_SOCIAL_NATIVE_' + country_id] ? process.env['REACT_APP_CDS_SOCIAL_NATIVE_' + country_id] : '';
  const CDS_FACEBOOK_APP_ID = process.env['REACT_APP_CDS_FACEBOOK_APP_ID_' + country_id] ? process.env['REACT_APP_CDS_FACEBOOK_APP_ID_' + country_id] : '';
  const CDS_FACEBOOK_VERSION = process.env['REACT_APP_CDS_FACEBOOK_VERSION_' + country_id] ? process.env['REACT_APP_CDS_FACEBOOK_VERSION_' + country_id] : '';
  const CDS_SOCIAL_SETTINGS_APP_ID = process.env['REACT_APP_CDS_SOCIAL_SETTINGS_APP_ID_' + country_id] ? process.env['REACT_APP_CDS_SOCIAL_SETTINGS_APP_ID_' + country_id] : '';
  const CDS_SOCIAL_SETTINGS_ENGAGE_DOMAIN = process.env['REACT_APP_CDS_SOCIAL_SETTINGS_ENGAGE_DOMAIN_' + country_id] ? process.env['REACT_APP_CDS_SOCIAL_SETTINGS_ENGAGE_DOMAIN_' + country_id] : '';
  const CDS_SOCIAL_SETTINGS_PROVIDERS = process.env['REACT_APP_CDS_SOCIAL_SETTINGS_PROVIDERS_' + country_id] ? process.env['REACT_APP_CDS_SOCIAL_SETTINGS_PROVIDERS_' + country_id] : '';
  //const CDS_WIDGE_TTYPE = process.env['REACT_APP_CDS_WIDGE_TTYPE_' + country_id] ? process.env['REACT_APP_CDS_WIDGE_TTYPE_' + country_id] : '';

  var result = {
    siteName: CDS_SITE_NAME,
    siteID: CDS_SITE_ID,
    apiKey: CDS_API_KEY,
    region: CDS_REGION,
    environment: CDS_ENVIRONMENT,
    locale: CDS_LOCALE,
    ciamClientId: CDS_CIAM_CLIENT_ID,
    reCaptchakey: CDS_RE_CAPTCHA_KEY,
    bundleHost: CDS_BUNDLE_HOST,
    bundlePath: CDS_BUNDLE_PATH,
    configBucketPath: CDS_CONFIG_BUCKET_PATH,
    showPasswordButton : true,
    socialNative: (CDS_SOCIAL_NATIVE === 'true'),
    //googleClientId: "<your-google-client-id>",
    facebookAppId: CDS_FACEBOOK_APP_ID,
    facebookVersion: CDS_FACEBOOK_VERSION,
  
    // Possibly not needed when socialNative === true
    socialSettings: {
      appId: CDS_SOCIAL_SETTINGS_APP_ID,
      engageDomain: CDS_SOCIAL_SETTINGS_ENGAGE_DOMAIN,
      providers: CDS_SOCIAL_SETTINGS_PROVIDERS?.split(','),
      style: socialSettingsStyle,
    },
    //appleClientId: "<apple-client-id>",
    //appleRedirectURI: "<apple-redirect-URI>",
    
    //rememberMe: true, 
  };
  console.log('CDS config result = ', result);
  return result
}

export const cdsIdConfig: CDSIdConfig = {
  loginId: 'cds-login-form',
  postLoginId: 'cds-post-login-form',
  socialLoginId: 'cds-social-login-form',
  mergeAccountId: 'cds-merge-account-form',
  forgotPasswordId: 'cds-forgot-password-form',
  registerFormId: 'cds-register-form',
  registerMergeFormId: 'cds-register-merge-form',
  resetPasswordId: 'cds-reset-password-form',

  overlay: overlayConfig,
};