import { Container, CssBaseline, Grid } from "@material-ui/core";
import { SimpleNavbar } from "features/main/Navbars/SimpleNavbar";
import React from "react";
import CDSLoginUser from "../main/LoginUser";

export const LoginUserPage = ({ config }: { config: CDSIdConfig }) => {
  return (
    <>
      <CssBaseline />
      <SimpleNavbar />
      <main>
        <Container>
          <Grid>
            <CDSLoginUser config={config}></CDSLoginUser>
          </Grid>
        </Container>
      </main>
    </>
  );
};
