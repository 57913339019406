//Tax Regime based on Mexican CFDI in version 4.0.

let taxRegimePersonal_MX: BillingTaxRegime[] = [
  { tax_regime: 'Sueldos y Salarios e Ingresos Asimilados a Salarios', tax_regime_code: '605' },
  { tax_regime: 'Arrendamiento', tax_regime_code: '606' },
  { tax_regime: 'Régimen de Enajenación o Adquisición de Bienes', tax_regime_code: '607' },
  { tax_regime: 'Demás ingresos', tax_regime_code: '608' },
  { tax_regime: 'Residentes en el Extranjero sin Establecimiento Permanente en México', tax_regime_code: '610' },
  { tax_regime: 'Ingresos por Dividendos (socios y accionistas)', tax_regime_code: '611' },
  { tax_regime: 'Personas Físicas con Actividades Empresariales y Profesionales', tax_regime_code: '612' },
  { tax_regime: 'Ingresos por intereses', tax_regime_code: '614' },
  { tax_regime: 'Régimen de los ingresos por obtención de premios', tax_regime_code: '615' },
  { tax_regime: 'Sin obligaciones fiscales', tax_regime_code: '616' },
  { tax_regime: 'Incorporación Fiscal', tax_regime_code: '621' },
  { tax_regime: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas', tax_regime_code: '625' },
  { tax_regime: 'Régimen Simplificado de Confianza', tax_regime_code: '626' },
]

export default taxRegimePersonal_MX;