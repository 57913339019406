let regions_CO: Region[] = [
  { region_id: 654, region: 'Amazonas', region_code: 'CO-AMA' },
  { region_id: 655, region: 'Antioquia', region_code: 'CO-ANT' },
  { region_id: 656, region: 'Arauca', region_code: 'CO-ARA' },
  { region_id: 657, region: 'Atlántico', region_code: 'CO-ATL' },
  { region_id: 658, region: 'Bolívar', region_code: 'CO-BOL' },
  { region_id: 659, region: 'Boyacá', region_code: 'CO-BOY' },
  { region_id: 660, region: 'Caldas', region_code: 'CO-CAL' },
  { region_id: 661, region: 'Caquetá', region_code: 'CO-CAQ' },
  { region_id: 662, region: 'Casanare', region_code: 'CO-CAS' },
  { region_id: 663, region: 'Cauca', region_code: 'CO-CAU' },
  { region_id: 664, region: 'Cesar', region_code: 'CO-CES' },
  { region_id: 665, region: 'Chocó', region_code: 'CO-CHO' },
  { region_id: 666, region: 'Córdoba', region_code: 'CO-COR' },
  { region_id: 667, region: 'Cundinamarca', region_code: 'CO-CUN' },
  { region_id: 668, region: 'Guainía', region_code: 'CO-GUA' },
  { region_id: 669, region: 'Guaviare', region_code: 'CO-GUV' },
  { region_id: 670, region: 'Huila', region_code: 'CO-HUL' },
  { region_id: 671, region: 'La Guajira', region_code: 'CO-LAG' },
  { region_id: 672, region: 'Magdalena', region_code: 'CO-MAG' },
  { region_id: 673, region: 'Meta', region_code: 'CO-MET' },
  { region_id: 674, region: 'Nariño', region_code: 'CO-NAR' },
  { region_id: 675, region: 'Norte de Santander', region_code: 'CO-NSA' },
  { region_id: 676, region: 'Putumayo', region_code: 'CO-PUT' },
  { region_id: 677, region: 'Quindío', region_code: 'CO-QUI' },
  { region_id: 678, region: 'Risaralda', region_code: 'CO-RIS' },
  { region_id: 679, region: 'San Andrés y Providencia', region_code: 'CO-SAP' },
  { region_id: 680, region: 'Santander', region_code: 'CO-SAN' },
  { region_id: 681, region: 'Sucre', region_code: 'CO-SUC' },
  { region_id: 682, region: 'Tolima', region_code: 'CO-TOL' },
  { region_id: 683, region: 'Valle del Cauca', region_code: 'CO-VAC' },
  { region_id: 684, region: 'Vaupés', region_code: 'CO-VAU' },
  { region_id: 685, region: 'Vichada', region_code: 'CO-VID' },
]

export let streetTypes: string[] = [
  "Avenida Carrera",
  "Avenida Calle",
  "Avenida",
  "Calle",
  "Carrera",
  "Circunvalar",
  "Transversal",
  "Diagonal",
  "Kilómetro",
  "Autopista Norte",
  "Autopista Sur"
];

export default regions_CO;