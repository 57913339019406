import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EcommerceProduct } from 'models/ecommerceProduct';

import {
  GTM_LIST_NAME_DEFAULT,
  handleAddToCartButtonClick,
  handleCheckoutButtonClick,
  handleProductClick,
  handleRemoveAllFromCartButtonClick,
  handleRemoveFromCartButtonClick,
} from './gtmEventHandler';
import { ShoppingCart, ShoppingCartProduct } from 'models/ecommerceCart';
import { BaseCategory } from './categorySlice';

export interface ProductsState {
  products: EcommerceProduct[];
  selectedProducts: EcommerceProduct[];
  cart: { [sku: string]: ShoppingCartProduct };
  cartId: string;
  subtotal: number;
  total?: number;
  discount?: number;
  couponCode?: string;
  couponIsLost?: boolean;
  shipping: number;
  cartProductsCount: number;
  listName: string;
}

const initialState: ProductsState = {
  products: [],
  selectedProducts: [],
  cart: {},
  cartId: '',
  subtotal: 0,
  shipping: 0,
  cartProductsCount: 0,
  listName: GTM_LIST_NAME_DEFAULT,
};

const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsInitialState: (state: ProductsState, action: PayloadAction<void>) => {
      return initialState;
    },
    initProducts: (
      state: ProductsState,
      action: PayloadAction<{
        products: EcommerceProduct[];
        selectedCategories: number[];
        categories: EcommerceCategories[];
      }>
    ) => {
      // Compare persisted data with new data
      //if (state.products.length !== 0 && state.products.every((val, i) => val === action.payload[i])) return;

      state.products = action.payload.products;
      
      const baseCategories = action.payload.categories.map((cat) => BaseCategory.fromEcommerceCategory(cat));
      state.products.forEach((product) => {
        EcommerceProduct.initProductInformation(product, baseCategories);
        if (product.linked_product) {
          EcommerceProduct.initProductInformation(product.linked_product, baseCategories);
        }
      });

      // Init the selectedProducts;
      const productsToEnd: EcommerceProduct[] = [];
      const productList = state.products.filter((product) => {
        if (action.payload.selectedCategories.indexOf(Number(product.category)) === -1) {
          return false;
        }

        if ((product.oos !== undefined && product.oos === '') || product.price <= 0 || !product.sku) {
          productsToEnd.push(product);
          return false;
        }

        return true;
      });

      productList.push(...productsToEnd);
      state.selectedProducts = productList;
    },
    clearCart: (state: ProductsState, action: PayloadAction<CheckoutResult | undefined>) => {
      if (action.payload) {
        handleCheckoutButtonClick(
          action.payload.order,
          action.payload.products,
          action.payload.countryId,
          action.payload.currency,
        );
      }

      state.cart = initialState.cart;
      state.cartId = initialState.cartId;
      state.shipping = initialState.shipping;
      state.subtotal = initialState.subtotal;
      state.total = initialState.total;
      state.discount = initialState.discount;
      state.couponCode = initialState.couponCode;
      state.cartProductsCount = initialState.cartProductsCount;
    },
    addToCart: (
      state: ProductsState,
      action: PayloadAction<{
        product: EcommerceProduct;
        position?: string;
        viewPosition: number;
        countryId?: string;
        currency?: string;
        sendClick?: boolean;
        cartId?: string;
      }>
    ) => {
      const product: EcommerceProduct = { ...action.payload.product };
      state.cartId = action.payload.cartId ?? state.cartId;

      if (state.cart[product.sku] === undefined) {
        product.cartCount = 1;
        product.view_position = action.payload.viewPosition;
        product.list_name = action.payload.position ?? state.listName;
        state.cart[product.sku] = ShoppingCartProduct.fromEcommerceProduct(product);
        state.cartProductsCount += EcommerceProduct.productCount(product);
      } else {
        if (state.cart[product.sku].quantity < product.stock) {
          state.cart[product.sku].quantity++;
          state.cart[product.sku].has_changed = true;
          state.cartProductsCount += EcommerceProduct.productCount(product);
        }
      }
      handleAddToCartButtonClick(
        action.payload.product,
        state,
        action.payload.position ?? state.listName,
        action.payload.viewPosition,
        action.payload.countryId,
        action.payload.currency
      );
      if (action.payload.sendClick) {
        handleProductClick(
          action.payload.product,
          action.payload.position ?? state.listName,
          action.payload.viewPosition,
          action.payload.countryId,
          action.payload.currency
        );
      }
    },
    removeProduct: (
      state: ProductsState,
      action: PayloadAction<{
        product: EcommerceProduct;
        position?: string;
        viewPosition: number;
        countryId?: string;
        currency?: string;
        sendClick?: boolean;
      }>
    ) => {
      const product: EcommerceProduct = { ...action.payload.product };
      if (!state.cart[product.sku]) return;

      state.cart[product.sku].quantity--;
      state.cart[product.sku].has_changed = true;
      state.cartProductsCount -= EcommerceProduct.productCount(product);
      handleRemoveFromCartButtonClick(
        action.payload.product,
        state,
        action.payload.position ?? state.listName,
        action.payload.viewPosition,
        action.payload.countryId,
        action.payload.currency
      );
      if (action.payload.sendClick) {
        handleProductClick(
          action.payload.product,
          action.payload.position ?? state.listName,
          action.payload.viewPosition,
          action.payload.countryId,
          action.payload.currency
        );
      }
    },
    removeAllProducts: (
      state: ProductsState,
      action: PayloadAction<{
        product: EcommerceProduct;
        position: string;
        viewPosition: number;
        countryId?: string;
        currency?: string;
        sendClick?: boolean;
      }>
    ) => {
      const product: EcommerceProduct = { ...action.payload.product };
      if (!state.cart[product.sku]) return;

      const cartCount = state.cart[product.sku].quantity;
      state.cart[product.sku].quantity = 0;
      state.cart[product.sku].has_changed = true;

      state.cartProductsCount -= cartCount * EcommerceProduct.productCount(product);
      handleRemoveAllFromCartButtonClick(
        action.payload.product,
        state,
        action.payload.position,
        action.payload.viewPosition,
        action.payload.countryId,
        action.payload.currency
      );
      if (action.payload.sendClick) {
        handleProductClick(
          action.payload.product,
          action.payload.position ?? state.listName,
          action.payload.viewPosition,
          action.payload.countryId,
          action.payload.currency
        );
      }
    },
    removeAllProductsBySku: (
      state: ProductsState,
      action: PayloadAction<{
        sku: string;
      }>
    ) => {
      if (!state.cart[action.payload.sku]) return;

      const cartCount = state.cart[action.payload.sku].quantity;
      state.cart[action.payload.sku].quantity = 0;
      state.cart[action.payload.sku].has_changed = true;

      state.cartProductsCount -= cartCount;
    },
    loadCart: (
      state: ProductsState,
      action: PayloadAction<{ products: EcommerceProduct[]; position: string; countryId?: string; currency?: string, cartId?: string }>
    ) => {
      if (Object.keys(state.cart).length === 0) {
        state.cartId = action.payload.cartId ?? state.cartId;
        action.payload.products
          .filter((product) => EcommerceProduct.isValidForCatalogue(product))
          .forEach((cartProduct, index) => {
            const cartProductCount = cartProduct.cartCount ?? 1;
            state.cartProductsCount += cartProductCount * EcommerceProduct.productCount(cartProduct);
            if (state.cart[cartProduct.sku] === undefined) {
              const product = { ...cartProduct };
              product.cartCount = cartProductCount;
              state.cart[cartProduct.sku] = ShoppingCartProduct.fromEcommerceProduct(product);
              handleAddToCartButtonClick(
                product,
                state,
                action.payload.position,
                index + 1,
                action.payload.countryId,
                action.payload.currency
              );
            } else {
              if (state.cart[cartProduct.sku].quantity + cartProductCount <= cartProduct.stock) {
                state.cart[cartProduct.sku].quantity =
                  state.cart[cartProduct.sku].quantity + cartProductCount;
                state.cart[cartProduct.sku].has_changed = true;
                const product = { ...cartProduct, cartCount: state.cart[cartProduct.sku].quantity };
                handleAddToCartButtonClick(
                  product,
                  state,
                  action.payload.position,
                  index + 1,
                  action.payload.countryId,
                  action.payload.currency
                );
              }
            }
          });
      }
    },
    loadShoppingCart: (
      state: ProductsState,
      action: PayloadAction<ShoppingCart>
    ) => {
      var newCart: { [sku: string]: ShoppingCartProduct } = {};
      state.cartProductsCount = 0;
      action.payload.products.forEach((product) => {
        state.cartProductsCount += product.quantity;
        newCart[product.sku] = {
          ...product,
          has_changed: false,
          list_name: state.cart[product.sku]?.list_name,
          view_position: state.cart[product.sku]?.view_position,
          category_name: state.cart[product.sku]?.category_name,
          brand: state.cart[product.sku]?.brand,
          flavor: state.cart[product.sku]?.brand,
        };
      });
      state.cart = newCart;
      state.subtotal = action.payload.subtotal;
      state.total = action.payload.total;
      state.discount = action.payload.discount;

      if (state.couponCode && !action.payload.coupon_code && !action.payload.coupon_result) {
        // The coupon was silently discarted from the cart => Set the error flag to true
        state.couponIsLost = true;
      }
      state.couponCode = action.payload.coupon_code;
    },
    setIsCouponLost: (
      state: ProductsState,
      action: PayloadAction<boolean>
    ) => {
      state.couponIsLost = action.payload;
    },
    filterProducts: (state: ProductsState, action: PayloadAction<(prod: EcommerceProduct) => boolean>) => {
      const productsToEnd: EcommerceProduct[] = [];
      let productList = state.products.filter(action.payload);
      productList = productList.filter((product) => {
        if ((product.oos !== undefined && product.oos === '') || product.price <= 0 || !product.sku) {
          productsToEnd.push(product);
          return false;
        }

        return true;
      });

      productList.push(...productsToEnd);
      state.selectedProducts = productList;
    },
    setSelectedProducts: (state: ProductsState, action: PayloadAction<EcommerceProduct[]>) => {
      state.selectedProducts = action.payload;
    },
    setCartProductCount: (state: ProductsState, action: PayloadAction<undefined>) => {
      state.cartProductsCount = 0;
      const productIds: string[] = Object.keys(state.cart);
      productIds.forEach((productId) => (state.cartProductsCount += state.cart[productId].quantity));
    },
    setListName: (state: ProductsState, action: PayloadAction<{ listName: string }>) => {
      state.listName = action.payload.listName;
    },
  },
});

export const {
  setProductsInitialState,
  initProducts,
  addToCart,
  removeProduct,
  removeAllProducts,
  removeAllProductsBySku,
  clearCart,
  loadCart,
  loadShoppingCart,
  setIsCouponLost,
  filterProducts,
  setSelectedProducts,
  setCartProductCount,
  setListName,
} = products.actions;

export const productsReducer = products.reducer;
