import {
  Badge,
  Button,
  Divider,
  Grid,
  Input,
  InputAdornment,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
//import SearchIcon from "@material-ui/icons/Search";

import {
  GTM_LIST_NAME_DEFAULT
} from "features/common/gtmEventHandler";
import { AppDispatch, RootState } from "app/store";
import burgerMenu from "assets/burgerMenu.svg";
import "assets/cart.css";
import shoppingCart from "assets/cartIcon.svg";
import ArrowLeftIcon from "assets/icons/ui/arrow-left.svg";
import searchIcon from "assets/searchIcon.svg";
import { setCategoryFilter, setSearchFilter } from "features/common/accountSlice";
import { setSelectedCategory, setSelectedSubcategory } from "features/common/categorySlice";
import { GTM_LIST_NAME_DIRECT_TEXT_SEARCH, GTM_LIST_NAME_TEXT_SEARCH } from "features/common/gtmEventHandler";
import { filterProducts, setCartProductCount, setListName } from "features/common/productSlice";
import { HomeRelativeLink, ProductsRelativeLink } from "features/common/urlBuilder";
import { useScroll } from "features/hooks/useScroll";
import { useShoppingCart } from "features/hooks/useShoppingCart";
import { MenuTour, MenuTourHandle } from "features/tours/MenuTour";
import { EcommerceProduct } from "models/ecommerceProduct";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddressList } from "../AddressList";
import { CategoryDrawer, CategoryDrawerHandle } from "../CategoryDrawer";
import { ScrollUp } from "../ScrollUp";
import CancelIcon from "assets/cancelIcon.svg";
import { LogoMain } from "../LogoMain";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexDirection: "row",
    backgroundColor: "#fafafa",
    paddingTop: "16px",
  },
  toolbarItems: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    height: "29.01px",
    width: "199px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "280px",
    },
  },
  searchbar: {
    backgroundColor: "#eee",
    borderRadius: "8px",
    border: 0,
    "& input::placeholder": {
      fontSize: "12px",
    },
  },

  cancelIconGrid: {
    position: "relative",
    display: "flex",
    cursor: "pointer",
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "32px",
    height: "33px",
  },

  cancelIconButton: {
    width: "24px",
    minWidth: "24px",
    padding: "0px",
  },

  cancelIcon: {
    padding: "0px",
    width: "24px",
    height: "16px",
  },

  button: {
    minWidth: "30px",
    padding: "0",
  },
  list: {
    width: 350,
    [theme.breakpoints.up("md")]: {
      width: 450,
    },
  },
  shoppingCartBadge: {
    backgroundColor: "#e02020",
    color: "#000000",
  },
  scrollButtonsvg: {
    height: "50px",
    width: "50px",
    position: "fixed",
    top: "70%",
    right: 2,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const EcommerceNavbar = () => {
  const classes = useStyles();

  const dispatch: AppDispatch = useDispatch();
  const { searchFilter } = useSelector((state: RootState) => state.account);
  let { cartProductsCount, cart, cartId } = useSelector((state: RootState) => state.products);
  const { scrollToElement, scrollToTop } = useScroll();

  const [searchFilterText, setSearchFilterText] = useState(searchFilter);

  const [saveCartTimerId, setSaveCartTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { saveShoppingCart } = useShoppingCart();

  const inputRef = useRef<HTMLInputElement>(null);
  const drawerRef = useRef<CategoryDrawerHandle>(null);
  const menuRef = useRef<HTMLButtonElement>(null);
  const tourRef = useRef<MenuTourHandle>(null);

  useEffect(() => {
    if (searchFilter !== undefined && searchFilter.length > 0) {
      setSearchFilterText(searchFilter);
      dispatch(setListName({ listName: GTM_LIST_NAME_DIRECT_TEXT_SEARCH }));
      dispatch(filterProducts((product) => EcommerceProduct.filterByName(product, searchFilter)));
    }
  }, [searchFilter]);

  useEffect(() => {
    if (cartId) {
      if (saveCartTimerId) clearTimeout(saveCartTimerId);
      const timerId = setTimeout(saveShoppingCart, 3000);
      setSaveCartTimerId(timerId);
    }
  }, [cartProductsCount]);

  const goToCart = () => {
    if (pathname !== "/products") {
      dispatch(setSearchFilter(""));
      navigate(ProductsRelativeLink(), { state: { goToCart: true } });
    }
    scrollToElement("cart-container", 160);
  };

  const logoToggle = () => {
    if (pathname !== "/" && pathname !== "/geofencing") {
      setSearchFilterText("");
      dispatch(setSearchFilter(""));
      dispatch(setCategoryFilter(""));
      navigate(HomeRelativeLink().replace("&promo=true", "").replace("edit=true", "").replace("editSuggestedOrder", ""));
    }

    scrollToTop();
  };

  const onInputClick = () => {
    dispatch(setSearchFilter(""));
    dispatch(setCategoryFilter(""));
    dispatch(setSelectedCategory(""));
    dispatch(setSelectedSubcategory(""));

    if (window.location.pathname !== "/products") {
      navigate(ProductsRelativeLink());
    }
  };

  // Function to handle when the search products text input is changed.
  function onChangeSearcher(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    dispatch(setSearchFilter(""));
    dispatch(setCategoryFilter(""));
    setSearchFilterText(e.target.value);
    dispatch(setListName({ listName: GTM_LIST_NAME_TEXT_SEARCH }));
    dispatch(filterProducts((product) => EcommerceProduct.filterByName(product, e.target.value)));
    scrollToTop();

    if (pathname !== "/products") {
      navigate(ProductsRelativeLink());
    }
  }

  const clearSearchFilter = () => {
    setSearchFilterText("");
    dispatch(setSearchFilter(""));
    dispatch(setListName({ listName: GTM_LIST_NAME_DEFAULT }));
    dispatch(filterProducts((product) => true));
  };

  // Fix to avoid cases when the cartProductCount was not created yet and is null.
  if (!cartProductsCount) {
    dispatch(setCartProductCount());
    cartProductsCount = 0;
    const productIds: string[] = Object.keys(cart);
    productIds.forEach((productId) => (cartProductsCount += cart[productId].quantity));
  }

  const toggleDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.toggle(true);
      tourRef.current?.toggleTour(false);
    }
  };

  return (
    <>
      <MenuTour menuRef={menuRef} ref={tourRef} />
      <AddressList />
      <Divider style={{ width: "90%", margin: "auto" }} />
      <Toolbar style={{ flexDirection: "column", paddingTop: "8px" }}>
        <Grid container direction="row" id={"LogoIconButton"} style={{ marginTop: "10px" }}>
          <Grid item>
            <LogoMain className={classes.logo} onClick={logoToggle}/>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "10px" }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          wrap="wrap"
        >
          <Grid item>
            <Button ref={menuRef} disableRipple onClick={toggleDrawer} className={classes.button}>
              <img src={burgerMenu} alt="" />
            </Button>
          </Grid>
          <Grid item style={{ flexBasis: "70%" }}>
            <Input
              fullWidth
              className={classes.searchbar}
              disableUnderline
              placeholder="Busca tus productos favoritos..."
              onChange={onChangeSearcher}
              tabIndex={0}
              onClick={onInputClick}
              inputRef={inputRef}
              value={searchFilterText}
              startAdornment={
                <InputAdornment position="start">
                  <img style={{ marginLeft: "3px", marginTop: "3px" }} src={searchIcon} alt="" />
                </InputAdornment>
              }
              endAdornment={
                searchFilterText && (
                  <InputAdornment position="end" className={classes.cancelIconGrid}>
                    <Button className={classes.cancelIconButton} onClick={clearSearchFilter}>
                      <img src={CancelIcon} className={classes.cancelIcon} alt="Cancel" />
                    </Button>
                  </InputAdornment>
                )
              }
            />
          </Grid>
          <Grid item>
            <Button disableRipple onClick={goToCart} className={classes.button}>
              <Badge classes={{ badge: classes.shoppingCartBadge }} badgeContent={cartProductsCount}>
                <img src={shoppingCart} alt="" />
              </Badge>
            </Button>
          </Grid>
        </Grid>
        {pathname === "/product" ? (
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Button disableRipple onClick={() => navigate(-1)}>
                <img src={ArrowLeftIcon} alt="arrow-left" />
                <Typography variant="h5">Volver</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Toolbar>
      <ScrollUp />
      <CategoryDrawer ref={drawerRef} />
    </>
  );
};
