import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  discountBadge: {
    textAlign: "left",
    backgroundColor: "#e02020",
    color: "white",
    padding: "3px",
    borderRadius: "4px",
    height: "100%",
    lineHeight: "15px",
  },
}));

export const DiscountBadge = ({ discountPercent }: { discountPercent: number }) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle2" className={classes.discountBadge}>
      ¡{discountPercent}% Menos!
    </Typography>
  );
};
