import $ from 'jquery';

export const responseHandler = {
	isString: function(res: any) {
		return $.type(res) === 'string';
	},
	isAlternateFlow: function(res: any) {
		var responseString = this._extractResponseString(res);
		return responseString.toLowerCase().includes('alternateflow') || responseString.toLowerCase().includes('ispostlogin') || responseString.toLowerCase().includes('socialregistration');
	},
	isSocialStart: function(res: any) {
		var responseString = this._extractResponseString(res);
		return /social\w+start/i.test(responseString);
	},
	_extractResponseString: function(res: any) {
		if (this.isString(res)) {
			return res;
		}
		if (res && res.hasOwnProperty('key')) {
			return res.key;
		}
		return null;
	}
};
