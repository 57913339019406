import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { inputClasses } from "app/tccc-theme";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../contexts/modalContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogOverActions: {
      justifyContent: "center",
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
  })
);

export const Modal = () => {
  const { modalContent, handleModal, modal } = useContext(ModalContext);
  const classes = useStyles();

  // TODO: Add implementation of modalContent as a ReactNode
  if (typeof modalContent !== "string") {
    return null;
  }

  if (modal) {
    return ReactDOM.createPortal(
      <Dialog open={modal}>
        <DialogContent>
          <DialogContentText color={"textPrimary"}>{modalContent}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogOverActions}>
          <Button className={classes.mobileStylePrimary} color="primary" onClick={() => handleModal()}>
            {"OK"}
          </Button>
        </DialogActions>
      </Dialog>,
      document.querySelector("#modal-root")!
    );
  } else return null;
};
