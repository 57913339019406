import { Grid, makeStyles } from "@material-ui/core";
import { GTM_LIST_NAME_CAROUSEL, handleProductsView } from "features/common/gtmEventHandler";
import { EcommerceProduct } from "models/ecommerceProduct";
import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { publicApiType } from "react-horizontal-scrolling-menu/dist/createApi";
import { Product } from "./Product";

const useStyles = makeStyles((theme) => ({
  gridProduct: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      minWidth: "130px",
      maxWidth: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px"
    },
    paddingRight: '5px',
    paddingLeft: "5px",
    paddingDown: "5px",
  },
}));

export const ProductCarousel = ({products}: {products: EcommerceProduct[]}) => {
  const classes = useStyles();

  return (
    <Grid>
      <ScrollMenu
        LeftArrow = {"symbol"} 
        RightArrow = {"symbol"} 
        onInit = {function (api: publicApiType): void {} } 
        onScroll = {function (api: publicApiType, ev: any): void {} } 
        onWheel = {function (api: publicApiType, ev: any): void {} }
      >
        {products.map(( product, index ) => (
          <ProductCard product={product} itemId={index.toString()} index={index}/>
        ))}
      </ScrollMenu>
    </Grid>
  );
}

export const ProductCard = ({ product, itemId, index} : { product: EcommerceProduct, itemId: string, index: number }) => {
  const classes = useStyles();

  const visibility = React.useContext(VisibilityContext);
  let isVisible = !!visibility.isItemVisible(itemId);

  return (
    <Grid className={classes.gridProduct} xs={12} sm={12} md={12}>
      <Product key={product.product_id} product={product} position={GTM_LIST_NAME_CAROUSEL} viewPosition={index + 1} isVisible={isVisible}/>
    </Grid>
  );
}