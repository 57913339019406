import { ShoppingCartProduct } from 'models/ecommerceCart';
import { EcommerceProduct } from 'models/ecommerceProduct';
import TagManager from 'react-gtm-module';
import { ProductsState } from './productSlice';
// ProductsSlice Handlers

export const handleProductsView = (
  ecommerceProducts: EcommerceProduct[],
  position: string,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        currency: currency,
        item_list_id: position,
        item_list_name: position,
        items: ecommerceProducts.map((ecommerceProduct, index) => {
          return {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: index + 1,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: 1,
          };
        }),
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleProductView = (
  ecommerceProduct: EcommerceProduct,
  position: string,
  viewPosition: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        currency: currency,
        item_list_id: position,
        item_list_name: position,
        items: [
          {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: viewPosition,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: 1,
          },
        ],
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleProductDetailView = (
  ecommerceProduct: EcommerceProduct,
  position: string,
  viewPosition: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        currency: currency,
        value: EcommerceProduct.totalPrice(ecommerceProduct),
        items: [
          {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: viewPosition,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: 1,
          },
        ],
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleProductClick = (
  ecommerceProduct: EcommerceProduct,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'select_item',
      ecommerce: {
        currency: currency,
        item_list_id: "related_products",
        item_list_name: "Related products",
        items: [
          {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: viewPosition,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: 1,
          },
        ],
        
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleAddToCartButtonClick = (
  ecommerceProduct: EcommerceProduct,
  ProductsState: ProductsState,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);
  var quantity: number =
    ProductsState.cart[ecommerceProduct.sku] === undefined ? 0 : ProductsState.cart[ecommerceProduct.sku].quantity;
  var value = quantity * EcommerceProduct.totalPrice(ecommerceProduct);
  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'add_to_cart',
      ecommerce: {
        currency: currency,
        value: value,
        items: [
          {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: viewPosition,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: quantity,
          },
        ],
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleRemoveFromCartButtonClick = (
  ecommerceProduct: EcommerceProduct,
  ProductsState: ProductsState,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);

  var quantity: number =
    ProductsState.cart[ecommerceProduct.sku] === undefined ? 0 : ProductsState.cart[ecommerceProduct.sku].quantity;
  var value = quantity * EcommerceProduct.totalPrice(ecommerceProduct);
  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'remove_from_cart',
      ecommerce: {
        currency: currency,
        value: value,
        items: [
          {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: viewPosition,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: quantity,
          },
        ],
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleRemoveAllFromCartButtonClick = (
  ecommerceProduct: EcommerceProduct,
  ProductsState: ProductsState,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);
  var quantity: number = 0;
  var value = quantity * EcommerceProduct.totalPrice(ecommerceProduct);
  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'remove_from_cart',
      ecommerce: {
        currency: currency,
        value: value,
        items: [
          {
            item_id: ecommerceProduct.sku,
            item_name: ecommerceProduct.name,
            affiliation: 'WebviewChatbotFramework',
            discount: ecommerceProduct.discount,
            index: viewPosition,
            item_brand: ecommerceProduct.brand,
            item_category: ecommerceProduct.category_name,
            item_list_id: position,
            item_list_name: position,
            item_variant: ecommerceProduct.flavor,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            quantity: quantity,
          },
        ],
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleBeginCheckout = (
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        value: 0,
        currency: currency,
        items: [],
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleAddShippingInformation = (
  products: ShoppingCartProduct[],
  total: number,
  coupon_code?: string,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'add_shipping_info',
      ecommerce: {
        currency: currency,
        value: total,
        coupon: coupon_code,
        shipping_tier: "Ground",
        items: products.map((product) => {
          return {
            item_id: product.sku,
            item_name: product.name,
            affiliation: 'WebviewChatbotFramework',
            discount: product.discount,
            index: product.view_position,
            item_brand: product.brand,
            item_category: product.category_name,
            item_list_id: product.list_name,
            item_list_name: product.list_name,
            item_variant: product.flavor,
            price: product.price,
            quantity: product.quantity,
          }
        }),
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleAddPaymentInformation = (
  products: ShoppingCartProduct[],
  total: number,
  payment_method: string,
  coupon_code?: string,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'add_payment_info',
      ecommerce: {
        currency: currency,
        value: total,
        coupon: coupon_code,
        payment_type: payment_method,
        items: products.map((product) => {
          return {
            item_id: product.sku,
            item_name: product.name,
            affiliation: 'WebviewChatbotFramework',
            discount: product.discount,
            index: product.view_position,
            item_brand: product.brand,
            item_category: product.category_name,
            item_list_id: product.list_name,
            item_list_name: product.list_name,
            item_variant: product.flavor,
            price: product.price,
            quantity: product.quantity,
          }
        }),
      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

export const handleCheckoutButtonClick = (
  order: EcommerceOrder,
  products: ShoppingCartProduct[],
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        transaction_id: order.supplier_order_id,
        value: order.total,
        tax: order.tax,
        shipping: 0,
        currency: currency,
        coupon: order.coupon_code,
        items: products.map((product) => {
          return {
            item_id: product.sku,
            item_name: product.name,
            affiliation: 'WebviewChatbotFramework',
            discount: product.discount,
            index: product.view_position,
            item_brand: product.brand,
            item_category: product.category_name,
            item_list_id: product.list_name,
            item_list_name: product.list_name,
            item_variant: product.flavor,
            price: product.price,
            quantity: product.quantity,
          }
        })

      },
    },
  };
  TagManager.dataLayer({dataLayer: {ecommerce: null}});
  TagManager.initialize(tagManagerArgs);
};

// CategoriesSlice handlers
export const handleMainCategoryButtonClick = (parentCategoryName: string | undefined, countryId?: string) => {
  var gtm = fillGTMInfo(countryId);
  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.auth,
    dataLayer: {
      event: 'Filter Product Menu',
      hierarchy1: parentCategoryName,
    },
  };
  TagManager.initialize(tagManagerArgs);
};

export const handleChildCategoryButtonClick = (
  parentCategoryName: string | undefined,
  childCategoryName: string | undefined,
  countryId?: string
) => {
  var gtm = fillGTMInfo(countryId);
  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'Filter Product Menu',
      hierarchy1: parentCategoryName,
      hierarchy2: childCategoryName,
    },
  };
  TagManager.initialize(tagManagerArgs);
};

// Geofencing gtm event handlers
export const handleWebviewInizialized = (contextId: string, bottler: string, countryId?: string) => {
  var gtm = fillGTMInfo(countryId);
  const tagManagerArgs = {
    gtmId: gtm.id,
    auth: gtm.auth,
    preview: gtm.preview,
    dataLayer: {
      event: 'bottlerAssigned',
      bottler: bottler,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

function fillGTMInfo(countryId?: string): GTMProps {
  var gtmCode = process.env['REACT_APP_GTM_ID_' + countryId] || '';
  var gtmAuth = process.env['REACT_APP_GTM_AUTH_' + countryId] || '';
  var gtmPreview = process.env['REACT_APP_GTM_PREVIEW_' + countryId] || '';
  return {
    id: gtmCode,
    auth: gtmAuth,
    preview: gtmPreview,
  };
}

export const GTM_LIST_NAME_DEFAULT = 'Catálogo de Productos';

export const GTM_LIST_NAME_DIRECT_CATEGORY = 'Categoría Directa';
export const GTM_LIST_NAME_DIRECT_PROMOTION = 'Promoción Directa';
export const GTM_LIST_NAME_DIRECT_TEXT_SEARCH = 'Búsqueda por texto Directa';

export const GTM_LIST_NAME_PARENT_CATEGORY = 'Categoría Padre';
export const GTM_LIST_NAME_CHILD_CATEGORY = 'Categoría Hija';
export const GTM_LIST_NAME_PARENT_ALL_SUBCATEGORIES = 'Ver todo Categoría Padre';
export const GTM_LIST_NAME_TEXT_SEARCH = 'Búsqueda por texto';

export const GTM_LIST_NAME_CAROUSEL = 'Carousel';
export const GTM_LIST_NAME_SHOPPING_CART = 'Carrito';
export const GTM_LIST_NAME_PRODUCT_DETAIL = 'Detalles de Producto';

export const GTM_LIST_NAME_BANNER_PRODUCT_REDIRECT = 'Banner - Detalle de Producto';
export const GTM_LIST_NAME_BANNER_CATEGORY_REDIRECT = 'Banner - Categoria';
export const GTM_LIST_NAME_BANNER_SEARCH_REDIRECT = 'Banner - Búsqueda por texto';

export const GTM_LIST_NAME_EDIT_RE_ORDER = 'Editar ReOrder';
export const GTM_LIST_NAME_EDIT_SUGGESTED_ORDER = 'Editar Pedido Sugerido';
