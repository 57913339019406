import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeocodingInfo } from 'models/geocoding';
import { AvailabilityZone } from 'models/geofencing';
import Geocode from 'react-geocode';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '';

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

interface GeofencingState {
  availabilityZone: AvailabilityZone | null;
  geofencingAddress: string;
  isValid: boolean;
}

const initialState: GeofencingState = {
  availabilityZone: null,
  geofencingAddress: '',
  isValid: true,
};

const updateStateByGeocodingResult = (
  state: GeofencingState,
  geocode: GeocodingInfo,
  userAddressInput: string | null
) => {
  state.availabilityZone = AvailabilityZone.fromGeocodingInfo(geocode);
  state.geofencingAddress = geocode.formatted_address;
  state.isValid = true;
};

const geofencing = createSlice({
  name: 'geofencing',
  initialState,
  reducers: {
    setGeofencingInitialState: (state: GeofencingState, action: PayloadAction<void>) => {
      return initialState;
    },
    updateByGeocodingInfo: (state: GeofencingState, action: PayloadAction<GeocodingInfo>) => {
      updateStateByGeocodingResult(state, action.payload, null);
    },

    updateAvailabilityZone: (state: GeofencingState, action: PayloadAction<AvailabilityZone>) => {
      state.availabilityZone = action.payload;
      state.isValid = true;
    },

    updateIsValid: (state: GeofencingState, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
  },
});

export const { setGeofencingInitialState, updateByGeocodingInfo, updateAvailabilityZone, updateIsValid } =
  geofencing.actions;

export const geofencingReducer = geofencing.reducer;
