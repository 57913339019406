import { Grid, LinearProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  colorPrimary: {
    backgroundColor: "#ABABAB",
  },
  barColorPrimary: {
    backgroundColor: "#DC2C00",
  },
  barColorPrimaryComplete: {
    backgroundColor: "#00D426",
  },
  progressLabelLeft: {
    position: "absolute",
    width: "85%",
    height: "100%",
    zIndex: 1,
    maxHeight: 25, // borderlinearprogress root.height
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    color: "white",
  },
  progressLabelRight: {
    position: "absolute",
    width: "85%",
    height: "100%",
    zIndex: 2,
    maxHeight: 25, // borderlinearprogress root.height
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: "auto",
    },
    color: "white",
  },
}));

export const CheckoutProgress = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={12} spacing={0}>
          <div className={classes.progressLabelLeft}>
            <span>{props.subtotalFormatted}</span>
          </div>
          <div className={classes.progressLabelRight}>
            <span>{props.minimumOrderAmountFormatted}</span>
          </div>
          <LinearProgress
            variant="determinate"
            style={{
              height: 25,
              borderRadius: 12,
            }}
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary:
                (props.subtotal * 100) / (props.minimumOrderAmount || 1) >= 100
                  ? classes.barColorPrimaryComplete
                  : classes.barColorPrimary,
            }}
            value={
              (props.subtotal * 100) / (props.minimumOrderAmount || 1) >= 100
                ? 100
                : (props.subtotal * 100) / (props.minimumOrderAmount || 1)
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
