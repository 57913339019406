import { Button, makeStyles } from "@material-ui/core";
import cancelIcon from "assets/cancelIcon.svg";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appbar: {
    position: "fixed",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fafafa",
  },
  root: {
    marginTop: "155px",
    position: "relative",
    backgroundColor: "#fff",
  },
  img: {
    transition: "transform .2s",
    display: "block",
    margin: "auto",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  backButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
}));

export const ProductImagePage = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const { url } = location.state;

  return (
    <div className={classes.root}>
      <Button disableRipple className={classes.backButton} onClick={() => navigate(-1)}>
        <img src={cancelIcon} alt="cancel" />
      </Button>
      <img className={classes.img} src={url} alt="product" />
    </div>
  );
};
