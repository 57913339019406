import { CssBaseline } from "@material-ui/core";
import { useScroll } from "features/hooks/useScroll";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Main } from "../main/Main";

export const ProductsPage = () => {
  const { scrollToElement } = useScroll();
  const { state } = useLocation();
  const { goToCart } = state || {};

  useEffect(() => {
    setTimeout(() => {
      if (goToCart) {
        scrollToElement("cart-container", 160);
      }
    }, 200);
  }, []);

  return (
    <>
      <CssBaseline />
      <Main />
    </>
  );
};
