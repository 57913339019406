import { Toolbar, makeStyles } from "@material-ui/core";
import React from "react";
import { LogoMain } from "../LogoMain";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "#fafafa",
    paddingTop: "16px",
  },
  toolbarItems: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    height: "29.01px",
    width: "199px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "280px",
    },
  },
}));

export const SimpleNavbar = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarItems} style={{ display: "flex" }}>
        <LogoMain className={classes.logo} />
      </div>
    </Toolbar>
  );
};
