import { useState } from 'react';

export const useLoader = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [loaderImage, setLoaderImage] = useState<string>('');
  const [loaderMessage, setLoaderMessage] = useState<string>('');

  const handleLoader = (image?: string, loaderMessage?: string) => {
    if (image) {
      setLoaderImage(image);
      setShowLoader(true);
    }
    if (loaderMessage) {
      setLoaderMessage(loaderMessage);
      setShowLoader(true);
    }

    if (!image && !loaderMessage) {
      setLoaderImage('');
      setLoaderMessage('');
      setShowLoader(false);
    }
  };

  return { showLoader, handleLoader, loaderImage, loaderMessage };
};
