import { useEffect, useState } from 'react';

export const useScroll = () => {
  const [isTopScroll, setIsTopScroll] = useState(true);
  const [isBottomScroll, setIsBottomScroll] = useState(false);

  useEffect(() => {
    const onScroll = () => (window.scrollY > 0 ? setIsTopScroll(false) : setIsTopScroll(true));
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      window.innerHeight + window.scrollY >= document.body.offsetHeight
        ? setIsBottomScroll(true)
        : setIsBottomScroll(false);
    };
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToElement = (id: string, offset: number) => {
    const cartContainer = document.getElementById(id);

    const containerPosition = cartContainer?.getBoundingClientRect().top;
    const offsetPosition = containerPosition! + window.scrollY - offset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return { scrollToElement, scrollToTop, isTopScroll, isBottomScroll };
};
