//Tax Regime based on Mexican CFDI in version 4.0.

let taxRegimeBusiness_MX: BillingTaxRegime[] = [
  { tax_regime: 'General de Ley Personas Morales', tax_regime_code: '601' },
  { tax_regime: 'Personas Morales con Fines no Lucrativos', tax_regime_code: '603' },
  { tax_regime: 'Residentes en el Extranjero sin Establecimiento Permanente en México', tax_regime_code: '610' },
  { tax_regime: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos', tax_regime_code: '620' },
  { tax_regime: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras', tax_regime_code: '622' },
  { tax_regime: 'Opcional para Grupos de Sociedades', tax_regime_code: '623' },
  { tax_regime: 'Coordinados', tax_regime_code: '624' },
  { tax_regime: 'Régimen Simplificado de Confianza', tax_regime_code: '626' },
]

export default taxRegimeBusiness_MX;