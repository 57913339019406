// @ts-nocheck
/* eslint-disable no-undef */

import { getConfigCDS } from '../cdsInit';
import $ from 'jquery';
import { finalizeMagentoLogin } from '../action/finalizeMagentoLogin';
import { responseHandler } from '../model/responseHandler';
import { CDSLoginPageLink } from 'features/common/urlBuilder';
//import { magentoCdsError } from 'cds/action/magentoCdsError';

export const register = (config, accountProviderId, registerPostUrl, phone, country_id) => {
	const successCallback = (res) => {
		if (responseHandler.isSocialStart(res)) {
			console.log(res);
			return;
		}
		console.log('CDS Register Success', res);
		
		$('body').trigger('processStart');
		cds.getUserInfo()
		.then((res) => {
			var user = res.user;
			finalizeMagentoLogin(accountProviderId, user, registerPostUrl, phone, 'register', country_id);
		})
		.catch((err) => {
			console.log('getUserInfo attempt', err);
			//TODO: check what is this logic for.
			//window.location = urlBuilder.build('cds/customer/createPost');
			$('body').trigger('processStop');
		});
	}

	const failureCallback = (res) => {
		console.log('Register attempt', res);
		//var error = magentoCdsError(res, 'register');
		
		if (responseHandler.isAlternateFlow(res)) {
			//TODO: check what is this logic for.
			$('#' + config.registerFormId).hide();
			$('body').trigger('cds:alternateFlow:register', res);
		}
	}

	cds.init(getConfigCDS(country_id)).then(() => {
		cds.register(config.registerFormId, config.registerMergeFormId, config.overlay, undefined, successCallback, failureCallback)
		.then(() => {
			$('#' + config.registerMergeFormId).bind('DOMNodeInserted', function() {
				$('#' + config.registerFormId).hide();
			});
			$('body').on('click', '#merge-account-cancel', function() {
				window.location.assign(CDSLoginPageLink())
				//window.location = urlBuilder.build('customer/account/login');
			});
		})
		.catch((err) => {
			console.log('Registration form rendering attempt', err);
		});
	});
}