import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationContext } from 'models/conversationContext';
interface AccountState {
  conversationContext?: ConversationContext;
  accountProvider?: AccountProvider;
  registerAccount?: RegisterAccount;
  ageValidation: AgeValidationState;
  searchFilter: string;
  categoryFilter: string;
  registrationFlow: string;
  registrationReturnTemplate: string;
  postLoginRedirectUrl: string;
  isUserLoggedIn?: userStatus;
}

export enum AgeValidationState {
  Valid,
  Invalid,
  Incomplete,
}

export enum userStatus {
  LoggedIn,
  LoggedOut,
}

const initialState: AccountState = {
  conversationContext: undefined,
  accountProvider: undefined,
  ageValidation: AgeValidationState.Incomplete,
  searchFilter: '',
  categoryFilter: '',
  registrationFlow: '',
  registrationReturnTemplate: '',
  postLoginRedirectUrl: '',
  isUserLoggedIn: userStatus.LoggedOut,
};

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountInitialState: (state: AccountState, action: PayloadAction<void>) => {
      return initialState;
    },
    setConversationContext: (state: AccountState, action: PayloadAction<ConversationContext>) => {
      state.conversationContext = action.payload;
    },
    setAccountProvider: (state: AccountState, action: PayloadAction<AccountProvider>) => {
      state.accountProvider = action.payload;
    },
    setRegisterAccount: (state: AccountState, action: PayloadAction<RegisterAccount>) => {
      state.registerAccount = action.payload;
    },
    setAgeValidation: (state: AccountState, action: PayloadAction<boolean>) => {
      state.ageValidation = action.payload ? AgeValidationState.Valid : AgeValidationState.Invalid;
    },
    setSearchFilter: (state: AccountState, action: PayloadAction<string>) => {
      state.searchFilter = action.payload;
    },
    setCategoryFilter: (state: AccountState, action: PayloadAction<string>) => {
      state.categoryFilter = action.payload;
    },
    setRegistrationFlow: (
      state: AccountState,
      action: PayloadAction<{ registrationFlow: string; registrationReturnTemplate: string }>
    ) => {
      state.registrationFlow = action.payload.registrationFlow;
      state.registrationReturnTemplate = action.payload.registrationReturnTemplate;
    },
    setPostLoginRedirectUrl: (state: AccountState, action: PayloadAction<string>) => {
      state.postLoginRedirectUrl = action.payload;
    },
    setIsUserLoggedIn: (state: AccountState, action: PayloadAction<userStatus>) => {
      state.isUserLoggedIn = action.payload;
    },
  },
});

export const {
  setAccountInitialState,
  setConversationContext,
  setAccountProvider,
  setRegisterAccount,
  setAgeValidation,
  setSearchFilter,
  setCategoryFilter,
  setRegistrationFlow,
  setPostLoginRedirectUrl,
  setIsUserLoggedIn,
} = account.actions;

export const accountReducer = account.reducer;
