import React, { createContext } from "react";
import { useModal } from "../hooks/useModal";
import { Modal } from "../main/Modal";

type ModalContextProps = {
  modal: boolean;
  handleModal: (content?: string | JSX.Element) => void;
  modalContent: string | JSX.Element;
};
let ModalContext: React.Context<ModalContextProps>;
let { Provider } = (ModalContext = createContext({
  modal: false,
  handleModal: () => {},
  modalContent: "",
} as ModalContextProps));

type ModalProviderProps = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};
let ModalProvider = ({ children }: ModalProviderProps) => {
  let { modal, handleModal, modalContent } = useModal();
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
