import { CheckoutShippingAddress } from "./checkout";
import { AvailabilityZone, Coordinates } from "./geofencing";

export class EcommerceAddress{
  private constructor(
    public coordinates: Coordinates,
    public street: string,
    public city: string,
    public municipality: string,
    public country_id: string,
    public region: string,
    public postal_code: string,
    public phone: string | null,
    public number: string | null,
    public internal_number: string | null,
    public neighborhood: string | null, 
    public references: string | null,
    public route_id : string,
    public warehouse_code: string,
    public encoded_route_info: string | null,
    public region_id: string,
    public region_code: string,
    public name: string | null,
    public is_new: boolean,
    public bottler?: string,
    public id?: number,
  ) {}

  public static toString(address: EcommerceAddress): string{
    var emptyPlaceholders = ['undefined', 'null', 'references', 'municipality', '-'];
    var formatedPostalCode: string = address.postal_code && !emptyPlaceholders.some((placeholder) => placeholder === address.postal_code)
      ? `CP ${address.postal_code}`
      : '';

    return `${[address.street, address.number, address.neighborhood, address.municipality, address.region, formatedPostalCode]
      .filter((stringName) => stringName && !emptyPlaceholders.find((placeholder) => placeholder === stringName))
      .join(', ')}`;
  }

  public static toGeocodeString(address: EcommerceAddress): string{
    var emptyPlaceholders = ['undefined', 'null', 'references', 'municipality', '-'];
    var formatedPostalCode: string = address.postal_code && !emptyPlaceholders.some((placeholder) => placeholder === address.postal_code)
      ? `${address.postal_code}`
      : '';

    return `${[`${address.street}  ${address.number}`, address.neighborhood, `${formatedPostalCode} ${address.city}`, address.country_id ]
      .filter((stringName) => stringName && !emptyPlaceholders.find((placeholder) => placeholder === stringName))
      .join(', ')}`;
  }

  static fromAddress (address: Address): EcommerceAddress{
    var coordinates: Coordinates = address.coordinates;
    return new EcommerceAddress(
      coordinates,
      address.street,
      address.city,
      address.municipality,
      address.country_id,
      address.region,
      address.postal_code,
      address.phone as string,
      address.number,
      address.internal_number || '' as string,
      address.neighborhood,
      address.references || '' as string,
      address.route_id,
      address.warehouse_code,
      address.encoded_route_info,
      address.region_id,
      address.region_code,
      `${address.firstName as string} ${address.lastName as string}`,
      false,
      address.bottler_name,
      address.id
    )
  }

  static fromCheckoutShippingAddress (checkoutAddress: CheckoutShippingAddress): EcommerceAddress{
    return new EcommerceAddress(
      checkoutAddress.coordinates,
      checkoutAddress.street,
      checkoutAddress.city,
      checkoutAddress.municipality,
      checkoutAddress.country_id,
      checkoutAddress.region,
      checkoutAddress.postal_code,
      checkoutAddress.telephone,
      checkoutAddress.number,
      checkoutAddress.internal_number || '',
      checkoutAddress.neighborhood,
      checkoutAddress.references || '',
      checkoutAddress.route_id,
      checkoutAddress.warehouse_code,
      checkoutAddress.encoded_route_info,
      checkoutAddress.region_id,
      checkoutAddress.region_code,
      `${checkoutAddress.first_Name as string} ${checkoutAddress.last_Name as string}`,
      false,
      ''
    )
  }

  static fromAvailabilityZone (availabilityZone: AvailabilityZone) : EcommerceAddress{
    return new EcommerceAddress(
      availabilityZone.coordinates,
      availabilityZone.address.street,
      availabilityZone.address.city,
      availabilityZone.address.municipality,
      availabilityZone.address.country_id,
      availabilityZone.address.region,
      availabilityZone.address.postal_code,
      null,
      availabilityZone.address.number,
      null,
      null,
      null,
      availabilityZone.route_id as string,
      availabilityZone.warehouse_code as string,
      availabilityZone.encoded_route_info,
      availabilityZone.region_id as string,
      availabilityZone.region_code as string,
      null,
      true,
    );
  }

  static default () : EcommerceAddress{
    return new EcommerceAddress(
      {
        lat: 0.0,
        lng: 0.0,
      },
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      null,
      null,
      null,
      null,
      '',
      '',
      null,
      '',
      '',
      null,
      true,
    );
  }
}