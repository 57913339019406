import { useState } from 'react';

export const useModal = () => {
  let [modal, setModal] = useState(false);
  let [modalContent, setModalContent] = useState<string | JSX.Element>("I'm the Modal Content");

  let handleModal = (content?: string | JSX.Element) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return { modal, handleModal, modalContent };
};
