import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import {
  setAccountInitialState,
  setAccountProvider,
  setCategoryFilter,
  setConversationContext,
  setIsUserLoggedIn,
  setRegisterAccount,
  userStatus,
} from "features/common/accountSlice";
import {
  funcCataloguePortafolioGetById,
  funcGetDeliveries,
  funcRegisterGetByPhone,
  funcShoppingCartCreate,
  funcSuggestedOrderCampaignGet,
  funcSuggestedOrderGet,
  getAccountProviderByPhone,
  getConversationContext,
} from "features/common/API";
import {
  BaseCategory,
  setCategoryInitialState,
} from "features/common/categorySlice";
import { setDeliveryData, setDeliveryDate, setDeliveryInitialState } from "features/common/deliverySlice";
import { setGeofencingInitialState } from "features/common/geofencingSlice";
import {
  GTM_LIST_NAME_DIRECT_CATEGORY,
  GTM_LIST_NAME_DIRECT_PROMOTION,
  GTM_LIST_NAME_EDIT_RE_ORDER,
  GTM_LIST_NAME_EDIT_SUGGESTED_ORDER,
  handleBeginCheckout,
  handleWebviewInizialized,
} from "features/common/gtmEventHandler";
import {
  initProducts,
  loadCart,
  removeAllProductsBySku,
  setProductsInitialState,
} from "features/common/productSlice";
import { BotWhatsAppLink, GeofencingPageLink, ProductsRelativeLink } from "features/common/urlBuilder";
import { CheckoutShippingAddress } from "models/checkout";
import { ConversationContext } from "models/conversationContext";
import { SuggestedOrder } from "models/suggestedOrder";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EcommerceAddress } from "../../models/ecommerceAddress";
import { CategoryList } from "./CategoryList";
import { MessageDialog } from "./MessageDialog";
// Components

import CokeLogo from "assets/coke_loading.svg";
import { LoaderContext } from "features/contexts/loaderContext";
import { PostShoppingCartCreate } from "models/ecommerceCart";
import { EcommerceProduct } from "models/ecommerceProduct";
import moment from "moment";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Banner } from "./Banner";
import { ProductCarousel } from "./ProductCarousel";
import { PromotionCarousel } from "./PromotionCarousel";
import { getProviderDefaultDeliveryDate } from "features/common/formatHelpers";
import { useCategoryDispatcher } from "features/hooks/useCategoryDispatcher";

const useStyles = makeStyles((theme) => ({
  banner: {
    // TODO: VERIFY THIS IS WHAT WE WANT OR GET MORE SIZES
    width: "100%",
  },
  appbar: {
    position: "fixed",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fafafa",
  },
  main: {
    marginTop: "155px",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  ProductGrid: {
    justifyContent: "center",
  },
  label: {
    textAlign: "left",
    position: "relative",
    fontWeight: 700,
    borderBottomRightRadius: "9px",
    borderTopRightRadius: "9px",
    padding: "0px 0px 8px 5px",
    whiteSpace: "nowrap",
    minWidth: "140px",
    display: "inline-block",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  paddingContent: {
    padding: "16px 11px 16px 11px",
  },
}));

export const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { fetchCategories, dispatchCategory } = useCategoryDispatcher();

  const dispatch: AppDispatch = useDispatch();
  const { selectedSubcategories } = useSelector((state: RootState) => state.categories);
  let categoriesState = useSelector((state: RootState) => state.categories);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);
  const { conversationContext, registerAccount, accountProvider, searchFilter, categoryFilter } = useSelector(
    (state: RootState) => state.account
  );
  let { cartId, cart } = useSelector((state: RootState) => state.products);

  //const [isLoaderActive, setIsLoaderActive] = useState(false);
  const { handleLoader } = useContext(LoaderContext);
  const [carouselProducts, setCarouselProducts] = useState<EcommerceProduct[]>([]);
  const [photoCategories, setPhotoCategories] = useState<EcommerceCategories[]>([]);

  const searchParams = new URLSearchParams(window.location.search);
  const contextId: string = searchParams.get("contextId") || "";
  const edit: string = searchParams.get("edit") || "";
  const editSuggestedOrder: string = searchParams.get("editSuggestedOrder") || "";
  const promo: string = searchParams.get("promo") || "";

  const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [isPreloadReady, setIsPreloadReady] = useState(false);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");
  const [suggestedOrder, setSuggestedOrder] = useState<SuggestedOrder | undefined>();

  let bannerMessage = accountProvider?.parameters?.find((p) => p.key === "banner_message")?.value;
  const bannerStartDate = accountProvider?.parameters?.find((p) => p.key === "banner_start_date")?.value;
  const bannerEndDate = accountProvider?.parameters?.find((p) => p.key === "banner_end_date")?.value;
  if (!bannerStartDate || !bannerEndDate) {
    bannerMessage = undefined;
  }

  let startDate = new Date();
  if (moment(bannerStartDate, "YYYY-MM-DD HH:mm:ss.SSS").isValid()) {
    startDate = moment(bannerStartDate, "YYYY-MM-DD HH:mm:ss.SSS").toDate();
  }

  let endDate = new Date();
  if (moment(bannerEndDate, "YYYY-MM-DD HH:mm:ss.SSS").isValid()) {
    endDate = moment(bannerEndDate, "YYYY-MM-DD HH:mm:ss.SSS").toDate();
  }
  const curdate = new Date();

  const isInRange = curdate >= startDate && curdate <= endDate;

  // PreLoad useEffect for ReOrder and Suggested Order flow
  useEffect(() => {
    (async () => {
      if ((edit === "true" || editSuggestedOrder === "true") && !isPreloadReady) {
        handleLoader(CokeLogo, loadingMessage);

        // Set the initalState of all the slices to be clean, as this is the first step on the webview for the users using the edit Flows.
        dispatch(setAccountInitialState());
        dispatch(setDeliveryInitialState());
        dispatch(setProductsInitialState());
        dispatch(setCategoryInitialState());
        dispatch(setGeofencingInitialState());

        let bottlers: Bottler[] = [];
        let country_id: string = "";

        const conversationContext = await getConversationContext(contextId || "");
        const accountProvider = await getAccountProviderByPhone(conversationContext?.whatsappNumber!);

        if (ConversationContext.isValid(conversationContext, accountProvider)) {
          dispatch(setConversationContext(conversationContext!));
          dispatch(setAccountProvider(accountProvider!));

          bottlers = JSON.parse(accountProvider?.bottlers ?? "[]");

          const registerAccount = (await funcRegisterGetByPhone(
            conversationContext?.accountProviderId!,
            conversationContext?.cellPhoneNumber!
          )) as RegisterAccount;
          dispatch(setRegisterAccount(registerAccount));
          dispatch(setIsUserLoggedIn(userStatus.LoggedIn));

          const suggestedOrder =
            edit === "true"
              ? await funcSuggestedOrderGet(accountProvider?.key as number, registerAccount?.account_id, true)
              : await funcSuggestedOrderCampaignGet(accountProvider?.key as number, registerAccount?.account_id);

          if (!suggestedOrder) {
            setIsErrorMessageOpen(true);
            setErrorMessage(
              "Lo siento 😅, tu sesión de editar el pedido ha expirado. Presiona regresar para volver a Whatsapp."
            );
            setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber));
            setErrorMessageButtonText("Regresar");
            handleLoader();
            return;
          }
          setSuggestedOrder(suggestedOrder);

          const deliveryAddress = EcommerceAddress.fromCheckoutShippingAddress(
            suggestedOrder?.shippingAddress as CheckoutShippingAddress
          );

          // Add the bottlerName to the DeliveryAddress using the bottlers list on the AccountProvider
          const defaultBottler = bottlers.find((bottler) => bottler.warehouse_code === "*");

          const bottlerName: string = (bottlers.find(
            (bottler) => bottler.warehouse_code === deliveryAddress.warehouse_code
          )?.bottler_name || defaultBottler?.bottler_name) as string;

          handleWebviewInizialized(contextId || "", bottlerName, country_id);

          deliveryAddress.bottler = bottlerName;

          dispatch(setDeliveryData(deliveryAddress));
          handleLoader();
        } else {
          setIsErrorMessageOpen(true);
          setErrorMessage("Lo siento 😅, el link de compra ha expirado. Presiona Regresar para volver a Whatsapp");
          setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber as string));
          setErrorMessageButtonText("Regresar");
          handleLoader();
          return;
        }
      }

      setIsPreloadReady(true);
    })()
  }, []);

  // Load all the Catalog information useEffect
  useEffect(() => {
    (async () => {
      if (isPreloadReady) {
        if (!deliveryData.warehouse_code) {
          setIsErrorMessageOpen(true);
          setErrorMessage(
            "Lo siento 😅, tu sesión ha expirado, presiona regresar para continuar con el flujo de compra."
          );
          setErrorMessageButtonLink(GeofencingPageLink());
          setErrorMessageButtonText("Regresar");
          return;
        }
        //setIsLoaderActive(true);
        handleLoader(CokeLogo, loadingMessage);

        const { categories, subcategories, comboCategories } = await fetchCategories();

        if (!categories || !subcategories) {
          setIsErrorMessageOpen(true);
          setErrorMessage(
            "Lo siento 😅, tu sesión ha expirado, presiona regresar e introduce una nueva dirección en formato: calle y número de puerta."
          );
          setErrorMessageButtonLink(GeofencingPageLink());
          setErrorMessageButtonText("Regresar");
          handleLoader();
          return;
        }

        setPhotoCategories(categories);

        // Fetch and set deliveryDate data
        const deliveryDates: EcommereceDeliveryDate[] | undefined = (await funcGetDeliveries(
          accountProvider?.key as number,
          registerAccount?.account_id as string,
          deliveryData?.warehouse_code as string,
          deliveryData?.route_id as string,
        )) || getProviderDefaultDeliveryDate(accountProvider);
        if (deliveryDates) 
          dispatch(setDeliveryDate(deliveryDates[0]));

        let Products: EcommerceProduct[] = (await funcCataloguePortafolioGetById(
          accountProvider?.key as number,
          registerAccount?.account_id as string,
          deliveryData?.warehouse_code as string,
        )) as EcommerceProduct[];

        // Add artificial promo category to corresponding products
        if (comboCategories.isArtificial && comboCategories?.comboCategoryId !== undefined) {
          Products.forEach((element: EcommerceProduct) => {
            if (
              element.has_promotion === true ||
              element.discount > 0 ||
              element.name?.toLowerCase().includes("paquete")
            ) {
              if (element.discount > 0) {
                element.categories?.unshift(comboCategories.discountSubcategoryId);
              }

              if (element.name?.toLowerCase().includes("paquete")) {
                element.categories?.unshift(comboCategories.comboSubcategoryId);
              }

              element.categories?.unshift(comboCategories?.comboCategoryId as string);
              element.category = comboCategories.comboCategoryId as string;
            }
          });
        }
        // Set Products on slice
        if (Products) {
          dispatch(
            initProducts({ products: Products, selectedCategories: selectedSubcategories, categories: [...categories, ...subcategories] })
          );
          handleBeginCheckout(accountProvider?.country_id, accountProvider?.currency);

          // Delete products in the cart that are not available in the new catalog
          if (cartId) {
            const cartItemsId = Object.keys(cart);
            cartItemsId.forEach((itemId) => {
              const productBySku = Products.find((product) => product.sku === cart[itemId].sku && !EcommerceProduct.isOutOfStock(product));
              if (!productBySku) {
                dispatch(
                  removeAllProductsBySku({
                    sku: cart[itemId].sku,
                  })
                );
              }
            });
          }
        }
        // Finish primary loading before setCarouselProducts
        if (promo !== "true" && searchFilter !== undefined && searchFilter.length === 0) {
          //setIsLoaderActive(false);
          handleLoader();
        }

        setIsAllLoaded(true);

        if (suggestedOrder) {
          // On Edit Order flow load the suggested Products on the webview cart.
          var cartProducts: EcommerceProduct[] = Products.filter((product) =>
            suggestedOrder?.products.find(
              (suggestedProduct) => suggestedProduct.product_id === product.product_id.toString()
            )
          ).map((product) => {
            var cartCount: number =
              suggestedOrder?.products.find(
                (suggestedProduct) => suggestedProduct.product_id === product.product_id.toString()
              )?.quantity || 1;
            return { ...product, cartCount };
          });

          // Create cart if it doesn't exist
          if (!cartId) {
            var payload = {
              outlet: deliveryData?.encoded_route_info, // equal to encoded_route_info
              store: deliveryData?.bottler, // equal to bottler from deliveryData
              products: [], // equal to products
            } as PostShoppingCartCreate;

            handleLoader(CokeLogo, loadingMessage);
            const shoppingCart = await funcShoppingCartCreate(
              conversationContext?.accountProviderId as number,
              registerAccount?.account_id as string,
              payload
            );
            handleLoader();
            handleBeginCheckout(accountProvider?.country_id, accountProvider?.currency);

            cartId = shoppingCart?.supplier_cart_id ?? cartId;
          }

          dispatch(
            loadCart({
              products: cartProducts,
              position: edit === "true" ? GTM_LIST_NAME_EDIT_RE_ORDER : GTM_LIST_NAME_EDIT_SUGGESTED_ORDER,
              countryId: accountProvider?.country_id,
              currency: accountProvider?.currency,
              cartId: cartId,
            })
          );

          setSuggestedOrder(undefined);
        }

        // Show in the carrousel the products that have disccounts
        var productsWithDisccountFilter = (product: EcommerceProduct) => product.discount > 0;

        const productsOnCarrousel: EcommerceProduct[] = Products.filter(
          (product) => productsWithDisccountFilter(product)
        ).reverse();

        setCarouselProducts(productsOnCarrousel);
      }
    })();
  }, [isPreloadReady]);

  useEffect(() => {
    if (isAllLoaded && searchFilter !== undefined && searchFilter.length > 0) {
      navigate(ProductsRelativeLink());
    }
    // Load promotions category if "promo" urlParam exists
    if (isAllLoaded && !isCategoriesLoaded && promo === "true") {
      goToPromotions(photoCategories);
    }
    // Load category if "ctg" urlParam exists
    if (isAllLoaded && !isCategoriesLoaded && categoryFilter !== "") {
      goToCategory(photoCategories);
    }
  }, [isAllLoaded]);

  const goToPromotions = async (categories: EcommerceCategories[]) => {
    // Avoid load promotions category more than once
    setIsCategoriesLoaded(true);

    var category = categories.find((x) => x.child_id === categoriesState.comboCategories?.comboCategoryId);

    if (category !== undefined) {
      setIsCategoriesLoaded(false);
      handleLoader();
      dispatchCategory(BaseCategory.fromEcommerceCategory(category), GTM_LIST_NAME_DIRECT_PROMOTION);
    } else {
      //setIsLoaderActive(false);
      handleLoader();
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo sentimos 😔, no hay promociones disponibles por el momento.");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Cerrar");
    }
  };
  const goToCategory = async (categories: EcommerceCategories[]) => {
    // Avoid load promotions category more than once
    setIsCategoriesLoaded(true);
    var category = categories.find((x) => x.description === categoryFilter);

    if (category !== undefined) {
      setIsCategoriesLoaded(false);
      handleLoader();
      dispatchCategory(BaseCategory.fromEcommerceCategory(category), `${GTM_LIST_NAME_DIRECT_CATEGORY} - ${category.group}`);
      dispatch(setCategoryFilter(''));
    } else {
      //setIsLoaderActive(false);
      handleLoader();
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo sentimos 😔, la categoría no esta disponible por el momento.");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Cerrar");
    }
  };

  const loadingMessage: string =
    edit === "true" || editSuggestedOrder === "true"
      ? "¡Estamos cargando tu carrito! Por favor espera un momento"
      : promo === "true"
      ? "Cargando promociones..."
      : "Cargando productos...";

  return (
    <>
      <main className={classes.main}>
        {isInRange && bannerMessage ? <Banner showCloseIcon type="warning" message={bannerMessage} /> : null}
        <PromotionCarousel />
        <CategoryList categories={photoCategories} show={6} />
        {carouselProducts && carouselProducts.length !== 0 && (
          <Grid className={classes.paddingContent}>
            <Typography variant="h2" className={classes.label}>
              Productos Recomendados
            </Typography>
            <ProductCarousel products={carouselProducts} />
          </Grid>
        )}

        {isErrorMessageOpen ? (
          <MessageDialog
            message={errorMessage}
            link={errorMessageButtonLink}
            text={errorMessageButtonText}
            setIsDialogActive={setIsErrorMessageOpen}
          />
        ) : null}
      </main>
    </>
  );
};
