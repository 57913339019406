import { Container, Divider, Grid, makeStyles } from "@material-ui/core";
import { RootState } from "app/store";
import { signIn } from "cds/pages/signIn";
import React from "react";
import { useSelector } from "react-redux";

//TODO: check if it is needed to use this cds-style.css
import "../../assets/cds-sdk-styles.css";

//import "assets/fonts/Text/TCCC-UnityText-Bold.woff2";
import { AddressFormPageLink, CCETHForgotPassword, CDSCreatePageLink } from "features/common/urlBuilder";

const useStyles = makeStyles((theme) => ({
  grid: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "TCCC-UnityText",
  },
  blockTitle: {
    fontSize: "18px",
    fontWeight: 500,
    borderBottom: "1px solid #e8e8e8",
    marginBottom: "15px",
    paddingBottom: "12px",
    textAlign: "left",
  },
  primary: {
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: 25,
    cursor: "pointer",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#e02020",
      border: "1px solid #e02020",
    },
  },
  secondary: {
    textAlign: "center",
    color: "#000000",
    margin: "0 0 46px",
  },
  actionCreatePrimary: {
    fontWeight: 600,
    padding: "13px 25px",
    width: "100%",
    display: "block",
    color: "#fff",
    textDecoration: "none",
  },
  actionRemind: {
    color: "#000000",
  },
  columns: {
    fontFamily: "TCCC-UnityText",
    fontWeight: 400,
    color: "#000000",
    marginBottom: "40px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "35px",
      marginRight: "35px",
    },
  },
  accountBennefitsContainer: {
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    backgroundPosition: "left top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    borderStyle: "solid",
    borderColor: "#979797",
    padding: "35px",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "70px",
      marginTop: "25px",
    },
  },
  listAccountBennefitsContainer: {
    lineHeight: 2,
    fontWeight: 400,
  },
  divider: {
    margin: "30px 0 30px 0px",
  },
  pageTitle: {
    fontSize: "25px",
    marginBottom: "40px",
  },
  blockContent: {
    fontFamily: "TCCC-UnityText",
  },
  cdsFormFormFormLogin: {
    "& p": {
      fontWeight: "500 !important",
      color: "#e02b27 !important",
    },
    "& a": {
      fontWeight: "500 !important",
      color: "#e02b27 !important",
    },
    "& form": {
      fontSize: "15px",
      "& div": {
        marginBottom: "13px",
        "& p": {
          "& .validation-error": {
            fontWeight: 400,
          },
        },
      },
      "& label": {
        fontWeight: 600,
        fontSize: "18px",
        color: "#000000",
      },
      "& input": {
        borderColor: "#000000",
        borderRadius: "0",
        height: "40px",
        width: "100%",
        fontSize: "18px",
      },
      "& #show-my-password-currentPassword": {
        display: "none",
      },
      "& .cds-form-submit-cds-login-form": {
        cursor: "pointer",
        paddingBottom: "0px",
        "& button": {
          padding: "13px 25px",
          width: "100%",
          display: "block",
          border: "1px solid #000000",
          backgroundColor: "#000000",
          borderRadius: 25,
          fontWeight: 600,
          fontSize: "15px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#e02020",
          },
        },
      },
      "& .div-legal_acceptance_id_1": {
        paddingBottom: "0px",
        marginBottom: "24px",
        "& input": {
          position: "absolute",
          width: "20px",
          height: "20px",
          borderColor: "#000000",
          borderRadius: "0",
        },
        "& label": {
          paddingLeft: "35px",
          position: "relative",
          display: "inline-block",
        },
      },
      "& .cds-form-submit-cds-post-login-form": {
        cursor: "pointer",
        paddingBottom: "0px",
        "& button": {
          padding: "13px 25px",
          width: "100%",
          display: "block",
          border: "1px solid #000000",
          backgroundColor: "#000000",
          borderRadius: 25,
          fontWeight: 600,
          fontSize: "15px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#e02020",
          },
        },
      },
    },
  },
  blockSocialLogin: {
    display: "none",
    "& #cds-social-login-form": {
      "& #janrainEngageEmbed": {
        "& .cds-fb-login-button": {
          margin: "0px !important",
          maxWidth: "none !important",
          width: "100%",
          borderRadius: "25px !important",
          background: "#3c5a99 !important",
          "& div": {
            textAlign: "center",
            "& p": {
              display: "inline",
              fontFamily: "TCCC-UnityText",
              fontSize: "16px",
              fontWeight: 700,
              position: "relative",
              top: "2px",
              "&:nth-child(2)": {
                display: "none !important",
              },
            },
          },
        },
      },
    },
  },
  loginContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    paddingBottom: "50px",
  },
}));

export const CDSLoginUser = ({ config }: { config: CDSIdConfig }) => {
  const classes = useStyles();

  const { accountProvider, conversationContext } = useSelector((state: RootState) => state.account);

  signIn(
    config,
    conversationContext?.accountProviderId,
    AddressFormPageLink(),
    conversationContext?.cellPhoneNumber,
    accountProvider?.country_id
  );

  return (
    <>
      <Grid className={classes.grid}>
        <h1 className={classes.pageTitle}>
          <span data-ui-id="page-title-wrapper">Inicia sesión o crea tu cuenta</span>
        </h1>
      </Grid>
      <Container>
        <Grid className={classes.loginContainer}>
          <Grid item xs={12} sm={12} md={6} className={classes.columns}>
            <div>
              <div className={classes.blockTitle}>
                <p>Inicia sesión con cualquier cuenta de The Coca-Cola Company</p>
              </div>
              <div className={classes.blockContent} aria-labelledby="blockCustomerLoginhHeading">
                {/* Login CDS form */}
                <div id={config.loginId} className={classes.cdsFormFormFormLogin}></div>

                <div className={classes.secondary}>
                  <a className={classes.actionRemind} href={CCETHForgotPassword(accountProvider?.country_id)}>
                    <span>¿Quieres restablecer tu contraseña?</span>
                  </a>
                </div>
                <div className={classes.primary}>
                  <div>
                    <a className={classes.actionCreatePrimary} href={CDSCreatePageLink()}>
                      <span>Crear cuenta</span>
                    </a>
                  </div>
                </div>

                <Divider className={classes.divider} />
                <div className={classes.blockSocialLogin}>
                  <div className={classes.blockContent}>
                    {/* Social CDS form */}
                    {/* TODO adding cds-sdk-v6.0.1b will aloud google login */}
                    <div id={config.socialLoginId}></div>
                  </div>
                </div>

                {/* Merge CDS form*/}
                <div id={config.mergeAccountId} className={classes.cdsFormFormFormLogin}></div>

                {/* PostLogin CDS form TODO: Find the correct place to put this div*/}
                <div id={config.postLoginId} className={classes.cdsFormFormFormLogin}></div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.columns}>
            <div className={classes.accountBennefitsContainer}>
              <div
                data-enable-parallax="0"
                data-parallax-speed="0.5"
                data-background-images="{}"
                data-background-type="image"
                data-video-loop="true"
                data-video-play-only-visible="true"
                data-video-lazy-load="true"
                data-video-fallback-src=""
                data-element="inner"
                data-pb-style="RXENGGY"
              >
                <h2 data-content-type="heading" data-appearance="default" data-element="main">
                  Beneficios de crear una cuenta:
                </h2>
                <div
                  className={classes.listAccountBennefitsContainer}
                  data-content-type="text"
                  data-appearance="default"
                  data-element="main"
                >
                  <ul>
                    <li>Hacer pedidos rápidamente</li>
                    <li>Registrar más de una dirección</li>
                    <li>Dar seguimiento a tus órdenes</li>
                    <li>Y más…</li>
                  </ul>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CDSLoginUser;
