import { Container, CssBaseline, Grid } from "@material-ui/core";
import { RootState } from "app/store";
import { AddressFormCo } from "features/main/AddressForms/AddressFormCo";
import { AddressFormGt } from "features/main/AddressForms/AddressFormGt";
import { AddressFormMx } from "features/main/AddressForms/AddressFormMx";
import { SimpleNavbar } from "features/main/Navbars/SimpleNavbar";
import React from "react";
import { useSelector } from "react-redux";

export const AddressFormPage = () => {
  const { accountProvider } = useSelector((state: RootState) => state.account);

  return (
    <>
      <CssBaseline />
      <SimpleNavbar />
      <main>
        <Container>
          <Grid>
            {accountProvider?.country_id === "GT" && <AddressFormGt />}
            {accountProvider?.country_id === "CO" && <AddressFormCo />}
            {accountProvider?.country_id === "MX" && <AddressFormMx />}
          </Grid>
        </Container>
      </main>
    </>
  );
};
