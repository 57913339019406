import {
  Button,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { inputClasses } from "app/tccc-theme";
import CancelIcon from "assets/cancelIcon.svg";
import locationDarkThemeLogo from "assets/icons/ui/location-dark-theme.svg";
import banner from "assets/productsBanner.svg";
import whatsappIcon from "assets/whatsappIcon.svg";
import { initB2Ccds } from "cds/cdsB2Csdk/b2cInit";
import {
  setAccountProvider,
  setCategoryFilter,
  setConversationContext,
  setIsUserLoggedIn,
  setPostLoginRedirectUrl,
  setRegisterAccount,
  setSearchFilter,
  userStatus,
} from "features/common/accountSlice";
import {
  funcAccountAddressGetById,
  funcNotificationPost,
  funcProviderPostAvailabilityZone,
  funcProviderPostValidateAvailabilityZone,
  funcRegisterGetByPhone,
  funcShoppingCartPostDefault,
  getAccountProviderByPhone,
  getConversationContext,
} from "features/common/API";
import { setDeliveryAddresses, setDeliveryData, setSelectedAddressIndex, setPaymentMethods, setSelectedDeliveryDate } from "features/common/deliverySlice";
import { updateAvailabilityZone, updateByGeocodingInfo } from "features/common/geofencingSlice";
import { clearCart, loadShoppingCart } from "features/common/productSlice";
import { BotWhatsAppLink, CDSLoginRelativeLink, HomeRelativeLink } from "features/common/urlBuilder";
import { ModalContext } from "features/contexts/modalContext";
import { useBannerImage } from "features/hooks/useBannerImage";
import { useMaintenanceMessage } from "features/hooks/useMaintenanceMessage";
import { ConversationContext } from "models/conversationContext";
import { EcommerceAddress } from "models/ecommerceAddress";
import { AvailabilityZone } from "models/geofencing";
import React, { useContext, useEffect, useState } from "react";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "../../assets/icons/ui/errorIcon.svg";
import InfoIcon from "../../assets/icons/ui/infoIcon.svg";
import { handleWebviewInizialized } from "../common/gtmEventHandler";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import { MessageDialog } from "./MessageDialog";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "";

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme) =>
  createStyles({
    autocomplete: {
      width: "100%",
      height: "40px",
      textOverflow: "ellipsis",
      ...inputClasses.text,
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      borderRight: "none !important",
      paddingTop: "5px",
      paddingRight: "5px",
      paddingBottom: "5px",
      paddingLeft: "15px",
      "&:focus": {
        outline: "none",
      },
    },
    infoWindow: {
      padding: 0,
      margin: 0,
    },
    validateButtonPrimary: {
      marginTop: "8px",
      ...inputClasses.mobileStylePrimary,
    },
    validateButtonSecondary: {
      marginTop: "16px",
      marginBottom: "24px",
      ...inputClasses.mobileStyleSecondary,
    },
    asyncMapLoadingElement: {
      height: "100%",
    },
    asyncMapElement: {
      height: "100%",
    },
    gridContainer: {
      padding: "16px",
    },
    mapTypography: {
      color: "#000",
      marginBottom: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      },
    },
    mapTypography2: {
      color: "#000",
      marginBottom: "12px",
      marginTop: "12px",
      fontSize: "16px",
    },
    mapTypography3: {
      color: "#000",
      marginBottom: "12px",
      marginTop: "12px",
      marginLeft: "12px",
    },
    mapTypography4: {
      color: "gray",
      marginTop: "8px",
    },
    placeholderMap: {
      height: "216px",
      width: "100%",
      marginTop: "24px",
      marginBottom: "24px",
    },
    locationButton: {
      border: "0",
      background: "transparent",
      textDecoration: "underline",
      cursor: "pointer",
      color: "#000",
      textAlign: "left",
      "&:hover": {
        background: "transparent",
        textDecoration: "underline",
      },
    },
    root: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    banner: {
      width: "100%",
      margin: "auto",
      height: "auto",
      maxHeight: "450px",
      objectFit: "contain"
    },
    whatsappIcon: {
      width: "25px",
      marginRight: "5px",
      marginLeft: "5px",
    },
    clearButton: {
      backgroundColor: "#fff",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      height: "40px",
      border: "1px solid #000",
      borderLeft: "none",
    },
    labelInputText: {
      backgroundColor: "#fff",
      position: "absolute",
      display: "inline-block",
      zIndex: 0,
      left: "23px",
      padding: "2px",
      top: "-10px",
      fontSize: "11px",
      color: "gray",
    },
    infoMessage: {
      ...inputClasses.infoMessage,
      display: "flex",
      flexWrap: "initial",
    },
    errorMessage: {
      ...inputClasses.errorMessage,
      display: "flex",
      flexWrap: "initial",
      marginBottom: "8px",
    },
    infoIconGrid: {
      alignItems: "center",
      display: "flex",
    },
    iconImage: {
      height: "21px",
      width: "21px",
      marginRight: "4px",
      marginLeft: "4px",
      alignItems: "center",
    },
    validateButton: {
      marginTop: "5px",
      marginBottom: "5px",
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
    registerButton: {
      border: "0",
      background: "transparent",
      textDecoration: "underline",
      cursor: "pointer",
      color: "#000",
      textAlign: "center",
      "&:hover": {
        background: "transparent",
        textDecoration: "underline",
      },
    },
  })
);

const Map = ({ center, height, zoom }) => {
  const classes = useStyles({ render: window.innerWidth >= 1280 });

  const navigate = useNavigate();
  const { getMaintenanceMessage } = useMaintenanceMessage();
  const landPage = useBannerImage("landPage")?.find((elem) => true) ?? undefined;
  const { handleModal } = useContext(ModalContext);
  const [maintenanceMessage, setMaintenanceMessage] = useState("");

  const loginB2C = async () => {
    setMaintenanceMessage(getMaintenanceMessage());
    if (maintenanceMessage) {
      handleModal(maintenanceMessage);
      return;
    }
    if (accountProvider?.registrationFlow === "b2c") {
      dispatch(setPostLoginRedirectUrl(window.location.href));
      const sdk = await initB2Ccds(accountProvider?.key);
      sdk.getAuthenticationUrl(true, false);
    } else {
      navigate(CDSLoginRelativeLink());
    }
  };

  // Loader State
  const [loader, setLoader] = useState({
    active: false,
    message: "",
  });
  const [userAddressInput, setUserAddressInput] = useState("");
  const [mapPosition, setMapPosition] = useState(center);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [renderReturnButton, setRenderReturnButton] = useState(false);
  const [isInvalidDialogOpen, setIsInvalidDialogOpen] = useState(false);
  const [renderMap, setRenderMap] = useState(false);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");
  const [gpsErrorMessage, setGpsErrorMessage] = useState("");
  const [isVisibleGpsErrorMessage, setIsVisibleGpsErrorMessage] = useState(false);
  const [gpsErrorTimerId, setGPSErrorTimerId] = useState(undefined);

  const { availabilityZone, geofencingAddress } = useSelector((state) => state.geofencing);
  let { accountProvider, conversationContext, registerAccount } = useSelector((state) => state.account);
  const { selectedAddress, selectedDeliveryDate } = useSelector((state) => state.deliveryData);
  const { cartId } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(window.location.search);
  const contextId = searchParams.get("contextId") || "";
  const returnUrl = searchParams.get("returnUrl") || "";
  const searchFilter = searchParams.get("search") || "";
  const categoryFilter = searchParams.get("ctg") || "";

  const URLS = {
    home: HomeRelativeLink(),
  };
    const DEFAULT_URL = HomeRelativeLink();

  useEffect(() => {
      (async () => {
        setLoader({
          active: true,
          message: "Obteniendo direcciones...",
        });

        const conversationContext = await getConversationContext(contextId);
        const accountProvider = await getAccountProviderByPhone(conversationContext?.whatsappNumber);

        if (!ConversationContext?.isValid(conversationContext, accountProvider)) {
          setIsErrorMessageOpen(true);
          setErrorMessage(
            'Lo siento 😅, el link de compra ha expirado. Presiona regresar para volver a WhatsApp y da click en "Realizar pedido" para continuar con tú compra.'
          );
          setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber));
          setErrorMessageButtonText("Regresar");
          await funcNotificationPost(
            conversationContext?.accountProviderId,
            accountProvider?.link_expiration_template_name,
            conversationContext?.cellPhoneNumber
          );

          setLoader({
            active: false,
            message: "",
          });
          return;
        }

        dispatch(setConversationContext(conversationContext));
        dispatch(setAccountProvider(accountProvider));
        dispatch(setSearchFilter(searchFilter));
        dispatch(setCategoryFilter(categoryFilter));

        registerAccount = await funcRegisterGetByPhone(
          conversationContext.accountProviderId,
          conversationContext.cellPhoneNumber
        );
        dispatch(setIsUserLoggedIn(registerAccount ? userStatus.LoggedIn : userStatus.LoggedOut));
        dispatch(setRegisterAccount(registerAccount));

        if (registerAccount?.account_id && returnUrl !== "home") {
          const userAddresses = (
            await funcAccountAddressGetById(conversationContext.accountProviderId, registerAccount?.account_id)
          )?.reverse();

          // Only execute this for existing users with confirmed address
          if (userAddresses !== undefined && userAddresses.length > 0) {
            setRenderMap(true);
            dispatch(setDeliveryAddresses(userAddresses));

            const deliveryAddress = EcommerceAddress.fromAddress(userAddresses[0]);
            const response = await Geocode.fromAddress(customerAddressToString(userAddresses[0]));
            if (response && response.status === "OK" && response.results.length !== 0) {
              dispatch(updateByGeocodingInfo(response.results[0]));
            }

            // Set the deliveryAddress phone number to have only the last quantity of digits needed by this accountProvider.
            deliveryAddress.phone = deliveryAddress?.phone.slice(-1 * accountProvider?.phone_digits_quantity);
            handleWebviewInizialized(contextId, deliveryAddress?.bottler, accountProvider?.country_id);

            if (selectedAddress === "" || returnUrl === "") {
              dispatch(setDeliveryData(deliveryAddress));
              dispatch(setSelectedAddressIndex(0));

              navigate(HomeRelativeLink());
              return;
            }

            setRenderReturnButton(true);
            const geocodingFromSelectedAddress = await Geocode.fromAddress(selectedAddress);
            if (
              geocodingFromSelectedAddress &&
              geocodingFromSelectedAddress.status === "OK" &&
              geocodingFromSelectedAddress.results.length !== 0
            ) {
              const result = geocodingFromSelectedAddress.results[0];
              setMapPosition({ lat: result.geometry.location.lat, lng: result.geometry.location.lng });
              setMarkerPosition({ lat: result.geometry.location.lat, lng: result.geometry.location.lng });
              setUserAddressInput(result.formatted_address);
              dispatch(updateByGeocodingInfo(result));
            }
          }
        }

        setLoader({
          active: false,
          message: "",
        });
      })();
    }, []);

  const returnToPreviousPage = () => {
    setLoader({
      active: true,
      message: "Cargando...",
    });

    Geocode.fromAddress(selectedAddress).then(
      (response) => {
        if (response && response.status === "OK" && response.results.length !== 0) {
          setUserAddressInput(response.results[0].formatted_address);
          dispatch(updateByGeocodingInfo(response.results[0]));
        }
      },
      (error) => console.log(error)
    );

    navigate(URLS[returnUrl] || DEFAULT_URL);
  };

  const onMarkerDragEnd = (event) => {
    Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      (response) => {
        if (response && response.status === "OK" && response.results.length !== 0) {
          setMapPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
          setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
          setUserAddressInput(response.results[0].formatted_address);
          dispatch(updateByGeocodingInfo(response.results[0]));
        }
      },
      (error) => console.log(error)
    );
  };

  const onPlaceSelected = (place) => {
    setRenderMap(true);
    if (place.formatted_address) {
      place.geometry.location = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      setMapPosition(place.geometry.location);
      setMarkerPosition(place.geometry.location);
      setUserAddressInput(place.formatted_address);
      dispatch(updateByGeocodingInfo(place));
    }
  };

  const onValidateButton = async () => {
    // New Address flow.
    if (!AvailabilityZone.addressComplete(availabilityZone)) {
      setIsErrorMessageOpen(true);
      setErrorMessage(
        "Escriba correctamente su dirección (calle, ciudad, estado...) o arrastra el cursor en el mapa a la ubicación."
      );
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Ok");
      return;
    }

    setLoader({
      active: true,
      message: "Validando dirección...",
    });

    let isUnderCoverage = false;
    var postedAvailabilityZone = await funcProviderPostAvailabilityZone(
      conversationContext.accountProviderId,
      accountProvider?.country_id,
      availabilityZone
    );
    if (postedAvailabilityZone) {
      var validateResult = await funcProviderPostValidateAvailabilityZone(
        conversationContext.accountProviderId,
        postedAvailabilityZone.warehouse_code
      );

      if (validateResult && validateResult.response) {
        isUnderCoverage = true;
      }

      // Cast region_id to string manually
      // This may be happening because we're setting an object of type EcommerceAddress using js instead of ts
      postedAvailabilityZone.region_id = postedAvailabilityZone.region_id?.toString();
    }

    if (!(isUnderCoverage && postedAvailabilityZone)) {
      setIsInvalidDialogOpen(true);

      setLoader({
        active: false,
        message: "",
      });
      return;
    }

    dispatch(updateAvailabilityZone(postedAvailabilityZone));
    var deliveryAddress = EcommerceAddress.fromAvailabilityZone(postedAvailabilityZone);

    // Add the bottlerName to the DeliveryAddress using the bottlers list on the AccountProvider
    var bottlers = JSON.parse(accountProvider?.bottlers || "[]");
    var defaultBottler = bottlers.find((bottler) => bottler.warehouse_code === "*");

    var bottlerName =
      bottlers.find((bottler) => bottler.warehouse_code === deliveryAddress.warehouse_code)?.bottler_name ||
      defaultBottler?.bottler_name;

    handleWebviewInizialized(contextId, bottlerName, accountProvider?.country_id);

    deliveryAddress.phone = conversationContext.cellPhoneNumber.slice(-1 * accountProvider?.phone_digits_quantity); // Set the phone number to the quantity of digits needed by this provider.
    deliveryAddress.bottler = bottlerName;

    dispatch(setDeliveryData(deliveryAddress));
    dispatch(setSelectedDeliveryDate(""));
    dispatch(setPaymentMethods(undefined));

    let shoppingCart;
    if (accountProvider?.is_switching_cart_address && cartId) {
      // Try to Switch address of the Shopping Cart
      shoppingCart = await funcShoppingCartPostDefault(
        conversationContext?.accountProviderId,
        registerAccount?.account_id,
        cartId,
        deliveryAddress.encoded_route_info,
        true,
        false,
        selectedDeliveryDate
      );

      if (shoppingCart) {
        dispatch(loadShoppingCart(shoppingCart));
      }
    }
    if (!shoppingCart) {
      // The ShoppingCart address was not changed => Clean the Cart to create a new one.
      dispatch(clearCart(undefined));
    }
    dispatch(setSelectedAddressIndex(-1));

    // dispatch function works asynchronously, so we need to wait for them to finish.
    await sleep(50);

    navigate(HomeRelativeLink());

    setLoader({
      active: false,
      message: "",
    });
  };

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const onLocationButtonClick = async () => {
    if (gpsErrorTimerId) {
      clearTimeout(gpsErrorTimerId);
    }
    setRenderMap(true);
    setLoader({ active: true, message: "Obteniendo ubicación actual" });
    navigator.geolocation.getCurrentPosition(async (location) => {
      const { latitude, longitude } = location.coords;
      let response;
      if (latitude !== undefined && longitude !== undefined) {
        response = await Geocode.fromLatLng(latitude.toString(), longitude.toString());
        setUserAddressInput(response.results[0].formatted_address);

        if (response && response.status === "OK" && response.results.length !== 0) {
          setMapPosition(response.results[0].geometry.location);
          setMarkerPosition(response.results[0].geometry.location);
          dispatch(updateByGeocodingInfo(response.results[0]));
        }
      }

      setLoader({ active: false, message: "" });
    }, showGPSError);
  };
  function showGPSError(error) {
    setLoader(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setGpsErrorMessage("Asegurate de autorizar el acceso a tu ubicación para utilizar esta función.");
        break;
      case error.POSITION_UNAVAILABLE:
        setGpsErrorMessage("Lo sentimos tu ubicación no esta disponible.");
        break;
      case error.TIMEOUT:
        setGpsErrorMessage("El tiempo para obtener tu ubicación ha expirado.");
        break;
      case error.UNKNOWN_ERROR:
        setGpsErrorMessage("Lo sentimos ha ocurrido un error inesperado. Intentalo nuevamente.");
        break;
    }
    setIsVisibleGpsErrorMessage(true);

    setGPSErrorTimerId(
      setTimeout(() => {
        setIsVisibleGpsErrorMessage(false);
      }, 5000)
    );
  }
  const goBackToWhatsappButtonClick = async () => {
    await funcNotificationPost(
      conversationContext?.accountProviderId,
      accountProvider?.invalid_address_template_name,
      conversationContext?.cellPhoneNumber
    );
    window.location.replace(BotWhatsAppLink(accountProvider?.whatsapp_number));
  };

  const customerAddressToString = (customerAddress) => {
    return EcommerceAddress.toString(EcommerceAddress.fromAddress(customerAddress));
  };

  //Hide unnecesary Google Maps Control
  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  };

  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={zoom} defaultCenter={{ lat: mapPosition.lat, lng: mapPosition.lng }} options={mapOptions}>
        {/*Marker*/}
        <Marker
          name={"Dolores park"}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        >
          {/* InfoWindow on top of marker */}
          <InfoWindow>
            <div>
              <Typography variant="caption" className={classes.infoWindow}>
                {geofencingAddress}
              </Typography>
            </div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    ))
  );

  const AsyncAutocomplete = withScriptjs((props) => (
    <FormGroup row style={{ alignItems: "center", flexWrap: "initial", position: "relative" }}>
      <label className={classes.labelInputText}>Calle, #, CP, ciudad, región, colonia, país</label>
      <Autocomplete
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
        className={classes.autocomplete}
        apiKey={GOOGLE_MAPS_API_KEY}
        onPlaceSelected={onPlaceSelected}
        types={[]}
        options={{
          // countries must be passed as as a two-character, ISO 3166-1 Alpha-2 compatible country code. https://developers.google.com/maps/documentation/javascript/places-autocomplete#places-searchbox
          componentRestrictions: { country: accountProvider?.country_id },
          types: []
        }}
        placeholder="Escribe tu dirección"
        defaultValue={userAddressInput}
      />
      <Button
        variant="contained"
        disableElevation
        disableRipple
        className={classes.clearButton}
        onClick={() => setUserAddressInput("")}
      >
        <img src={CancelIcon} className={classes.cancelIcon} alt="" />
      </Button>
    </FormGroup>
  ));

  let map;
  if (mapPosition.lat !== undefined && renderMap) {
    map = (
      <Grid style={{ marginTop: "8px" }}>
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
          loadingElement={<div className={classes.asyncMapLoadingElement} />}
          containerElement={<div style={{ height: height }} />}
          mapElement={<div className={classes.asyncMapElement} />}
        />
        <Grid item container direction="row" className={classes.infoMessage} style={{ marginTop: "8px" }}>
          <Grid item className={classes.infoIconGrid}>
            <img className={classes.iconImage} alt="logo" src={InfoIcon} />
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: "14px" }} variant="h6">
              Mueve el pin de ser necesario para ajustar tu dirección de entrega.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  } /*else {
    map = <img alt="" src={placeholderMap} className={classes.placeholderMap} style={{}} />;
  }*/

  return (
    <div className={classes.root}>
      <Container className={classes.gridContainer}>
        {!renderMap && (
          <Typography variant="h3" className={classes.mapTypography} style={{ marginBottom: "50px" }}>
            Recibe tus productos favoritos sin salir de casa
          </Typography>
        )}
        <Grid>
          {renderMap && (
            <>
              {isVisibleGpsErrorMessage ? (
                <Grid item container direction="row" className={classes.errorMessage}>
                  <Grid item className={classes.infoIconGrid}>
                    <img className={classes.iconImage} alt="logo" src={ErrorIcon} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: "14px" }} variant="h6">
                      {gpsErrorMessage}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              <Button
                className={classes.validateButtonSecondary}
                style={{ marginTop: "0px", marginBotton: "16px" }}
                color="secondary"
                onClick={() => onLocationButtonClick()}
              >
                <img src={locationDarkThemeLogo} alt="location" />
                <Typography style={{ paddingLeft: "3px" }} variant="body2">
                  Usar mi ubicación actual
                </Typography>
              </Button>
            </>
          )}
          <AsyncAutocomplete
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
            loadingElement={<div className={classes.asyncMapLoadingElement} />}
          />
        </Grid>
        {map}
        <Grid>
          {!renderMap && (
            <>
              <Button disableRipple className={classes.locationButton} onClick={() => onLocationButtonClick()}>
                <img src={locationDarkThemeLogo} alt="location" />
                <Typography style={{ paddingLeft: "3px" }} variant="body1">
                  Usar mi ubicación actual
                </Typography>
              </Button>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  display: accountProvider?.is_using_login_on_geofencing && !registerAccount ? undefined : "none",
                }}
              >
                <Button
                  id="geofencingLoginButton"
                  className={classes.validateButton + " " + classes.mobileStylePrimary}
                  color="primary"
                  onClick={loginB2C}
                >
                  Iniciar sesión
                </Button>
                <Button id="geofencingSignInButton" disableRipple className={classes.registerButton} onClick={loginB2C}>
                  <Typography variant="body1">O Crear cuenta</Typography>
                </Button>
              </div>
            </>
          )}
          {renderMap && (
            <Grid className={classes.gridValidateButtonPrimary}>
              <Button className={classes.validateButtonPrimary} color="primary" onClick={() => onValidateButton()}>
                Confirmar dirección
              </Button>
            </Grid>
          )}
          {renderReturnButton && (
            <Grid>
              <Button
                className={classes.validateButtonSecondary}
                color="secondary"
                onClick={() => returnToPreviousPage()}
              >
                Regresar
              </Button>
            </Grid>
          )}
          <Dialog open={isInvalidDialogOpen}>
            <DialogContent>
              <DialogContentText color={"textPrimary"}>
                Lo siento 😔 por el momento no tenemos cobertura en esta área
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button color="secondary" onClick={() => goBackToWhatsappButtonClick()}>
                <Typography style={{ paddingLeft: "3px" }} variant="body2">
                  Regresar a
                </Typography>
                <img src={whatsappIcon} className={classes.whatsappIcon} alt="whatsappIcon" />
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setIsInvalidDialogOpen(false);
                }}
              >
                Continuar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        {loader.active ? <FullPageLoader type={LoaderType.Authentication} text={loader.message} /> : <></>}
      </Container>
      {!renderMap && (accountProvider || isErrorMessageOpen) && <img src={landPage ? landPage.src : banner} className={classes.banner} alt="banner" />}
      {isErrorMessageOpen && (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      )}
    </div>
  );
};

export default Map;