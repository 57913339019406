import { Button, createStyles, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { setRegisterAccount } from "features/common/accountSlice";
import {
  funcAccountAddressPost,
  funcProviderAvailabilityZoneGetSettlements,
  funcProviderPostAvailabilityZone,
  funcProviderPostValidateAvailabilityZone,
  funcRegisterGetByPhone,
  funcShoppingCartPostDefault,
} from "features/common/API";
import { setDeliveryData } from "features/common/deliverySlice";
import { updateAvailabilityZone } from "features/common/geofencingSlice";
import {
  GeofencingPageLink,
  HomePageLink,
  HomeRelativeLink,
  ProductsPageLink,
} from "features/common/urlBuilder";
import { EcommerceAddress } from "models/ecommerceAddress";
import { AvailabilityZone } from "models/geofencing";
import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextErrorIcon from "../../../assets/icons/ui/textInputErrorIcon.svg";
import { FullPageLoader, LoaderType } from "../FullPageLoader";
import { MessageDialog } from "../MessageDialog";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "";

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: "100%",
      fontWeight: 500,
    },
    inputGrid: {
      marginTop: theme.spacing(2),
    },
    inputGridSeparator: {
      marginTop: theme.spacing(4),
    },
    textField: {
      width: "100%",
    },
    textFieldLabelRoot: {
      ...inputClasses.textFieldLabel.root,
    },
    textFieldLabelFocus: {
      ...inputClasses.textFieldLabel.focus,
    },
    textFieldLabelError: {
      ...inputClasses.textFieldLabel.error,
    },
    textFieldInputRoot: {
      ...inputClasses.textFieldInput.root,
    },
    textFieldInputOutlined: {
      ...inputClasses.textFieldInput.notchedOutline,
    },
    textFieldInputFocus: {
      ...inputClasses.textFieldInput.focus,
    },
    textFieldInputError: {
      ...inputClasses.textFieldInput.error,
    },
    textFieldFilled: {
      width: "100%",
    },
    disabledTextFieldLabelRoot: {
      ...inputClasses.disabledTextFieldLabel.root,
    },
    disabledTextFieldLabelFocus: {
      ...inputClasses.disabledTextFieldLabel.focus,
    },
    disabledTextFieldLabelError: {
      ...inputClasses.disabledTextFieldLabel.error,
    },
    disabledTextFieldInputRoot: {
      ...inputClasses.disabledTextFieldInput.root,
    },
    disabledTextFieldInputOutlined: {
      ...inputClasses.disabledTextFieldInput.notchedOutline,
    },
    disabledTextFieldInputFocus: {
      ...inputClasses.disabledTextFieldInput.focus,
    },
    disabledTextFieldInputError: {
      ...inputClasses.disabledTextFieldInput.error,
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
    iconImage: {
      height: "16px",
      width: "16px",
      marginRight: "4px",
      marginLeft: "4px",
      alignItems: "center",
    },
  })
);

const helperTextStyles = makeStyles((theme) => ({
  ...inputClasses.textFieldHelperText,
}));

export const AddressFormMx = () => {
  const classes = useStyles();
  const helperTextClasses = helperTextStyles();
  const navigate = useNavigate();

  const { availabilityZone, isValid } = useSelector((state: RootState) => state.geofencing);
  let { deliveryData, deliveryAddresses } = useSelector(
    (state: RootState) => state.deliveryData
  );
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { cartId } = useSelector((state: RootState) => state.products);

  const accountProviderId = accountProvider?.key ? accountProvider?.key : 0;

  // Loader State
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const [references, setReferences] = useState("");
  const [extNumber, setExtNumber] = useState(availabilityZone?.address.number ?? "");
  const [intNumber, setIntNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState(availabilityZone?.address.neighborhood ?? "");
  const [city, setCity] = useState(availabilityZone?.address.city ?? "");
  const [municipality, setMunicipality] = useState(availabilityZone?.address.municipality ?? "");
  const [colonies, setColonies] = useState<Settlement[]>([]);
  const [postalCode, setPostalCode] = useState<string>(availabilityZone?.address.postal_code ?? "");
  const [street, setStreet] = useState(availabilityZone?.address.street ?? "");

  var initalPhoneNumber: string =
    conversationContext?.cellPhoneNumber.startsWith(accountProvider?.phone_digits_prefix as string) &&
    conversationContext?.cellPhoneNumber.length ===
      (accountProvider?.phone_digits_prefix.length as number) + (accountProvider?.phone_digits_quantity as number)
      ? (conversationContext?.cellPhoneNumber.slice(-1 * (accountProvider?.phone_digits_quantity as number)) as string)
      : "";

  var initialFirstName: string = registerAccount?.name.split(" ")[0] || "";
  var initialLastName: string = registerAccount?.name.split(" ").slice(1).join(" ") || "";

  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [phone, setPhone] = useState<string>(initalPhoneNumber);

  const validatePhone = (phone: string): string => {
    let error = "";
    if (phone === "") {
      error = "Campo obligatorio";
    } else if (phone.length !== accountProvider?.phone_digits_quantity) {
      error = `El número de teléfono debe tener ${accountProvider?.phone_digits_quantity} dígitos.`;
    } else if (!/^\d+$/.test(phone)) {
      error = `El número de teléfono solo puede tener dígitos.`;
    }
    return error;
  };

  const validatePostalCode = (postalCode: string): string => {
    let error = "";
    if (postalCode === "") {
      error = "Campo obligatorio";
    } else if (!/^\d{5}$/.test(postalCode)) {
      error = "El código postal debe ser de 5 dígitos.";
    }
    return error;
  };

  const [extNumberError, setExtNumberError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [neighboorhoodError, setNeighborhoodError] = useState("");
  const [cityError, setCityError] = useState("");
  const [municipalityError, setMunicipalityError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [streetError, setStreetError] = useState("");
  const [referencesError, setReferencesError] = useState("");

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  useEffect(() => {
    if (!deliveryData.warehouse_code || !availabilityZone) {
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo siento 😅, tu sesión ha expirado, presiona regresar para continuar con el flujo de compra.");
      setErrorMessageButtonLink(GeofencingPageLink());
      setErrorMessageButtonText("Regresar");
      return;
    }
    if (postalCode.length === 5) {
      funcProviderAvailabilityZoneGetSettlements(
        accountProviderId,
        availabilityZone?.address.country_id as string,
        postalCode
      ).then((settlements) => {
        const validColonies: Settlement[] = settlements ? settlements : [];
        const validCities: Settlement[] = settlements ? settlements : [];
        const validMunicipalities: Settlement[] = settlements ? settlements : [];
        setColonies(validColonies);

        validColonies.length !== 0 ? setNeighborhood(validColonies[0].settlement) : setNeighborhood("");
        validCities.length !== 0 ? setCity(validCities[0].city) : setCity("");
        validMunicipalities.length !== 0 ? setMunicipality(validMunicipalities[0].municipality) : setMunicipality("");
      });
    }
  }, [postalCode]);

  useEffect(() => {
    // If no registerAccount is saved because the user was a new one we need to request for it and save it
    (async () => {
      if (!registerAccount) {
        var registerAccount = await funcRegisterGetByPhone(
          conversationContext?.accountProviderId as number,
          conversationContext?.cellPhoneNumber as string
        );
        dispatch(setRegisterAccount(registerAccount as RegisterAccount));

        var initialFirstName: string = registerAccount?.name.split(" ")[0] || "";
        var initialLastName: string = registerAccount?.name.split(" ").slice(1).join(" ") || "";

        setFirstName(initialFirstName);
        setLastName(initialLastName);
      }
    })();
  }, []);

  const validateForm = () => {
    let isValid = true;
    if (extNumber === "") {
      setExtNumberError("Campo obligatorio");
      isValid = false;
    } else {
      setExtNumberError("");
    }

    if (neighborhood === "") {
      setNeighborhoodError("Selección obligatoria");
      isValid = false;
    } else {
      setNeighborhoodError("");
    }

    if (city === "") {
      setCityError("Selección obligatoria");
      isValid = false;
    } else {
      setCityError("");
    }

    if (municipality === "") {
      setMunicipalityError("Selección obligatoria");
      isValid = false;
    } else {
      setMunicipalityError("");
    }

    if (street === "") {
      setStreetError("Campo obligatorio");
      isValid = false;
    } else {
      setStreetError("");
    }

    let tempPostalCodeError: string = "";
    if (postalCode.length !== 5)
      tempPostalCodeError = "El código postal en México consta de 5 cifras. Por favor, intenta uno diferente";
    else if (!colonies || colonies.length === 0)
      tempPostalCodeError +=
        "Este código postal ingresado no se encuentra en el Catálogo Nacional de Códigos Postales. Por favor, intenta uno diferente";
    else if (colonies[0].region.region && colonies[0].region.region !== deliveryData?.region)
      tempPostalCodeError +=
        "Este código postal parece estar demasiado lejos de tu ubicación de entrega inicial. Por favor, intenta uno diferente";

    setPostalCodeError(tempPostalCodeError);
    isValid = isValid && tempPostalCodeError === "";

    if (phone === "") {
      setPhoneError("Campo obligatorio");
      isValid = false;
    } else if (phone.length !== accountProvider?.phone_digits_quantity) {
      setPhoneError(`El número de teléfono debe tener ${accountProvider?.phone_digits_quantity} dígitos.`);
      isValid = false;
    } else if (!/^\d+$/.test(phone)) {
      setPhoneError(`El número de teléfono solo puede tener dígitos.`);
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (firstName === "") {
      setFirstNameError("Campo obligatorio");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (lastName === "") {
      setLastNameError("Campo obligatorio");
      isValid = false;
    } else {
      setLastNameError("");
    }

    return isValid;
  };

  const onSendClick = async () => {
    if (validateForm()) {
      setIsLoaderActive(true);
      window.onbeforeunload = () => true;
      const isAddressChanged = deliveryData.number !== extNumber || deliveryData.postal_code !== postalCode;
      deliveryData.number = extNumber as string;
      deliveryData.internal_number = intNumber;
      deliveryData.neighborhood = neighborhood;
      deliveryData.city = city;
      deliveryData.street = street;
      deliveryData.municipality = municipality;
      deliveryData.references = references;
      deliveryData.postal_code = postalCode;
      deliveryData.name = `${firstName} ${lastName}`;
      deliveryData.phone = phone;

      if (isAddressChanged) {
        // When the address main information is changed we need to get the new coordinates and availability zone information.
        try {
          const geocodeResponse = await Geocode.fromAddress(EcommerceAddress.toGeocodeString(deliveryData));

          if (geocodeResponse.status === "OK" && geocodeResponse.results.length !== 0) {
            const coordinates = geocodeResponse.results[0].geometry.location;

            if (
              (coordinates.lat as number) !== deliveryData.coordinates.lat ||
              (coordinates.lng as number) !== deliveryData.coordinates.lng
            ) {
              const availabilityZone = AvailabilityZone.fromGeocodingInfo(geocodeResponse.results[0]);

              const postedAvailabilityZone = await funcProviderPostAvailabilityZone(
                conversationContext?.accountProviderId!,
                accountProvider?.country_id!,
                availabilityZone
              );
              let isUnderCoverage = false;
              if (postedAvailabilityZone) {
                const validateResult = await funcProviderPostValidateAvailabilityZone(
                  conversationContext?.accountProviderId!,
                  postedAvailabilityZone.warehouse_code!
                );

                if (validateResult && validateResult.response) {
                  isUnderCoverage = true;
                }

                postedAvailabilityZone.region_id = postedAvailabilityZone.region_id!;
              }

              if (isUnderCoverage && postedAvailabilityZone) {
                dispatch(updateAvailabilityZone(postedAvailabilityZone));
                const deliveryAddress = EcommerceAddress.fromAvailabilityZone(postedAvailabilityZone);

                if (deliveryAddress.warehouse_code !== deliveryData.warehouse_code) {
                  setIsErrorMessageOpen(true);
                  setErrorMessage("La nueva dirección que ingresaste pertenece a otro almacén.");
                  setErrorMessageButtonLink(HomePageLink());
                  setErrorMessageButtonText("Ok");
                  navigate(HomeRelativeLink());
                }

                // Add the bottlerName to the DeliveryAddress using the bottlers list on the AccountProvider
                const bottlers = JSON.parse(accountProvider?.bottlers || "[]");
                const defaultBottler = bottlers.find((bottler: Bottler) => bottler.warehouse_code === "*");

                const bottlerName =
                  bottlers.find((bottler: Bottler) => bottler.warehouse_code === deliveryAddress.warehouse_code)
                    ?.bottler_name || defaultBottler?.bottler_name;

                deliveryAddress.phone = conversationContext?.cellPhoneNumber.slice(
                  -1 * accountProvider?.phone_digits_quantity!
                ) as string; // Set the phone number to the quantity of digits needed by this provider.
                deliveryAddress.bottler = bottlerName;

                deliveryData = deliveryAddress;
                deliveryData.region_id = String(deliveryAddress.region_id);

                deliveryData.number = extNumber;
                deliveryData.internal_number = intNumber;
                deliveryData.neighborhood = neighborhood;
                deliveryData.references = references;
                deliveryData.postal_code = postalCode;
                deliveryData.street = street;
                deliveryData.municipality = municipality;
                deliveryData.region = deliveryAddress.region;
                deliveryData.country_id = deliveryAddress.country_id;
                deliveryData.city = city;
                deliveryData.region_code = deliveryAddress.region_code;
                deliveryData.name = `${firstName} ${lastName}`;
                deliveryData.phone = phone;
              } else {
                setIsErrorMessageOpen(true);
                setErrorMessage("¡Oh no! La dirección que agregaste ya no está en nuestra cobertura.");
                setErrorMessageButtonLink(GeofencingPageLink());
                setErrorMessageButtonText("Ok");
                return;
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      var createdAddress: EcommerceAddress | undefined = deliveryAddresses?.map((address) => EcommerceAddress.fromAddress(address))
        .find((deliveryAddress) => EcommerceAddress.toString(deliveryAddress) === EcommerceAddress.toString(deliveryData));

      if (!createdAddress) {
        var response = await funcAccountAddressPost(
          conversationContext?.accountProviderId as number,
          registerAccount?.account_id as string,
          deliveryData
        );

        if (!response || "data" in response) {
          setIsErrorMessageOpen(true);
          setErrorMessage(`Lo sentimos 😔, no he podido guardar tu dirección. Puedes intentarlo nuevamente.`);
          setErrorMessageButtonLink("");
          setErrorMessageButtonText("Ok");
          return;
        }
        createdAddress = EcommerceAddress.fromAddress(response as Address);
      }

      // Call to Switch the CartAddress with the new updated one to update the User Segmentation rules.
      await funcShoppingCartPostDefault(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        cartId,
        createdAddress.encoded_route_info as string,
        true,
        false,
      );

      createdAddress.is_new = false;
      dispatch(setDeliveryData(createdAddress));

      // Go to cart to confirmOrder
      setIsLoaderActive(false);
      window.onbeforeunload = () => undefined;
      window.location.replace(ProductsPageLink() + '#paymentSectionId');
    }
  };

  let addressForm = (
    <>
      <Typography variant="h1" className={classes.title}>
        Dirección de Entrega
      </Typography>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: firstNameError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          value={firstName}
          error={firstNameError !== ""}
          helperText={firstNameError}
          label="Nombre"
          variant="outlined"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: lastNameError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          value={lastName}
          error={lastNameError !== ""}
          helperText={lastNameError}
          label="Apellido"
          variant="outlined"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: phoneError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          defaultValue={phone}
          error={phoneError !== ""}
          helperText={phoneError}
          label="Teléfono"
          variant="outlined"
          onChange={(e) => {
            setPhone(e.target.value);
            setPhoneError(validatePhone(e.target.value));            
          }}
        />
      </Grid>
      <Grid className={classes.inputGridSeparator}>
        {availabilityZone?.address.street && availabilityZone?.address.street !== "undefined" && (
          <TextField
            className={classes.textFieldFilled}
            InputLabelProps={{
              classes: {
                root: classes.disabledTextFieldLabelRoot,
                focused: classes.disabledTextFieldLabelFocus,
                error: classes.disabledTextFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.disabledTextFieldInputRoot,
                focused: classes.disabledTextFieldInputFocus,
                notchedOutline: classes.disabledTextFieldInputOutlined,
                error: classes.disabledTextFieldInputError,
              },
            }}
            required
            disabled
            label="Calle"
            defaultValue={availabilityZone?.address.street}
            variant="outlined"
          />
        )}
        {!(availabilityZone?.address.street && availabilityZone?.address.street !== "undefined") && (
          <TextField
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            required
            defaultValue={street}
            error={streetError !== ""}
            helperText={streetError}
            label="Calle"
            variant="outlined"
            onChange={(e) => setStreet(e.target.value)}
          />
        )}
      </Grid>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: extNumberError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          defaultValue={extNumber}
          error={extNumberError !== ""}
          helperText={extNumberError}
          label="Núm. Exterior"
          variant="outlined"
          onChange={(e) => setExtNumber(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
          }}
          label="Núm. Interior"
          variant="outlined"
          onChange={(e) => setIntNumber(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: postalCodeError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          defaultValue={postalCode}
          error={postalCodeError !== ""}
          helperText={postalCodeError}
          label="Código Postal"
          variant="outlined"
          onChange={(e) => {
            setPostalCode(e.target.value);
            setPostalCodeError(validatePostalCode(e.target.value));
          }}
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
          }}
          select
          label="Colonia"
          value={neighborhood}
          onChange={(e) => setNeighborhood(e.target.value)}
          SelectProps={{
            native: true,
          }}
          helperText={neighboorhoodError}
          variant="outlined"
        >
          {colonies.map((colony) => (
            <option key={colony.settlement} value={colony.settlement}>
              {colony.settlement}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textFieldFilled}
          InputLabelProps={{
            classes: {
              root: classes.disabledTextFieldLabelRoot,
              focused: classes.disabledTextFieldLabelFocus,
              error: classes.disabledTextFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.disabledTextFieldInputRoot,
              focused: classes.disabledTextFieldInputFocus,
              notchedOutline: classes.disabledTextFieldInputOutlined,
              error: classes.disabledTextFieldInputError,
            },
          }}
          required
          disabled
          label="Ciudad"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          helperText={cityError}
          error={cityError !== ""}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textFieldFilled}
          InputLabelProps={{
            classes: {
              root: classes.disabledTextFieldLabelRoot,
              focused: classes.disabledTextFieldLabelFocus,
              error: classes.disabledTextFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.disabledTextFieldInputRoot,
              focused: classes.disabledTextFieldInputFocus,
              notchedOutline: classes.disabledTextFieldInputOutlined,
              error: classes.disabledTextFieldInputError,
            },
          }}
          required
          disabled
          label="Municipio"
          value={municipality}
          onChange={(e) => setMunicipality(e.target.value)}
          helperText={municipalityError}
          error={municipalityError !== ""}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textFieldFilled}
          InputLabelProps={{
            classes: {
              root: classes.disabledTextFieldLabelRoot,
              focused: classes.disabledTextFieldLabelFocus,
              error: classes.disabledTextFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.disabledTextFieldInputRoot,
              focused: classes.disabledTextFieldInputFocus,
              notchedOutline: classes.disabledTextFieldInputOutlined,
              error: classes.disabledTextFieldInputError,
            },
          }}
          required
          disabled
          label="Estado/Provincia"
          defaultValue={availabilityZone?.address.region}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: referencesError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          defaultValue={references}
          error={referencesError !== ""}
          helperText={referencesError}
          label="Referencias y/o Entre Calles (opcional)"
          variant="outlined"
          onChange={(e) => setReferences(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <Button
          disabled={isLoaderActive}
          className={classes.mobileStylePrimary}
          color="primary"
          onClick={() => onSendClick()}
        >
          Proceder al Pago
        </Button>
      </Grid>
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );

  return isLoaderActive ? (
    <FullPageLoader
      type={LoaderType.Authentication}
      text="Procesando dirección..."
    />
  ) : (
    addressForm
  );
};
