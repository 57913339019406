import { CssBaseline } from "@material-ui/core";
import { initB2Ccds } from "cds/cdsB2Csdk/b2cInit";
import { setAccountProvider, setConversationContext, setRegisterAccount, setRegistrationFlow } from "features/common/accountSlice";
import { funcRegisterGetByPhone, getAccountProviderByPhone, getConversationContext } from "features/common/API";
import { BotWhatsAppLink } from "features/common/urlBuilder";
import { FullPageLoader, LoaderType } from "features/main/FullPageLoader";
import { MessageDialog } from "features/main/MessageDialog";
import { ConversationContext } from "models/conversationContext";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const AuthenticationPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [isLoaderActive, setIsLoaderActive] = useState(true);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  const contextId = searchParams.get("contextId") || "";
  const registrationFlowId = searchParams.get("registrationFlow") || "";
  const registrationReturnTemplate = searchParams.get("registrationReturnTemplate") || "";

  useEffect(() => {
    (async () => {
      const conversationContext = await getConversationContext(contextId);
      const accountProvider = await getAccountProviderByPhone(conversationContext?.whatsappNumber!);
      const registerAccount = await funcRegisterGetByPhone(
        conversationContext?.accountProviderId!,
        conversationContext?.cellPhoneNumber!
      ) as RegisterAccount;
      dispatch(setRegisterAccount(registerAccount));

      if (!ConversationContext.isValid(conversationContext, accountProvider) || registerAccount) {
        setIsErrorMessageOpen(true);
        setErrorMessage(
          'Lo siento 😅, el link de registro ha expirado. Presiona Regresar para volver a WhatsApp.'
        );
        setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber));
        setErrorMessageButtonText("Regresar");

        setIsLoaderActive(false);
        return;
      }

      dispatch(setConversationContext(conversationContext!));
      dispatch(setAccountProvider(accountProvider!));
      dispatch(setRegistrationFlow({registrationFlow: registrationFlowId, registrationReturnTemplate: registrationReturnTemplate}));

      const sdk = await initB2Ccds(accountProvider?.key);
      sdk.getAuthenticationUrl(true, false);
    })();
  }, []);

  return (
    <>
      <CssBaseline />
      {isLoaderActive ?<FullPageLoader type={LoaderType.Authentication} text={"Cargando..."} /> : <></>}
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );
};
