import { RootState } from 'app/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBannerImage = (bannerType: string) => {
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const [banners, setBanners] = useState<Banner[]>([]);

  useEffect(() => {
    updateBanners();
  }, [accountProvider]);

  const updateBanners = () => {
    const now = new Date();
    const bannersToReturn = accountProvider?.banners?.filter((banner) => {
      const { start_date, end_date, type, bottler, warehouse_id } = banner;

      const isValidType = bannerType === type;
      const isInRange = now >= new Date(start_date!) && now <= new Date(end_date!);
      const isValidBottler = bottler === '*' || bottler === deliveryData.bottler;
      const isValidWarehouse = warehouse_id === '*' || warehouse_id === deliveryData.warehouse_code;

      return isValidType && isInRange && isValidBottler && isValidWarehouse;
    });

    switch (bannerType) {
      case 'category':
      case 'logo':
        // Sort by warehouse_id in descending order so * becomes lowest priority
        setBanners(bannersToReturn?.sort((a, b) => b.warehouse_id!.localeCompare(a.warehouse_id!)) ?? []);
        break;
      default:
        // Sort by order in ascending order
        setBanners(bannersToReturn?.sort((a, b) => a.order - b.order) ?? []);
        break;
    }
  };

  return banners;
};
