import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import chevronDownIcon from "assets/icons/ui/chevron-down.svg";
import chevronUpIcon from "assets/icons/ui/chevron-up.svg";
import truckIcon from "assets/icons/ui/truck.svg";
import { funcShoppingCartPostDefault } from "features/common/API";
import {
  setDeliveryData,
  setPaymentMethods,
  setSelectedAddressIndex,
  setSelectedDeliveryDate,
} from "features/common/deliverySlice";
import { updateByGeocodingInfo } from "features/common/geofencingSlice";
import { clearCart, loadShoppingCart } from "features/common/productSlice";
import { GeofencingRelativeLink, HomeRelativeLink } from "features/common/urlBuilder";
import { useOutsideClick } from "features/hooks/useOutsideClick";
import { EcommerceAddress } from "models/ecommerceAddress";
import { ShoppingCart } from "models/ecommerceCart";
import React, { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import { LogoMain } from "./LogoMain";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "";
Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme) =>
  createStyles({
    rootChangeAddress: {
      textAlign: "center",
    },
    logoAddress: {
      height: "30px",
      cursor: "pointer",
      marginTop: "25px",
    },
    changeAddressHeader: {
      fontWeight: 500,
      color: "#000000",
    },
    addressButtonsDialog: {
      display: "flex",
      textAlign: "center",
    },
    geofencingRouteHeader: {
      padding: "8px 16px 0px 16px",
    },
    gridContainer: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "10px",
      backgroundColor: "#fafafa",
    },
    selectLabel: {
      width: "100%",
      verticalAlign: "middle",
      paddingTop: "10px",
      paddingBottom: "3px",
    },
    listItem: {
      padding: "0px",
    },
    checkBox: {
      ...inputClasses.checkBox,
    },
    validateButton: {
      marginTop: "5px",
      marginBottom: "5px",
    },
    address: {
      textAlign: "left",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      whiteSpace: "normal",
      paddingRight: "16px",
      paddingLeft: "16px",
    },
    expandButton: {
      height: "100%",
      textTransform: "unset",
      backgroundColor: "#fafafa",
      color: "#000",
      padding: "16px 16px 8px 16px",
    },
    locationIcon: {
      margin: "auto",
      [theme.breakpoints.up("md")]: {
        textAlign: "right",
      },
    },
    chevronIcon: {
      margin: "auto",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    addressList: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
  })
);

export const AddressList = () => {
  const [loader, setLoader] = useState<{ active: boolean; message: string }>({
    active: false,
    message: "",
  });
  const [renderList, setRenderList] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [addNewAddress, setAddNewAddress] = useState<boolean>(false);
  const navigate = useNavigate();

  const wrapperRef = useRef(null);

  const { clickedOutside } = useOutsideClick(wrapperRef);

  const { accountProvider, conversationContext, registerAccount } = useSelector((state: RootState) => state.account);
  const { cartId } = useSelector((state: RootState) => state.products);
  const { selectedAddress, deliveryAddresses, selectedAddressIndex, selectedDeliveryDate, deliveryData } = useSelector(
    (state: RootState) => state.deliveryData
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const addressesSize: number = accountProvider?.addressesSize || 3;
  const addressesDisplayed = deliveryAddresses?.slice(0, addressesSize);

  const onAddressChoiceSelected = (index: number) => {
    setSelectedIndex(index);
    setShowDialog(true);
  };

  const customerAddressToString = (customerAddress: Address) => {
    return EcommerceAddress.toString(EcommerceAddress.fromAddress(customerAddress));
  };

  const changeSelectedAddress = async () => {
    setRenderList(false);
    try {
      if (addNewAddress) {
        navigate(GeofencingRelativeLink("home"));
        return;
      }

      // Select existing address from the list
      setLoader({ active: true, message: "Validando dirección..." });

      const response = await Geocode.fromAddress(customerAddressToString(addressesDisplayed![selectedIndex]));
      if (response && response.status === "OK" && response.results.length !== 0) {
        dispatch(updateByGeocodingInfo(response.results[0]));
      }
      const deliveryAddress = EcommerceAddress.fromAddress(addressesDisplayed![selectedIndex]);

      // Set the deliveryAddress phone number to have only the last quantity of digits needed by this accountProvider.
      deliveryAddress.phone = deliveryAddress?.phone!.slice(-1 * accountProvider?.phone_digits_quantity!);
      dispatch(setDeliveryData(deliveryAddress));
      dispatch(setSelectedAddressIndex(selectedIndex));
      dispatch(setSelectedDeliveryDate(""));
      dispatch(setPaymentMethods(undefined));

      let shoppingCart: ShoppingCart | undefined;
      if (accountProvider?.is_switching_cart_address && cartId) {
        // Try to Switch address of the Shopping Cart
        shoppingCart = await funcShoppingCartPostDefault(
          conversationContext?.accountProviderId as number,
          registerAccount?.account_id as string,
          cartId,
          deliveryAddress.encoded_route_info as string,
          true,
          false,
          selectedDeliveryDate
        );

        if (shoppingCart) {
          dispatch(loadShoppingCart(shoppingCart));
        }
      }
      if (!shoppingCart) {
        // The ShoppingCart address was not changed => Clean the Cart to create a new one.
        dispatch(clearCart(undefined));
      }

      // For some reason, if we don't wait for the next tick during the execution,
      // the component will redirect without dispatching the 3 actions above.
      // TODO: find out why this is happening. Maybe create a middleware to listen to all actions dispatched?
      await sleep(100);
      setLoader({ active: false, message: "" });
      navigate("/1");
      navigate(HomeRelativeLink());
    } catch (error) {
      console.log(error);
    }
  };

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    if (clickedOutside) {
      setRenderList(false);
    }
  }, [clickedOutside]);

  return (
    <div id="address-list" ref={wrapperRef}>
      <Button className={classes.expandButton} onClick={() => setRenderList(!renderList)} disableRipple>
        <Grid container>
          <Grid item className={classes.addressList} xs={12}>
            <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
              <img src={truckIcon} alt="truck" style={{ width: "100", height: "auto" }} />
              <Typography className={classes.address} variant="body2">
                Enviar a: {selectedAddress}
              </Typography>
            </Typography>
            <Typography variant="caption">
              {renderList ? (
                <img src={chevronUpIcon} alt="chevronUp" />
              ) : (
                <img src={chevronDownIcon} alt="chevronDown" />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Button>
      {renderList && (
        <Grid container justifyContent="center">
          {addressesDisplayed?.length !== 0 && (
            <Grid item xs={12} lg={4} className={classes.geofencingRouteHeader}>
              <List disablePadding>
                {addressesDisplayed?.map((customerAddress: Address, index: number) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      key={index}
                      dense
                      button
                      disableRipple
                      onClick={() => onAddressChoiceSelected(index)}
                    >
                      <ListItemIcon className={classes.checkBox}>
                        <Checkbox
                          className={classes.checkBox}
                          tabIndex={-1}
                          checked={selectedAddressIndex === index}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: "body2" }}
                        primary={customerAddressToString(customerAddress)}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <Button
                className={classes.validateButton + " " + classes.mobileStylePrimary}
                color="primary"
                onClick={() => {
                  setAddNewAddress(true);
                  onAddressChoiceSelected(-1);
                }}
              >
                Agregar dirección
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {loader.active ? <FullPageLoader type={LoaderType.Authentication} text={loader.message} /> : <></>}
      <Dialog open={showDialog}>
        <LogoMain className={classes.logoAddress} />
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" className={classes.changeAddressHeader}>
              Si cambia de dirección pueden cambiar los productos del carrito. ¿Desea continuar?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.addressButtonsDialog}>
          <Button
            className={classes.mobileStylePrimary}
            color="primary"
            onClick={() => {
              setShowDialog(false);
              changeSelectedAddress();
            }}
          >
            Sí
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setAddNewAddress(false);
              setShowDialog(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
