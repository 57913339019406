import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { funcPaymentPostVerify, funcShoppingCartPutDelivery, funcShoppingCartPutPayment } from "features/common/API";
import {
  CssBaseline,
} from "@material-ui/core";
import { FullPageLoader, LoaderType } from "features/main/FullPageLoader";
import { BotWhatsAppLink, ProductsRelativeLink } from "features/common/urlBuilder";
import { MessageDialog } from "features/main/MessageDialog";
import { clearCart } from "features/common/productSlice";
import { ShoppingCart } from "models/ecommerceCart";
import { ErrorRequestResponse } from "models/errorRequestResponse";

const SHOPPING_CART_NOT_ACTIVE_ERROR_MESSAGE = 'Shopping Cart not found or not active';

export const PaymentPage = () => {
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { billingInformation } = useSelector((state: RootState) => state.deliveryData);
  const { cart, cartId, total, couponCode } = useSelector((state: RootState) => state.products);
  const { deliveryData, selectedPaymentMethod } = useSelector((state: RootState) => state.deliveryData);

  const dispatch: AppDispatch = useDispatch();
  const [isLoaderActive, setIsLoaderActive] = useState(true);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  let searchParams = new URLSearchParams(window.location.search);
  let token = searchParams.get("token") || '';

  useEffect(() => {
    (async () => {
      let putPaymentResult: EcommerceOrder | ErrorRequestResponse | undefined;

      if (token) {
        const paymentResult = await funcPaymentPostVerify(conversationContext?.accountProviderId as number, registerAccount?.account_id as string, token);
        if (paymentResult && paymentResult.transaction_status === 'approved') {
          // Do Checkout
          window.onbeforeunload = () => true;
          const cartProducts = Object.keys(cart).map((id) => cart[id]);
          putPaymentResult = await funcShoppingCartPutPayment(
            conversationContext?.accountProviderId as number,
            registerAccount?.account_id as string,
            cartId,
            selectedPaymentMethod,
            deliveryData,
            billingInformation!,
            paymentResult
          );

          if (putPaymentResult && !ErrorRequestResponse.isErrorRequestResponse(putPaymentResult)) {
            //CheckoutOrder gives OK
            const result = putPaymentResult as EcommerceOrder;

            const CheckoutResult = {
              order: result,
              products: cartProducts,
              countryId: accountProvider?.country_id,
              currency: accountProvider?.currency,
            };
            dispatch(clearCart(CheckoutResult));

            window.onbeforeunload = () => undefined;
            window.location.replace(BotWhatsAppLink(conversationContext?.whatsappNumber));
            return;
          } 
        }
      }

      //Error on Payment/Checkout processing
      window.onbeforeunload = () => undefined;

      let putPaymentResultError = putPaymentResult as ErrorRequestResponse;

      if (ErrorRequestResponse.isErrorRequestResponse(putPaymentResult) && putPaymentResultError.data.error === SHOPPING_CART_NOT_ACTIVE_ERROR_MESSAGE) {
        // The order was already created through the Webhook => Give notice to the user and redirect him to Whatsapp
        setIsErrorMessageOpen(true);
        setErrorMessage(
          "Tu pedido ya ha sido confirmado. Muy pronto te llegará un mensaje de Whatsapp con todos los detalles. ¡Gracias por tú compra!"
        );
        setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber));
        setErrorMessageButtonText("Ir a Whatsapp");
        setIsLoaderActive(false);

        // Send artifitially generated transaction information to GTM
        const order = {
          total,
          coupon_code: couponCode,
          supplier_order_id: `[MercadoPagoWrapper]-${Math.floor(Math.random() * 10000000)}`,
          tax: 0,
        } as EcommerceOrder;
        const CheckoutResult = {
          order,
          products: Object.keys(cart).map((sku) => cart[sku]),
          countryId: accountProvider?.country_id,
          currency: accountProvider?.currency,
        };
        dispatch(clearCart(CheckoutResult));
      } else {
        // Error with the order creation => Give notice to the user and redirect him to the ShoppingCart page.
        setIsErrorMessageOpen(true);
        setErrorMessage("El pago no ha sido procesado correctamente. Intentalo nuevamente o selecciona otro método de compra");
        setErrorMessageButtonLink(ProductsRelativeLink() + "?contextId=" + conversationContext?.contextId + "#cart-container");
        setErrorMessageButtonText("Regresar al carrito");
        setIsLoaderActive(false);
      }
    })();
  }, []);
  return (
    <>
      <CssBaseline/>
      {isLoaderActive ?<FullPageLoader type={LoaderType.Authentication} text={"¡Ya casi terminamos! Estamos procesando tu orden. Por favor, continua en esta página hasta recibir la confirmación."} /> : <></>}
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  )
};
