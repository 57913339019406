import { LoaderContext } from 'features/contexts/loaderContext';
import { useContext, useEffect, useState } from 'react';
import { STATUS, status, valueof } from 'react-joyride';

export const useTour = (tourName: string) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const { showLoader } = useContext(LoaderContext);
  const [status, setStatus] = useState<valueof<status> | undefined>(undefined);
  const [stepIndex, setStepIndex] = useState(0);
  const root = document.querySelector('#root')!;

  useEffect(() => {
    const showTour = (localStorage.getItem(`show${tourName}`) ?? 'true') === 'true';
    if (!showLoader && showTour) {
      document.body.classList.add('overflow-hidden');
      setIsTourOpen(showTour);
    } else {
      document.body.classList.remove('overflow-hidden');
      setIsTourOpen(false);
    }
  }, [showLoader]);

  useEffect(() => {
    if (status === STATUS.FINISHED) {
      document.body.classList.remove('overflow-hidden');
      localStorage.setItem(`show${tourName}`, 'false');
      setIsTourOpen(false);
    }
  }, [status]);

  return { isTourOpen, setIsTourOpen, setStatus, stepIndex, setStepIndex };
};
