import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { initB2Ccds } from "cds/cdsB2Csdk/b2cInit";
import { userStatus } from "features/common/accountSlice";
import {
  funcGetDeliveries,
  funcPaymentPostTokenize,
  funcShoppingCartPutDelivery,
  funcShoppingCartPutPayment,
} from "features/common/API";
import { setDeliveryDate } from "features/common/deliverySlice";
import { formatNumber, INTERNAL_CHATBOT_DATE_FORMAT } from "features/common/formatHelpers";
import { GTM_LIST_NAME_SHOPPING_CART } from "features/common/gtmEventHandler";
import { clearCart } from "features/common/productSlice";
import {
  BotWhatsAppLink,
  CDSLoginRelativeLink,
  PaymentWrapperPageLink,
  AddressFormRelativeLink,
} from "features/common/urlBuilder";
import { ModalContext } from "features/contexts/modalContext";
import { useMaintenanceMessage } from "features/hooks/useMaintenanceMessage";
import { useScroll } from "features/hooks/useScroll";
import { EcommerceProduct } from "models/ecommerceProduct";
import { ErrorRequestResponse } from "models/errorRequestResponse";
import { ReturnableTypes } from "models/returnableType";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartIllustration from "../../assets/cart_empty.svg";
import CloseIcon from '@material-ui/icons/Close';
import Coupon from "../../assets/icons/ui/coupon.svg";
import mercagoPagoLogoProtectedBuyIcon from "../../assets/icons/ui/mercagoPagoLogoProtectedBuyIcon.svg";
import ProtectedBuyIcon from "../../assets/icons/ui/protectedBuyIcon.svg";
import currency from "../../assets/pesos_svg.svg";
import facebookIconLogo from "../../assets/facebookIconLogo.svg";
import googleIconLogo from "../../assets/googleIconLogo.svg";
import { Banner } from "./Banner";
import { CartCoupon } from "./CartCoupon";
import { CartProduct } from "./CartProduct";
import { CheckoutProgress } from "./CheckoutProgress";
import { Delivery } from "./Delivery";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import { MessageDialog } from "./MessageDialog";
import { MERCADO_PAGO_WRAPPER_PAYMENT_CODE, Payment, CASH_ON_DELIVERY_PAYMENT_CODE } from "./Payment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    maxWidth: "500px",
    textAlign: "center",
    backgroundColor: "#F5F5F5",
    margin: "auto",
  },
  confirmOrderCard: {
    padding: theme.spacing(2),
    maxWidth: "500px",
    textAlign: "center",
    backgroundColor: "#F5F5F5",
    marginBottom: theme.spacing(7),
    margin: "auto",
  },
  orderActions: {
    justifyContent: "center",
  },
  priceDetails: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontWeight: 400,
  },
  priceTotal: {
    marginTop: theme.spacing(1),
    fontWeight: 400,
    textAlign: "left",
  },
  invoiceFormTextField: {
    marginBottom: theme.spacing(2),
  },
  invoiceFormRadioField: {
    marginTop: theme.spacing(1),
  },
  invoiceRadioGroup: {
    flexDirection: "row",
  },
  invoiceRadio: {
    ...inputClasses.radioButton,
  },
  invoiceCheckbox: {
    ...inputClasses.checkBox,
  },
  dialogActions: {
    justifyContent: "center",
  },
  billingAddress: {
    textAlign: "justify",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  billingAddressField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  grid: {
    justifyContent: "center",
  },
  gridImage: {
    textAlign: "right",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "6px",
    paddingRight: "5px",
  },
  gridText: {
    textAlign: "left",
    justifyContent: "center",
    alignItems: "center",
  },
  modalSocialLogin: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    minHeight: "50vh",
  },
  modalSocialLoginPaper: {
    width: "100%",
    maxWidth: "unset",
    borderRadius: "16px 16px 0 0",
    margin: 0,
  },
  modalSocialLoginCloseButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: "pointer",
    color: "#000",
  },
  modalSocialLoginHeader: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: theme.spacing(1),
  },
  socialLoginSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  loginSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  socialLoginButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    borderRadius: "16px",
    cursor: "pointer",
  },
  socialLoginFacebookButton: {
    backgroundColor: "#1877F2",
    border: "1px solid #1877F2",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#135688",
    },
  },
  socialLoginGoogleButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #000",
    color: "#000",
    "&:hover": {
      backgroundColor: "#E6E6E6",
    },
  },
  socialLoginCokeButton: {
    backgroundColor: "#000000",
    border: "1px solid #000",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  socialRegistrationCokeText: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
  socialLoginIcon: {
    width: "20px",
  },
  socialLoginText: {
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
  },
  customDivider: {
    display: "flex",
    alignItems: "center",
  },
  customDividerhr: {
    flex: 1,
    border: 0,
    borderTop: "1px solid #afafaf",
    margin: "0 10px",
  },
  customDividerText: {
    color: "#afafaf",
  },
  textFieldLabelRoot: {
    ...inputClasses.textFieldLabel.root,
  },
  textFieldLabelFocus: {
    ...inputClasses.textFieldLabel.focus,
  },
  textFieldLabelError: {
    ...inputClasses.textFieldLabel.error,
  },
  textFieldInputRoot: {
    ...inputClasses.textFieldInput.root,
  },
  textFieldInputOutlined: {
    ...inputClasses.textFieldInput.notchedOutline,
  },
  textFieldInputFocus: {
    ...inputClasses.textFieldInput.focus,
  },
  textFieldInputError: {
    ...inputClasses.textFieldInput.error,
  },
  cartImage: {
    height: "50%",
    width: "25%",
    paddingTop: "16px",
    paddingBottom: "8px",
  },
  confirmOrderButtonContent: {
    width: "75%",
    height: 44,
  },
  mobileStylePrimary: {
    ...inputClasses.mobileStylePrimary,
    whiteSpace: "nowrap",
  },
  infoMessage: {
    ...inputClasses.infoMessage,
    display: "flex",
    flexWrap: "initial",
  },
  infoIconGrid: {
    alignItems: "center",
    display: "flex",
  },
  protectedBuyMessage: {
    fontFamily: "TCCC-UnityHeadline",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#428059",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  protectedBuyCard: {
    borderRadius: "16px",
  },
  protectedBuyIconGrid: {
    borderRadius: "16px",
    backgroundColor: "#E9F3DC",
    margin: "auto",
    marginBottom: theme.spacing(4),
    width: "fit-content",
    padding: "4px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  iconImage: {
    height: "21px",
    width: "21px",
    marginRight: "4px",
    marginLeft: "4px",
    alignItems: "center",
  },
  couponIconImage: {
    height: "21px",
    width: "21px",
    alignItems: "center",
  },
  paymentMethodTypography: {
    fontSize: "14px",
    textAlign: "left",
  },
  paymentIconGrid: {
    alignItems: "center",
    display: "flex",
  },
  logoMercadoPagoImage: {
    height: "50px",
    width: "150px",
    marginRight: "10px",
    marginLeft: "10px",
    alignItems: "right",
  },
  textData: {
    textAlign: "left",
    fontWeight: 500,
  },
  iconPaymentImage: {
    height: "21px",
    width: "21px",
    marginRight: "4px",
    marginLeft: "4px",
    alignItems: "center",
  },
  cartTotalLeft: {
    fontWeight: 700,
    textAlign: "left",
  },
  cartTotalRight: {
    textAlign: "left",
  },
  cartTotalInfo: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  couponCartTotal: {
    paddingTop: "4px",
  },
  cartCouponLeft: {
    fontWeight: "normal",
    textAlign: "left",
    color: "#E02020",
    overflowWrap: "break-word",
  },
  cartCouponRight: {
    textAlign: "left",
    color: "#E02020",
  },
}));

export const Checkout = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getMaintenanceMessage } = useMaintenanceMessage();
  const { handleModal } = useContext(ModalContext);

  // Loader State
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  // Modal Controls
  const [isPopUpWrapperOrderConfirmationOpen, setIsPopUpWrapperOrderConfirmationOpen] = useState(false);
  const [isPopUpB2CConfirmationOpen, setIsPopUpB2CConfirmationOpen] = useState(false);

  const { cart, cartId, subtotal, total, couponCode, discount, shipping, products } = useSelector(
    (state: RootState) => state.products
  );
  const { deliveryData, selectedPaymentMethod } = useSelector((state: RootState) => state.deliveryData);
  const dispatch: AppDispatch = useDispatch();

  const { accountProvider, conversationContext, registerAccount, isUserLoggedIn } = useSelector(
    (state: RootState) => state.account
  );
  const { selectedDeliveryDate, billingInformation } = useSelector((state: RootState) => state.deliveryData);
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(accountProvider?.minimum_order_amount);
  const [isCartEmpty, setIsCartEmpty] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const { isBottomScroll } = useScroll();
  const renderCart = () => {
    const cartItemsId = Object.keys(cart);
    const cartProducts = cartItemsId
      .filter((itemId) => cart[itemId].quantity !== 0)
      .map((itemId) => products.find((product) => product.sku === cart[itemId].sku) as EcommerceProduct);
    return cartProducts
      .filter((product) => product?.returnable_type !== ReturnableTypes.Container)
      .map((product, index) => {
        return product 
          ? (
            <CartProduct
              key={product.sku}
              product={product}
              position={GTM_LIST_NAME_SHOPPING_CART}
              viewPosition={index + 1}
            />)
          : null;
      });
  };

  useEffect(() => {
    const cartIds = Object.keys(cart);
    cartIds.length > 0 ? setIsCartEmpty(false) : setIsCartEmpty(true);
  }, [cart]);

  const handleCDSAction = async (actionType: "facebook" | "google" | "registration" | "login") => {
    const maintenanceMessage = getMaintenanceMessage();
    if (maintenanceMessage) {
      handleModal(maintenanceMessage);
      return;
    }
  
    if (accountProvider?.registrationFlow === "b2c") {
      const queryParamsMap: Record<string, { [key: string]: string }> = {
        facebook: { goToSignUp: "true" },
        google: { goToSignUp: "true" },
        registration: { goToSignUp: "true" },
        login: { goToSignIn: "true" },
      };
  
      const params = {
        extraQueryParameters: queryParamsMap[actionType] || {},
      };
  
      const sdk = await initB2Ccds(accountProvider?.key);
      sdk.doRedirect(params);
    } else {
      navigate(CDSLoginRelativeLink());
    }
  };

  const validateCart = async () => {
    const cartProducts = Object.keys(cart).map((id) => cart[id]);
    if (cartProducts.length === 0) {
      setIsErrorMessageOpen(true);
      setErrorMessage("No hay productos en tu carrito");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Ok");
    } else {
      // Unregistered user => Go to CDS Login/Register or B2C Login/Register
      if (!registerAccount || !registerAccount?.account_id) {
        openModal();
        return;
      }

      // Registered user that wants to create a new address => Go to AddressFormPage
      if (deliveryData.is_new) {
        navigate(AddressFormRelativeLink());
        return;
      }

      // Validate that the selected delivery_date is in the range of the allowed dates
      const deliveryDates: EcommereceDeliveryDate[] | undefined = await funcGetDeliveries(
        accountProvider?.key as number,
        registerAccount?.account_id as string,
        deliveryData.warehouse_code,
        deliveryData.route_id
      );

      if (deliveryDates && deliveryDates.length > 0) {
        dispatch(setDeliveryDate(deliveryDates[0]));

        const min_date = moment(deliveryDates[0].min_day, INTERNAL_CHATBOT_DATE_FORMAT).toDate();
        const max_date = moment(deliveryDates[0].max_day, INTERNAL_CHATBOT_DATE_FORMAT).toDate();

        const selected_date = moment(selectedDeliveryDate, INTERNAL_CHATBOT_DATE_FORMAT).toDate();

        if (!(selected_date >= min_date && selected_date <= max_date)) {
          setIsErrorMessageOpen(true);
          setErrorMessage(
            "El día de entrega seleccionado no está en el rango permitido, por favor selecciona nuevamente."
          );
          setErrorMessageButtonLink("");
          setErrorMessageButtonText("Ok");
          return;
        }
      }

      var putDeliveryPayload = {
        cart_id: cartId,
        delivery_date: selectedDeliveryDate,
        encoded_route_info: deliveryData?.encoded_route_info,
      };
      const putDeliveryResult = await funcShoppingCartPutDelivery(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        putDeliveryPayload
      );

      if (!putDeliveryResult || ErrorRequestResponse.isErrorRequestResponse(putDeliveryResult)) {
        setIsErrorMessageOpen(true);
        setErrorMessage(`Lo sentimos 😔, no he podido crear tu pedido. Puedes intentarlo nuevamente más tarde.`);
        setErrorMessageButtonLink("");
        setErrorMessageButtonText("Ok");
        return;
      }

      if (selectedPaymentMethod === MERCADO_PAGO_WRAPPER_PAYMENT_CODE) {
        // TODO: Delete this Hot Fix for a dynamic solution that changes FEMSA bottler name to FEMSA_MX on our complete structure.
        let bottler_name = deliveryData.bottler as string;
        if (bottler_name === "FEMSA") bottler_name = "FEMSA_MX";

        const paymentTokenize: PostPaymentTokenize = {
          bottler_name: bottler_name,
          cart_id: cartId,
          amount: total as number,
          channel_id: 'Bot',
        };

        var paymentTokenResponse = await funcPaymentPostTokenize(
          conversationContext?.accountProviderId as number,
          registerAccount?.account_id as string,
          paymentTokenize as PostPaymentTokenize
        );

        if (!paymentTokenResponse) {
          // Error generating token -> Redirect to login (Authentication expired or missing)
          setIsPopUpB2CConfirmationOpen(true);
          return;
        }
        switch(paymentTokenResponse?.transaction_status) {
          case 'approved':
            // The payment of that cart was already done -> Give notice to user.
            setIsErrorMessageOpen(true);
            setErrorMessage(
              "Tu pedido ya ha sido confirmado. Muy pronto te llegará un mensaje de Whatsapp con todos los detalles. ¡Gracias por tú compra!"
            );
            setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber));
            setErrorMessageButtonText("Ir a Whatsapp");
            break;
          case 'pending':
          default:
            // Token generated correctly -> Redirect user to the Payment Wrapper Page.
            setIsPopUpWrapperOrderConfirmationOpen(true);
            setTimeout(() => {
              if (paymentTokenResponse?.token) {
                setIsPopUpWrapperOrderConfirmationOpen(false);
                window.location.replace(PaymentWrapperPageLink(accountProvider?.country_id, paymentTokenResponse.token));
              }
            }, 5000);
            break;
        }

        return;
      }
      checkout();
    }
  };

  const checkout = async () => {
    const cartProducts = Object.keys(cart).map((id) => cart[id]);
    if (cartProducts.length === 0) {
      setIsErrorMessageOpen(true);
      setErrorMessage("No hay productos en tu carrito");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Ok");
    } else {
      // Unregistered user => Go to CDS Login/Register or B2C Login/Register
      if (!registerAccount || !registerAccount?.account_id) {
        if (accountProvider?.registrationFlow === "b2c") {
          const sdk = await initB2Ccds(accountProvider?.key);
          sdk.getAuthenticationUrl(true, false);
        } else {
          navigate(CDSLoginRelativeLink());
        }
        return;
      }

      // Registered user that wants to create a new address => Go to AddressFormPage
      if (deliveryData.is_new) {
        navigate(AddressFormRelativeLink());
        return;
      }

      const billingAddressData: BillingAddress = {
        street: [],
        number: "",
        number_int: "",
        postcode: "",
        neighborhood: "",
        municipality: "",
        region: "",
        region_id: "",
        region_code: "",
        city: "",
      };
      const mockInvoiceData: BillingInformation = {
        rfc: "",
        rfc_type: "",
        billing_name: "",
        tax_regime: "",
        tax_cfdi_use: "",
        tax_capital_regime: "",
        want_bill: false,
        billingAddress: billingAddressData,
      };

      // Registered user with a existing address => Checkout + Send to Whatsapp
      setIsLoaderActive(true);
      window.onbeforeunload = () => true;
      var putPayment = await funcShoppingCartPutPayment(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        cartId,
        selectedPaymentMethod,
        deliveryData,
        billingInformation ? billingInformation : mockInvoiceData
      );

      if (putPayment && !ErrorRequestResponse.isErrorRequestResponse(putPayment)) {
        //CheckoutOrder gives OK
        const result = putPayment as EcommerceOrder;

        const CheckoutResult = {
          order: result,
          products: cartProducts,
          countryId: accountProvider?.country_id,
          currency: accountProvider?.currency,
        };
        dispatch(clearCart(CheckoutResult));

        window.onbeforeunload = () => undefined;
        window.location.replace(BotWhatsAppLink(conversationContext?.whatsappNumber));
        return;
      }

      //CheckoutOrder gives not OK
      window.onbeforeunload = () => undefined;

      // Unexpected error on Checkout call
      setIsErrorMessageOpen(true);
      setErrorMessage(`Lo sentimos 😔, no he podido crear tu pedido. Puedes intentarlo nuevamente más tarde.`);
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Ok");

      setIsLoaderActive(false);
    }
  };

  const loginB2C = async () => {
    if (accountProvider?.registrationFlow === "b2c") {
      const sdk = await initB2Ccds(accountProvider?.key);
      sdk.getAuthenticationUrl(true, false);
    } else {
      navigate(CDSLoginRelativeLink());
    }
  };

  return (
    <>
      <Card id="cart-container" className={classes.root}>
        <Typography variant="h4">
          <b>Mi carrito</b>
        </Typography>
        <br />
        {renderCart()}
        {isCartEmpty ? (
          <Grid>
            <Typography>Tu carrito está vacío,</Typography>
            <Typography>¡Agrega productos!</Typography>
            <img className={classes.cartImage} alt="cart" src={CartIllustration} />
          </Grid>
        ) : null}

        {accountProvider?.is_using_coupons === true && cartId && <CartCoupon />}
        <div className={classes.priceDetails}>
          <Grid container wrap="nowrap" direction="row" className={classes.cartTotalInfo}>
            <Grid item xs={9}>
              <Typography className={classes.cartTotalLeft}>Subtotal:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.cartTotalRight}>
                {accountProvider?.currency_symbol}
                {formatNumber(subtotal, accountProvider?.amount_format, accountProvider?.amount_separator)}
              </Typography>
            </Grid>
          </Grid>
          {accountProvider?.is_using_coupons === true && couponCode !== undefined && (
            <Grid container wrap="nowrap" direction="row" className={classes.couponCartTotal}>
              <Grid item xs={1}>
                <img className={classes.couponIconImage} src={Coupon} />
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.cartCouponLeft}>{couponCode}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.cartCouponRight}>
                  {accountProvider?.currency_symbol}
                  {formatNumber(discount ?? 0, accountProvider?.amount_format, accountProvider?.amount_separator)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container wrap="nowrap" direction="row" className={classes.cartTotalInfo}>
            <Grid item xs={9}>
              <Typography className={classes.cartTotalLeft}>Envio Gratis:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.cartTotalRight}>
                {accountProvider?.currency_symbol}
                {formatNumber(shipping ?? 0, accountProvider?.amount_format, accountProvider?.amount_separator)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Grid container wrap="nowrap" direction="row" className={classes.cartTotalInfo}>
          <Grid item xs={9}>
            <Typography className={classes.cartTotalLeft}>Total:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.cartTotalRight}>
              {accountProvider?.currency_symbol}
              {formatNumber(total ?? 0, accountProvider?.amount_format, accountProvider?.amount_separator)}
            </Typography>
          </Grid>
        </Grid>

        {minimumOrderAmount !== 0 && (
          <Grid>
            <br />
            <CheckoutProgress
              subtotal={subtotal}
              subtotalFormatted={`${accountProvider?.currency_symbol}${formatNumber(
                subtotal,
                accountProvider?.amount_format,
                accountProvider?.amount_separator
              )}`}
              minimumOrderAmount={minimumOrderAmount}
              minimumOrderAmountFormatted={`${accountProvider?.currency_symbol}${formatNumber(
                minimumOrderAmount as number,
                accountProvider?.amount_format,
                accountProvider?.amount_separator
              )}`}
            />
            <br />
            <Typography variant="body1">
              <img style={{ verticalAlign: "middle" }} src={currency} alt="" />{" "}
              <strong>
                El monto mínimo de compra es de {accountProvider?.currency_symbol}
                {formatNumber(
                  minimumOrderAmount as number,
                  accountProvider?.amount_format,
                  accountProvider?.amount_separator
                )}
              </strong>
            </Typography>
          </Grid>
        )}
      </Card>

      <Delivery />

      {registerAccount?.account_id !== undefined && !deliveryData.is_new && <Payment />}

      {cart && Object.keys(cart).length !== 0 && (
        <Card className={classes.confirmOrderCard}>
          {isUserLoggedIn === userStatus.LoggedOut && (
            <Banner type="info" message="Para confirmar tu orden es necesario iniciar sesión:" showIcon={true} />
          )}
          <Button
            className={classes.confirmOrderButtonContent + " " + classes.mobileStylePrimary}
            color="primary"
            disabled={
              subtotal + shipping < (minimumOrderAmount || 0) ||
              Object.keys(cart).some((productId) => cart[productId].has_changed) ||
              isLoaderActive ||
              (selectedPaymentMethod === "" && registerAccount?.account_id !== undefined && !deliveryData.is_new)
            }
            variant="contained"
            onClick={() => validateCart()}
            style={{ width: isBottomScroll && isMobile ? "100%" : "75%" }}
          >  
          {selectedPaymentMethod === CASH_ON_DELIVERY_PAYMENT_CODE
            ? isUserLoggedIn === userStatus.LoggedIn
              ? "Confirmar Orden"
              : "Iniciar sesión y continuar"
            : selectedPaymentMethod === MERCADO_PAGO_WRAPPER_PAYMENT_CODE
            ? isUserLoggedIn === userStatus.LoggedIn
              ? "Proceder al Pago"
              : "Iniciar sesión y continuar"
            : "Proceder al Pago"}
          </Button>
          {isLoaderActive ? (
            <FullPageLoader
              type={LoaderType.Authentication}
              text="Creando pedido... Por favor permanece en esta página hasta que finalice la operación."
            />
          ) : (
            <></>
          )}
        </Card>
      )}

      <Dialog open={isPopUpWrapperOrderConfirmationOpen} classes={{ paper: classes.protectedBuyCard }}>
        <DialogContent className={classes.protectedBuyCard}>
          <DialogContentText color={"textPrimary"}>
            <Grid container justify="center" className={classes.protectedBuyIconGrid}>
              <Grid item>
                <Typography className={classes.protectedBuyMessage}>Compra Protegida</Typography>
              </Grid>
              <Grid item>
                <img className={classes.iconImage} alt="logo" src={ProtectedBuyIcon} />
              </Grid>
            </Grid>
            <Grid justify="center" className={classes.paymentIconGrid}>
              <img
                className={classes.logoMercadoPagoImage}
                alt="logoMercadoPago"
                src={mercagoPagoLogoProtectedBuyIcon}
              />
            </Grid>
            <p style={{ textAlign: "center" }}>
              Estás siendo redirigido a Mercado Pago para completar tu compra de forma segura.
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={isPopUpB2CConfirmationOpen}>
        <DialogContent>
          <DialogContentText color={"textPrimary"}>
            Por seguridad debes iniciar sesión para mostrar los datos de pago con tarjeta
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="secondary"
            onClick={() => {
              setIsPopUpB2CConfirmationOpen(false);
            }}
          >
            Volver
          </Button>
          <Button
            className={classes.mobileStylePrimary}
            color="primary"
            onClick={() => {
              setIsPopUpB2CConfirmationOpen(false);
              loginB2C();
            }}
          >
            Ir a iniciar sesión
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isModalOpen} classes={{ container: classes.modalSocialLogin, paper: classes.modalSocialLoginPaper }}>
        <IconButton
          onClick={() => {
            setIsModalOpen(false);
          }}
          className={classes.modalSocialLoginCloseButton}
          data-tracking="checkoutLoginModuleCloseButton"
        >
          <CloseIcon data-tracking="checkoutLoginModuleCloseButton"><CloseIcon /></CloseIcon>
        </IconButton>
        <DialogTitle></DialogTitle>
        <DialogContent>
        <Typography variant="h5" className={classes.modalSocialLoginHeader}>
          Para continuar
        </Typography>
        <Typography variant="h5" className={classes.modalSocialLoginHeader}>
          selecciona una opción
        </Typography>
        <Container className={classes.socialLoginSection}>
          <Button className={`${classes.socialLoginFacebookButton} ${classes.socialLoginButton}`} data-tracking="checkoutFacebookLoginButton" onClick={() => handleCDSAction("facebook")}>
            <img src={facebookIconLogo} alt="Facebook Logo" className={classes.socialLoginIcon} data-tracking="checkoutFacebookLoginButton"/>
            <span className={classes.socialLoginText} data-tracking="checkoutFacebookLoginButton">Continúa con Facebook</span>
          </Button>
          <Button className={`${classes.socialLoginGoogleButton} ${classes.socialLoginButton}`} data-tracking="checkoutGoogleLoginButton" onClick={() => handleCDSAction("google")}>
            <img src={googleIconLogo} alt="Google Logo" className={classes.socialLoginIcon} data-tracking="checkoutGoogleLoginButton"/>
            <span className={classes.socialLoginText} data-tracking="checkoutGoogleLoginButton">Continúa con Google</span>
          </Button>
        </Container>
        <div className={classes.customDivider}>
          <hr className={classes.customDividerhr} />
          <span className={classes.customDividerText}>o</span>
          <hr className={classes.customDividerhr} />
        </div>
        <Container className={classes.loginSection}>
          <Typography className={classes.socialRegistrationCokeText}>
            <a id="checkoutStandardRegistrationButton" style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleCDSAction("registration")}>
              ¿No tienes una cuenta? Regístrate
            </a>{" "}
          </Typography>
          <Button id="checkoutStandardEmailLoginButton" className={`${classes.socialLoginCokeButton} ${classes.socialLoginButton}`} data-tracking="checkoutStandardEmailLoginButton" onClick={() => handleCDSAction("login")}>
            <span className={classes.socialLoginText} data-tracking="checkoutStandardEmailLoginButton">Inicia Sesión con Correo</span>
          </Button>
        </Container>  
        </DialogContent>
      </Dialog>

      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );
};