import { RootState } from 'app/store';
import moment from 'moment';
import { useSelector } from 'react-redux';

export const useMaintenanceMessage = () => {
  const { accountProvider } = useSelector((state: RootState) => state.account);

  const getMaintenanceMessage = () => {
    let maintenanceMessage = accountProvider?.parameters?.find((p) => p.key === 'cds_maintenance_message')?.value;
    const maintenanceStartDate = accountProvider?.parameters?.find(
      (p) => p.key === 'cds_maintenance_start_date'
    )?.value;
    const maintenanceEndDate = accountProvider?.parameters?.find((p) => p.key === 'cds_maintenance_end_date')?.value;
    if (!maintenanceStartDate || !maintenanceEndDate) {
      return '';
    }

    let startDate = new Date();
    if (moment(maintenanceStartDate, 'YYYY-MM-DD HH:mm:ss.SSS').isValid()) {
      startDate = moment(maintenanceStartDate, 'YYYY-MM-DD HH:mm:ss.SSS').toDate();
    }

    let endDate = new Date();
    if (moment(maintenanceEndDate, 'YYYY-MM-DD HH:mm:ss.SSS').isValid()) {
      endDate = moment(maintenanceEndDate, 'YYYY-MM-DD HH:mm:ss.SSS').toDate();
    }

    const curdate = new Date();
    if (curdate >= startDate && curdate <= endDate) {
      return maintenanceMessage;
    }

    return '';
  };
  return { getMaintenanceMessage };
};
