import { RootState } from 'app/store';
import { setSearchFilter } from 'features/common/accountSlice';
import { GTM_LIST_NAME_BANNER_CATEGORY_REDIRECT, GTM_LIST_NAME_BANNER_PRODUCT_REDIRECT, GTM_LIST_NAME_BANNER_SEARCH_REDIRECT } from 'features/common/gtmEventHandler';
import { setListName } from 'features/common/productSlice';
import { BotWhatsAppLink, ProductsRelativeLink } from 'features/common/urlBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCategoryDispatcher } from './useCategoryDispatcher';

export const useBannerActions = () => {
  const { categories } = useSelector((state: RootState) => state.categories);
  const navigate = useNavigate();
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const { products } = useSelector((state: RootState) => state.products);
  const { dispatchCategory } = useCategoryDispatcher();
  const dispatch = useDispatch();
  const [search] = useSearchParams();

  const categoryRedirect = (category_id: number) => {
    const category = categories.find((category) => category.id === category_id);
    if (category) {
      dispatchCategory(category, GTM_LIST_NAME_BANNER_CATEGORY_REDIRECT);
      navigate(ProductsRelativeLink());
    }
  };

  const whatsappRedirect = (phone?: string, message?: string) => {
    window.location.assign(BotWhatsAppLink(phone ?? accountProvider?.whatsapp_number, message));
  };

  const linkRedirect = (url: string, internal?: boolean) => {
    if (internal) {
      navigate({ pathname: url, search: search.toString() });
    } else {
      window.location.assign(url);
    }
  };

  const productRedirect = (product_name: string) => {
    const product = products.find((product) => product.shortname.trim() === product_name.trim());
    if (product) {
      navigate('/product', { state: { product, viewPosition: 1, position: GTM_LIST_NAME_BANNER_PRODUCT_REDIRECT } });
    }
  };

  const searchRedirect = (search: string) => {
    dispatch(setSearchFilter(search));
    dispatch(setListName({listName: GTM_LIST_NAME_BANNER_SEARCH_REDIRECT}));
    navigate(ProductsRelativeLink());
  };

  return { categoryRedirect, whatsappRedirect, linkRedirect, productRedirect, searchRedirect };
};

type BannerActionDefault = {
  action: 'default';
};
type BannerActionCategory = {
  action: 'category';
  category_id: number;
};
type BannerActionWhatsapp = {
  action: 'whatsapp';
  phone?: string;
  message?: string;
};
type BannerActionLink = {
  action: 'link';
  internal: boolean;
  url: string;
};
type BannerActionProduct = {
  action: 'product';
  product_name: string;
};
type BannerActionSearch = {
  action: 'search';
  search: string;
};
export type BannerAction =
  | BannerActionDefault
  | BannerActionCategory
  | BannerActionWhatsapp
  | BannerActionLink
  | BannerActionProduct
  | BannerActionSearch;
