import { RootState } from "app/store";
import defaultLogo from "assets/cceth_v1.svg";
import { useBannerImage } from "features/hooks/useBannerImage";
import React from "react";
import { useSelector } from "react-redux";

export const LogoMain = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const bannerLogo = useBannerImage("header")[0] ?? undefined;
  return (
    <>
      {accountProvider && (
        <img
          className={className}
          alt="Coca Cola logo"
          src={bannerLogo?.src ?? defaultLogo}
          onClick={onClick}
        />
      )}
    </>
  );
};
