import { EcommerceProduct } from "./ecommerceProduct";

export class ShoppingCartProduct {
  public constructor(
    public quantity: number,
    public sku: string,
    public name: string,
    public linked_product?: ShoppingCartProduct,
    public product_id?: string,
    public image_url?: string,
    public tax?: number,
    public discount?: number,
    public is_alcoholic?: boolean,
    public non_alcoholic_description?: string,
    public description?: string,
    public price?: number,
    public has_changed: boolean = true,
    public list_name?: string,
    public view_position?: number,
    public category_name?: string,
    public brand?: string,
    public flavor?: string,
  ) {}
  
  static fromEcommerceProduct(product: EcommerceProduct, baseProduct?: EcommerceProduct): ShoppingCartProduct{
    return new ShoppingCartProduct(
      baseProduct?.cartCount ?? product.cartCount,
      product.sku,
      product.name,
      product.linked_product
        ? ShoppingCartProduct.fromEcommerceProduct(product.linked_product, product)
        : undefined,
      undefined,
      product.image_url,
      product.tax,
      product.discount,
      product.is_alcoholic,
      product.non_alcoholic_name,
      product.long_description,
      product.price,
      true,
      product.list_name,
      product.view_position,
      product.category_name,
      product.brand,
      product.flavor,
    );
  }
}

export interface PostShoppingCartCreate {
  outlet: string; //equal to encoded_route_info 
  store: string; //open value, equal to bottler for D2C
  products: ShoppingCartProduct[];
}

export interface PutShoppingCartAddItems {
  products: ShoppingCartProduct[];
  cart_id: string;
  encoded_route_info: string | null;
}

export interface PutShoppingCartUpdateItems {
  products: ShoppingCartProduct[];
  cart_id: string;
  encoded_route_info: string | null;
}

export interface PutShoppingCartDeliveryDate {
  cart_id: string;
  delivery_date: string;
  encoded_route_info: string | null;
}

export interface linked_product {
  sku: string;
  quantity: number;
}

export interface ShoppingCartPaymentMethod {
  code: string;
  title: string;
}

export interface ShoppingCart{
  account_provider_id: number;
  account_id: string;
  internal_cart_id: number;
  supplier_cart_id: string;
  suggested_order_id: string;
  subtotal: number;
  discount: number;
  tax: number;
  total: number;
  creation_date: string;
  update_date: string;
  status: string;
  products: ShoppingCartProduct[];
  want_bill: boolean;
  delivery: object;
  coupon_code: string;
  coupon_result?: ShoppingCartCouponResult;
}

export enum ShoppingCartCouponResult {
  added = 'added',
  removed = 'removed',
  notValid = 'not_valid',
  notFound = 'not_found',
  none = 'none',
  error = 'error',
  lost = 'lost'
}

export const isCouponError = (result: ShoppingCartCouponResult) => {
  return [ShoppingCartCouponResult.notValid, ShoppingCartCouponResult.notFound, ShoppingCartCouponResult.error].includes(result);
}