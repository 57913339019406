import { CssBaseline } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { funcNotificationPost, funcRegisterPostB2C, funcShoppingCartPostDefault } from "features/common/API";
import {
  setIsUserLoggedIn,
  setPostLoginRedirectUrl,
  setRegisterAccount,
  userStatus,
} from "features/common/accountSlice";
import {
  AddressFormPageLink,
  AuthenticationPageLink,
  BotWhatsAppLink,
  ProductsRelativeLink,
} from "features/common/urlBuilder";
import { FullPageLoader, LoaderType } from "features/main/FullPageLoader";
import { MessageDialog } from "features/main/MessageDialog";
import { MERCADO_PAGO_WRAPPER_PAYMENT_CODE } from "features/main/Payment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const B2CCDSPage = () => {
  const {
    conversationContext,
    registerAccount,
    accountProvider,
    registrationFlow,
    registrationReturnTemplate,
    postLoginRedirectUrl,
  } = useSelector((state: RootState) => state.account);
  const { deliveryData, selectedPaymentMethod } = useSelector((state: RootState) => state.deliveryData);
  const { cartId } = useSelector((state: RootState) => state.products);
  const dispatch: AppDispatch = useDispatch();
  const [isLoaderActive, setIsLoaderActive] = useState(true);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  let searchParams = new URLSearchParams(window.location.search);
  let code = searchParams.get("code") || "";
  let state: string = searchParams.get("state") || "";

  useEffect(() => {
    (async () => {
      const flowId =
        registrationFlow ||
        accountProvider?.parameters?.find((parameter) => parameter.key === "registration_finished_action")?.value ||
        "";

      if (
        code &&
        state &&
        (selectedPaymentMethod === MERCADO_PAGO_WRAPPER_PAYMENT_CODE || registerAccount?.account_id === undefined)
      ) {
        var payload = {
          code,
          phone: conversationContext?.cellPhoneNumber,
        } as PostB2CCustomer;

        const register = await funcRegisterPostB2C(
          conversationContext?.accountProviderId as number,
          "undefined",
          payload
        );
        if (register) {
          dispatch(setRegisterAccount(register as RegisterAccount));
          dispatch(setIsUserLoggedIn(userStatus.LoggedIn));

          if (flowId !== "whatsapp") {
            // Link shoppingCart-to-email for unregister users
            if (cartId) {
              await funcShoppingCartPostDefault(
                conversationContext?.accountProviderId as number,
                register?.account_id as string,
                cartId,
                deliveryData.encoded_route_info as string,
                false,
                true,
              );
            }

            if (postLoginRedirectUrl) {
              dispatch(setPostLoginRedirectUrl(""));
              window.location.replace(postLoginRedirectUrl);
            } else if (deliveryData.is_new) window.location.replace(AddressFormPageLink());
            else window.location.replace(ProductsRelativeLink() + "?contextId=" + conversationContext?.contextId);
          } else {
            var SIGNUP_TEMPLATE =
              accountProvider?.parameters?.find((parameter) => parameter.key === "signup_template")?.value ?? "";

            var returnTemplate = registrationReturnTemplate || SIGNUP_TEMPLATE;

            await funcNotificationPost(
              accountProvider?.key as number,
              returnTemplate,
              conversationContext?.cellPhoneNumber as string
            );
            window.location.replace(BotWhatsAppLink(conversationContext?.whatsappNumber));
          }
          return;
        }

        if (flowId !== "whatsapp") {
          setIsLoaderActive(false);
          setIsErrorMessageOpen(true);
          setErrorMessage(
            "Ha ocurrido un problema al momento de ingresar a tu cuenta. Por favor intenta de nuevo o contacta al equipo de soporte."
          );
          setErrorMessageButtonLink(ProductsRelativeLink() + "?contextId=" + conversationContext?.contextId);
          setErrorMessageButtonText("Ok");
        } else {
          window.location.replace(AuthenticationPageLink(conversationContext?.contextId));
        }
      }
      if (flowId != "whatsapp") {
        setIsLoaderActive(false);
        setIsErrorMessageOpen(true);
        setErrorMessage(
          "Ha ocurrido un problema al momento de ingresar a tu cuenta. Por favor intenta de nuevo o contacta al equipo de soporte."
        );
        setErrorMessageButtonLink(ProductsRelativeLink() + "?contextId=" + conversationContext?.contextId);
        setErrorMessageButtonText("Ok");
      } else {
        console.log(`Error detected.\n code:${code}\n state:${state}\n account:${accountProvider?.key}`);
      }
    })();
  }, []);
  return (
    <>
      <CssBaseline />
      {isLoaderActive ? <FullPageLoader type={LoaderType.Authentication} text={"Cargando..."} /> : <></>}
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );
};
