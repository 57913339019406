import { RootState } from 'app/store';
import CokeLoading from 'assets/coke_loading.svg';
import { funcCataloguePortafolioGetById } from 'features/common/API';
import { BaseCategory, updateSelectedSubcategories, updateSelectedSubcategoriesByParent } from 'features/common/categorySlice';
import { filterProducts, setSelectedProducts } from 'features/common/productSlice';
import { LoaderContext } from 'features/contexts/loaderContext';
import { EcommerceProduct } from 'models/ecommerceProduct';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useProductDispatcher = () => {
  const { accountProvider, registerAccount } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);
  const categoriesState = useSelector((state: RootState) => state.categories);

  const { handleLoader } = useContext(LoaderContext);
  const dispatch = useDispatch();

  const dispatchProductByName = async (text: string, setProductResponse?: React.Dispatch<React.SetStateAction<EcommerceProduct[]>>) => {
    handleLoader(CokeLoading, 'Cargando productos...');
    let productsResult = await funcCataloguePortafolioGetById(
      accountProvider?.key as number,
      registerAccount?.account_id as string,
      deliveryData.warehouse_code,
      undefined,
      text,
    );

    handleLoader();

    if (!productsResult) {
      productsResult = [];
    }
    productsResult.forEach((product) => EcommerceProduct.initProductInformation(product, categoriesState.categories));

    if (setProductResponse) {
      setProductResponse(productsResult);
    } else {
      dispatch(setSelectedProducts(productsResult));
    }
  };

  const dispatchProductByCategory = async (category: BaseCategory) => {
    if (category.id.toString() === categoriesState.comboCategories?.comboCategoryId && categoriesState.comboCategories?.isArtificial) {
      // Artificial Category => Find Products locally
      dispatch(
        filterProducts((product) => {
          return product.categories?.includes(category.id.toString());
        })
      );
    } else {
      // Real Category => Fetch products through API.
      handleLoader(CokeLoading, 'Cargando productos...');
      let productsResult = await funcCataloguePortafolioGetById(
        accountProvider?.key as number,
        registerAccount?.account_id as string,
        deliveryData.warehouse_code,
        category.id.toString(),
        undefined,
      );

      handleLoader();

      if (!productsResult) {
        productsResult = [];
      }
      productsResult.forEach((product) => EcommerceProduct.initProductInformation(product, categoriesState.categories));
      dispatch(setSelectedProducts(productsResult));
    }
  };

  const dispatchProductByAllSubCategories = async (category: BaseCategory) => {
    const updatedSubcategories: number[] = updateSelectedSubcategoriesByParent(categoriesState, category.id);
    if (category.id.toString() === categoriesState.comboCategories?.comboCategoryId && categoriesState.comboCategories?.isArtificial) {
      // Artificial Category => Find Products locally
      dispatch(
        filterProducts((product) => {
          return updatedSubcategories.some((cat) => product.categories?.includes(cat.toString()));
        })
      );
    } else {
      // Real Category => Fetch products through API.
      handleLoader(CokeLoading, 'Cargando productos...');
      let productsResult = await funcCataloguePortafolioGetById(
        accountProvider?.key as number,
        registerAccount?.account_id as string,
        deliveryData.warehouse_code,
        updatedSubcategories.join(","),
        undefined,
      );

      handleLoader();

      if (!productsResult) {
        productsResult = [];
      }
      productsResult.forEach((product) => EcommerceProduct.initProductInformation(product, categoriesState.categories));
      dispatch(setSelectedProducts(productsResult));
    }
  };

  const dispatchProductBySubCategory = async (subcategory: BaseCategory) => {
    let updatedSubcategories = updateSelectedSubcategories(categoriesState, subcategory.id);
    if (subcategory.parent_id?.toString() === categoriesState.comboCategories?.comboCategoryId && categoriesState.comboCategories?.isArtificial) {
      // Artificial Subcategories => Find products locally.
      dispatch(
        filterProducts((product) => {
          return updatedSubcategories.some((cat) => EcommerceProduct.filterByCategory(product, cat));
        })
      );
    } else {
      // Real SubCategory => Fetch products through API.
      handleLoader(CokeLoading, 'Cargando productos...');
      let productsResult = await funcCataloguePortafolioGetById(
        accountProvider?.key as number,
        registerAccount?.account_id as string,
        deliveryData.warehouse_code,
        updatedSubcategories.join(","),
        undefined,
      );

      handleLoader();

      if (!productsResult) {
        productsResult = [];
      }
      productsResult.forEach((product) => EcommerceProduct.initProductInformation(product, categoriesState.categories));
      dispatch(setSelectedProducts(productsResult));
    }
  };

  return { dispatchProductByName, dispatchProductByCategory, dispatchProductBySubCategory, dispatchProductByAllSubCategories };
};
