// @ts-nocheck
/* eslint-disable no-undef */

import { getConfigCDS } from '../cdsInit';
import $ from 'jquery';
import { finalizeMagentoLogin } from '../action/finalizeMagentoLogin';
import { responseHandler } from '../model/responseHandler';

export const signIn = (config, accountProviderId, loginPostUrl, phone, country_id) => {
	const successCallback = (res) => {
		if (responseHandler.isSocialStart(res)) {
			console.log(res);
			return;
		}
		console.log('CDS SignIn Success', res);
		
		$('body').trigger('processStart');
		cds.getUserInfo()
		.then((res) => {
			var user = res.user;
			finalizeMagentoLogin(accountProviderId, user, loginPostUrl, phone, 'login', country_id);
		})
		.catch((err) => {
			console.log('GetUserInfo attempt', err);
			$('body').trigger('processStop');
		});
	};

	const failureCallback = (res) => {
		console.log('SignIn attempt error', res);
		
		if (responseHandler.isAlternateFlow(res)) {
			// TODO: check what is this functionality for
			$('#' + config.loginId).hide();
			$('#' + config.socialLoginId).hide();
			$('.block-customer-login .secondary').hide();
			$('body').trigger('cds:alternateFlow:login', res);
		}
	};
	cds.init(getConfigCDS(country_id)).then(() => {
		cds.signIn(config.loginId, config.postLoginId, config.overlay, undefined, successCallback, failureCallback)
		.catch((err) => {
			console.log('SignIn form rendering attempt', err);
		});

		cds.socialSignIn(config.socialLoginId, config.mergeAccountId, config.postLoginId, config.overlay, undefined, successCallback, failureCallback)
		.catch((err) => {
			console.log('SocialSignIn form rendering attempt', err);
		});
	})
	.then(() => {
		$('#fb-button-social').children().append(`<p>${'Inicia sesión con Facebook'}</p>`);
	});
}