// Set ot methods to get all the needed url for transition logics.

let searchParams = new URLSearchParams(window.location.search);
let contextId = searchParams.get('contextId') ? `contextId=${searchParams.get('contextId')}` : '';
let editFlow: string = searchParams.get('edit') ? `&edit=${searchParams.get('edit')}` : '';
let promoFlow: string = searchParams.get('promo') ? `&promo=${searchParams.get('promo')}` : '';
let searchFilter: string = searchParams.get('search') ? `&search=${searchParams.get('search')}` : '';
let categoryFilter: string = searchParams.get('ctg') ? `&ctg=${searchParams.get('ctg')}` : '';
let urlParams: string = contextId || editFlow ? '?' : '';

export const BotWhatsAppLink = (whatsapp_number?: string, message?: string) => {
  let url = `https://wa.me/${whatsapp_number}`;

  if (message) {
    url += `?text=${message}`;
  }

  return url;
};
export const GeofencingPageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }

  return `${window.location.protocol}//${window.location.host}/geofencing${urlParams}${contextId}${editFlow}${promoFlow}${searchFilter}${categoryFilter}${returnUrl}`;
};

export const GeofencingRelativeLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }

  return `/geofencing${urlParams}${contextId}${editFlow}${promoFlow}${searchFilter}${categoryFilter}${returnUrl}`;
};

export const HomePageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }

  return `${window.location.protocol}//${window.location.host}/${urlParams}${contextId}${editFlow}${promoFlow}${returnUrl}`;
};

export const HomeRelativeLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `/${urlParams}${contextId}${editFlow}${promoFlow}${returnUrl}`;
};

export const ProductsPageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `${window.location.protocol}//${window.location.host}/products${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const ProductsRelativeLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `/products${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const CDSLoginPageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `${window.location.protocol}//${window.location.host}/customer/account/login${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const CDSLoginRelativeLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `/customer/account/login${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const CDSCreatePageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `${window.location.protocol}//${window.location.host}/customer/account/create${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const CDSB2CAuth = () => `${window.location.protocol}//${window.location.host}/customer/account/b2c`;

export const AddressFormPageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `${window.location.protocol}//${window.location.host}/address-form${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const AddressFormRelativeLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `/address-form${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const CCETHForgotPassword = (country_id?: string) => {
  const CCETH_WEB = process.env['REACT_APP_CCETH_WEB_' + country_id]
    ? process.env['REACT_APP_CCETH_WEB_' + country_id]
    : '';
  return `${CCETH_WEB}/customer/account/forgotpassword/`;
};

export const CCETHLogin = (country_id?: string) => {
  const CCETH_WEB = process.env['REACT_APP_CCETH_WEB_' + country_id]
    ? process.env['REACT_APP_CCETH_WEB_' + country_id]
    : '';
  return `${CCETH_WEB}/customer/account/login/`;
};

export const DocumentsPageLink = (returnUrl: string = '') => {
  if (returnUrl) {
    returnUrl = `&returnUrl=${returnUrl}`;
  }
  return `${window.location.protocol}//${window.location.host}/document${urlParams}${contextId}${editFlow}${returnUrl}`;
};

export const AuthenticationPageLink = (contextId: string = '') => {
  return `${window.location.protocol}//${window.location.host}/authentication?contextId=${contextId}`;
};

export const PaymentWrapperPageLink = (country_id?: string, token?: string) => {
  const PAYMENT_WRAPPER_WEB = process.env['REACT_APP_CDS_PAYMENT_WRAPPER_' + country_id];
  return `${PAYMENT_WRAPPER_WEB}/?token=${token}`;
};
