import { Button, ButtonGroup, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { RootState } from "app/store";
import "assets/calendar.css";
import DownloadIcon from "assets/icons/ui/download.svg";
import { es } from "date-fns/locale";
import { ProductsRelativeLink } from "features/common/urlBuilder";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/arrow_left.svg";
import NextIcon from "../../assets/chevron_down.svg";
import PreviousIcon from "../../assets/chevron_up.svg";
if (es && es.options) {
  es.options.weekStartsOn = 0;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "80px 0px 0px 0px",
  },
  buttonGroup: {
    marginTop: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    height: "30px",
    position: "absolute",
    top: "16px",
    right: "8px",
  },
  buttonGroupBack: {
    marginTop: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    height: "30px",
    position: "absolute",
    top: "16px",
    left: "8px",
  },
  productButtonItem: {
    border: "0",
    padding: "0px",
    fontWeight: 500,
    width: "30px",
    height: "30px",
  },
  icon: {
    height: "16px",
    witdth: "16px",
    padding: "8px",
  },
  downloadIcon: {
    transform: "rotate(180deg)",
  },
}));

export const DocumentViewer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const { accountProvider } = useSelector((state: RootState) => state.account);
  var bottlers = JSON.parse(accountProvider?.bottlers || "[]");

  var bottlerShippingPolicyUrl = bottlers.find(
    (bottler: { bottler_name: string | undefined }) => bottler.bottler_name === deliveryData.bottler
  )?.shipping_policy_url;

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  const onClickNext = () => {
    if (numPages! && pageNumber + 1 <= numPages) setPageNumber(pageNumber + 1);
  };
  const onClickPrevious = () => {
    if (pageNumber - 1 >= 1) setPageNumber(pageNumber - 1);
  };
  const onClickBack = () => {
    navigate(ProductsRelativeLink());
  };
  const onClickDownload = () => {
    Object.assign(document.createElement("a"), {
      href: bottlerShippingPolicyUrl,
      download: "Políticas de envío Coca-Cola",
    }).click();
  };

  return (
    <>
      <Document
        className={classes.container}
        renderMode="svg"
        loading="Cargando documento..."
        file={bottlerShippingPolicyUrl}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          renderInteractiveForms={false}
          renderAnnotationLayer={false}
          width={window.screen.width}
          pageNumber={pageNumber}
        />
      </Document>
      <ButtonGroup variant="outlined" className={classes.buttonGroup}>
        <Button className={classes.productButtonItem} onClick={() => onClickDownload()}>
          <Tooltip title="Descargar" placement="bottom" arrow={true} enterTouchDelay={0} leaveTouchDelay={3000}>
            <img className={classes.icon} alt="logo" src={DownloadIcon} />
          </Tooltip>
        </Button>
        <Button className={classes.productButtonItem} onClick={() => onClickPrevious()}>
          <img className={classes.icon} alt="logo" src={PreviousIcon} />
        </Button>
        <Button className={classes.productButtonItem}>
          <Typography variant="subtitle1">
            Pagina {pageNumber} de {numPages}
          </Typography>
        </Button>
        <Button className={classes.productButtonItem} onClick={() => onClickNext()}>
          <img className={classes.icon} alt="logo" src={NextIcon} />
        </Button>
      </ButtonGroup>

      <ButtonGroup variant="outlined" className={classes.buttonGroupBack}>
        <Button className={classes.productButtonItem} onClick={() => onClickBack()}>
          <img className={classes.icon} alt="logo" src={BackIcon} />
        </Button>
      </ButtonGroup>
    </>
  );
};
