import { Button, Card, CardActionArea, CardMedia, makeStyles } from "@material-ui/core";
import { RootState } from "app/store";
import { BaseCategory } from "features/common/categorySlice";
import { useBannerImage } from "features/hooks/useBannerImage";
import { useCategoryDispatcher } from "features/hooks/useCategoryDispatcher";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AlcoholicValidationComponent } from "./AlcoholicValidationDialog";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "8px 0px 8px 16px",
    position: "relative",
    boxShadow: "none",
  },
  image: {
    borderRadius: "16px",
    maxWidth: "200px",
    maxHeight: "200px",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "146px",
      width: "100%",
    },
    objectFit: "inherit",
  },
  buttonCategory: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    maxWidth: "200px",
    margin: "auto",
    color: "white",
    backgroundColor: "#000000",
    height: "36px",
    paddingLeft: "0px",
    paddingRight: "0px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "unset",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0em",
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const tryImagePathRequire = (categoryName: string, country_id: string) => {
  const imgName = categoryName
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s/g, "_");

  try {
    return require(`../../assets/categories/${country_id}/${imgName}.jpg`) as string;
  } catch (err) {
    try {
      return require(`../../assets/categories/${country_id}/${imgName}.png`) as string;
    } catch (err) {
      return require(`../../assets/categories/generica01.png`) as string;
    }
  }
};

const tryCategoryRequire = async (categoryName: string, country_id: string) => {
  const filteredName = categoryName
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s/g, "_");

  const { categories } = await import(`../../data/categories/categories_${country_id}`);
  if (categories) {
    let result =
      categories.find((categoryData: { category_name: string }) => categoryData.category_name === filteredName) ??
      categories.find((categoryData: { category_name: string }) => categoryData.category_name === "*");
    return result;
  }
};

export const CategoryItem = ({ category }: { category: EcommerceCategories }) => {
  const classes = useStyles();
  const { accountProvider } = useSelector((state: RootState) => state.account);

  const [categoryData, setCaregoryData] = useState<CategoryData | undefined>(undefined);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const bannerImages = useBannerImage("category");

  const { dispatchCategory}  = useCategoryDispatcher();

  tryCategoryRequire(category.group, accountProvider?.country_id || "").then((currentCategoryData) => {
    setCaregoryData(currentCategoryData);
  });

  const onClickCategory = async () => {
    dispatchCategory(BaseCategory.fromEcommerceCategory(category));
  };

  const getCategoryImage = () => {
    const catImg = bannerImages.find((banner) => banner.redirect === category.child_id);

    return catImg?.src ?? tryImagePathRequire(category.group, accountProvider?.country_id || "");
  };

  const renderCategoryItem = (onClickEvent: () => void) => {
    return (
      <Card key={category.child_id} className={classes.card} onClick={onClickEvent}>
        <CardActionArea>
          <CardMedia component="img" image={getCategoryImage()} title={category.group} className={classes.image} />
          <Button
            onClick={() => setIsOnFocus(true)}
            onMouseEnter={() => setIsOnFocus(true)}
            onMouseLeave={() => setIsOnFocus(false)}
            style={{
              backgroundColor: isOnFocus ? "#e02020" : "#000000",
            }}
            className={classes.buttonCategory}
            variant="contained"
          >
            {category.group}
          </Button>
        </CardActionArea>
      </Card>
    );
  };

  return category.is_alcoholic === true ? (
    <AlcoholicValidationComponent render={renderCategoryItem} validationPassedCallback={onClickCategory} />
  ) : (
    renderCategoryItem(onClickCategory)
  );
};
