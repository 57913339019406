import moment from "moment";

export class ConversationContext {
  public constructor(
    public accountProviderId: number,
    public contextId: string,
    public whatsappNumber: string,
    public cellPhoneNumber: string,
    public provider: string,
    public createdAt: string,
    public userWelcomeCardTexts: string,
    public orderStage: string,
    public order: string,
    public flow_id: string
  ) { }

  public static isValid(conversationContext?: ConversationContext, accountProvider?: AccountProvider): boolean {
    var currentTime = moment().utc();
    var expirationTime = moment(conversationContext?.createdAt).utc().add(accountProvider?.link_expiration_hours, 'hours');

    return accountProvider?.link_expiration_hours
      ? currentTime.isBefore(expirationTime)
      : conversationContext && accountProvider? true : false;
  }
}