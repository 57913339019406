import { Container, Grid, makeStyles } from "@material-ui/core";
import { RootState } from "app/store";
import { register } from "cds/pages/register";
import React from "react";
import { useSelector } from "react-redux";

//TODO: check if it is needed to use this cds-style.css
import "../../assets/cds-sdk-styles.css";

//import "assets/fonts/Text/TCCC-UnityText-Bold.woff2";
import { AddressFormPageLink } from "features/common/urlBuilder";

const useStyles = makeStyles((theme) => ({
  grid: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "TCCC-UnityText",
  },
  loginContainer: {
    paddingBottom: "40px",
  },
  columns: {
    fontFamily: "TCCC-UnityText",
    fontWeight: 400,
    color: "#000000",
    marginBottom: "40px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "35px",
      marginRight: "35px",
    },
  },
  pageTitle: {
    fontSize: "25px",
    marginBottom: "40px",
  },
  cdsFormFormCreateAccount: {
    "& form": {
      "& label": {
        fontWeight: 600,
        fontSize: "15px",
        color: "#000000",
      },
      "& input": {
        borderColor: "#000000",
        borderRadius: "0",
        height: "40px",
        width: "100%",
        fontSize: "18px",
      },
      "& p": {
        fontWeight: "500 !important",
        color: "#e02b27 !important",
      },
      "& #div-marketingConsentRegistration": {
        "& input": {
          position: "absolute",
          width: "20px",
          height: "20px",
          borderColor: "#000000",
          borderRadius: "0",
        },
        "& label": {
          paddingLeft: "35px",
          position: "relative",
          display: "inline-block",
        },
      },
      "& select": {
        borderColor: "#000000",
        borderRadius: "0",
        height: "40px",
        fontSize: "18px",
        "&:nth-child(2)": {
          marginRight: "2%",
          width: "31.33%",
        },
        "&:nth-child(3)": {
          marginRight: "1%",
          marginLeft: "1%",
          width: "31.33%",
        },
        "&:nth-child(4)": {
          marginLeft: "2%",
          width: "31.33%",
        },
      },
      "& #gender": {
        width: "100%",
      },
      "& #dniType": {
        width: "100%",
      },
      "& #show-my-password-newPassword": {
        display: "none",
      },
      "& #show-my-password-newPasswordConfirm": {
        display: "none",
      },
      "& a": {
        color: "#000000",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "revert",
          color: "#e02020",
        },
      },
      "& #div-termsAndPolicyConsentRegistration": {
        paddingBottom: "0px",
        marginBottom: "24px",
        "& input": {
          position: "absolute",
          width: "20px",
          height: "20px",
          borderColor: "#000000",
          borderRadius: "0",
        },
        "& label": {
          paddingLeft: "35px",
          position: "relative",
          display: "inline-block",
        },
      },
      "& #div-personalizationConsentRegistration": {
        paddingBottom: "0px",
        marginBottom: "24px",
        "& input": {
          position: "absolute",
          width: "20px",
          height: "20px",
          borderColor: "#000000",
          borderRadius: "0",
        },
        "& label": {
          paddingLeft: "35px",
          position: "relative",
          display: "inline-block",
        },
      },
      "& .cds-form-submit-cds-register-form": {
        cursor: "pointer",
        paddingBottom: "0px",
        "& button": {
          padding: "13px 25px",
          width: "100%",
          display: "block",
          border: "1px solid #000000",
          backgroundColor: "#000000",
          borderRadius: 25,
          fontWeight: 600,
          fontSize: "15px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#e02020",
          },
        },
      },
    },
  },
  cdsForm: {},
}));

export const CDSCreateUser = ({ config }: { config: CDSIdConfig }) => {
  const classes = useStyles();

  const { accountProvider, conversationContext } = useSelector((state: RootState) => state.account);

  register(
    config,
    conversationContext?.accountProviderId,
    AddressFormPageLink(),
    conversationContext?.cellPhoneNumber,
    accountProvider?.country_id
  );

  return (
    <>
      <Grid className={classes.grid}>
        <h1 className={classes.pageTitle}>
          <span data-ui-id="page-title-wrapper">Crea una cuenta nueva</span>
        </h1>
      </Grid>
      <Container>
        <Grid className={classes.loginContainer}>
          <Grid item xs={12} sm={12} md={6} className={classes.columns}>
            <div>
              {/* Register CDS form */}
              <div id={config.registerFormId} className={classes.cdsFormFormCreateAccount}>
                {" "}
              </div>

              {/* Register merge CDS form */}
              <div id={config.registerMergeFormId} className={classes.cdsForm}>
                {" "}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CDSCreateUser;
