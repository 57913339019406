import React from "react";
import { TooltipRenderProps } from "react-joyride";

export const Tooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}: TooltipRenderProps) => {
  return (
    <div {...tooltipProps} style={{ width: "75%" }}>
      {step.content}
    </div>
  );
};
