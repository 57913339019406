import { Container, Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";

// Components
import { ProductList } from "./ProductList";

// Images
import { CartTotal } from "./CartTotal";
import { Checkout } from "./Checkout";

const useStyles = makeStyles((theme) => ({
  banner: {
    // TODO: VERIFY THIS IS WHAT WE WANT OR GET MORE SIZES
    width: "100%",
    // maxWidth: "100%"
  },
  main: {
    marginTop: "155px",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  ProductGrid: {
    justifyContent: "center",
  },
}));

export const Main = () => {
  const classes = useStyles();

  return (
    <>
      <main className={classes.main}>
        <Container>
          <Grid className={classes.content} container spacing={3} direction="row">
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={1} className={classes.ProductGrid}>
                <ProductList />
                <Hidden mdUp>
                  <CartTotal />
                </Hidden>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Checkout />
            </Grid>
          </Grid>
        </Container>
      </main>
      <footer></footer>
    </>
  );
};
