import React from 'react';
import { Home } from "../main/Home";
import { CssBaseline } from "@material-ui/core";

export const HomePage = () => {
  return (
    <>
      <CssBaseline />
      <Home />
    </>
  )
};
