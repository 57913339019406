import { Button, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography, makeStyles } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { initB2Ccds } from "cds/cdsB2Csdk/b2cInit";
import { funcShoppingCartPutCoupon } from "features/common/API";
import { userStatus } from "features/common/accountSlice";
import { loadShoppingCart, setIsCouponLost } from "features/common/productSlice";
import { CDSLoginRelativeLink } from "features/common/urlBuilder";
import { ModalContext } from "features/contexts/modalContext";
import { useMaintenanceMessage } from "features/hooks/useMaintenanceMessage";
import { ShoppingCartCouponResult, isCouponError } from "models/ecommerceCart";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import TextErrorIcon from "../../assets/icons/ui/errorIcon.svg";
import TextSuccessIcon from "../../assets/icons/ui/successIcon.svg";
import facebookIconLogo from "../../assets/facebookIconLogo.svg";
import googleIconLogo from "../../assets/googleIconLogo.svg";
import { Banner } from "./Banner";
import { FullPageLoader, LoaderType } from "./FullPageLoader";

const useStyles = makeStyles((theme) => ({
  couponSession: {
    height: "100%",
  },
  couponHeaderMessage: {
    fontWeight: 700,
    lineHeight: "20px",
    fontSize: "14px",
  },
  applyCouponButtonGrid: {
    paddingRight: "0px !important",
    width: "100%",
  },
  applyCouponButton: {
    ...inputClasses.mobileStylePrimary,
    whiteSpace: "nowrap",
    borderRadius: "4px",
  },
  couponInputGrid: {
    height: "48px",
    width: "100%",
  },
  modalSocialLogin: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    minHeight: "50vh",
  },
  modalSocialLoginPaper: {
    width: "100%",
    maxWidth: "unset",
    borderRadius: "16px 16px 0 0",
    margin: 0,
  },
  modalSocialLoginCloseButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: "pointer",
    color: "#000",
  },
  modalSocialLoginHeader: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: theme.spacing(1),
  },
  socialLoginSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  loginSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  socialLoginButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    borderRadius: "16px",
    cursor: "pointer",
  },
  socialLoginFacebookButton: {
    backgroundColor: "#1877F2",
    border: "1px solid #1877F2",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#135688",
    },
  },
  socialLoginGoogleButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #000",
    color: "#000",
    "&:hover": {
      backgroundColor: "#E6E6E6",
    },
  },
  socialLoginCokeButton: {
    backgroundColor: "#000000",
    border: "1px solid #000",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  socialRegistrationCokeText: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
  socialLoginIcon: {
    width: "20px",
  },
  socialLoginText: {
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
  },
  customDivider: {
    display: "flex",
    alignItems: "center",
  },
  customDividerhr: {
    flex: 1,
    border: 0,
    borderTop: "1px solid #afafaf",
    margin: "0 10px",
  },
  customDividerText: {
    color: "#afafaf",
  },
  textField: {
    width: "100%",
    height: "40px",
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
  },
  textFieldSuccess: {
    border: "2px solid !important",
    borderColor: "#8FC34E !important",
  },
  textLink: {
    fontWeight: 700,
  },
  textFieldLabelRoot: {
    ...inputClasses.textFieldLabel.root,
  },
  textFieldLabelFocus: {
    ...inputClasses.textFieldLabel.focus,
  },
  textFieldLabelError: {
    ...inputClasses.textFieldLabel.error,
  },
  textFieldInputRoot: {
    ...inputClasses.textFieldInput.root,
    height: "40px",
    fontSize: "12px",
  },
  textFieldInputOutlined: {
    ...inputClasses.textFieldInput.notchedOutline,
  },
  textFieldInputFocus: {
    ...inputClasses.textFieldInput.focus,
  },
  textFieldInputError: {
    ...inputClasses.textFieldInput.error,
  },
  textFieldFilled: {
    width: "100%",
  },
  disabledTextFieldLabelRoot: {
    ...inputClasses.disabledTextFieldLabel.root,
  },
  disabledTextFieldLabelFocus: {
    ...inputClasses.disabledTextFieldLabel.focus,
  },
  disabledTextFieldLabelError: {
    ...inputClasses.disabledTextFieldLabel.error,
  },
  disabledTextFieldInputRoot: {
    ...inputClasses.disabledTextFieldInput.root,
  },
  disabledTextFieldInputOutlined: {
    ...inputClasses.disabledTextFieldInput.notchedOutline,
  },
  disabledTextFieldInputFocus: {
    ...inputClasses.disabledTextFieldInput.focus,
  },
  disabledTextFieldInputError: {
    ...inputClasses.disabledTextFieldInput.error,
  },
  iconImage: {
    height: "22px",
    width: "22px",
    marginLeft: "4px",
    marginRight: "-6px",
  },
}));

const helperTextStyles = makeStyles((theme) => ({
  ...inputClasses.textFieldHelperText,
}));

const COUPON_APPLYING_MESSAGE = "Aplicando cupón...";
const COUPON_DELETE_MESSAGE = "Eliminando cupón...";

export const CartCoupon = () => {
  const classes = useStyles();
  const helperTextClasses = helperTextStyles();

  const { couponCode, cartId, couponIsLost } = useSelector((state: RootState) => state.products);
  const { conversationContext, registerAccount, isUserLoggedIn, accountProvider } = useSelector(
    (state: RootState) => state.account
  );
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const [couponInput, setCouponInput] = useState(couponCode ?? "");
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(COUPON_APPLYING_MESSAGE);

  const [isCouponActionMessageOpen, setIsCouponActionMessageOpen] = useState(false);
  const [couponActionMessage, setCouponActionMessage] = useState("");
  const [couponActionResult, setCouponActionResult] = useState<ShoppingCartCouponResult>(ShoppingCartCouponResult.none);
  const [couponActionTimerId, setCouponActionTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const { handleModal } = useContext(ModalContext);
  const { getMaintenanceMessage } = useMaintenanceMessage();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (couponIsLost) {
      setIsCouponActionMessageOpen(true);
      setCouponActionMessage("El cupón ha dejado de estar aplicado");
      setCouponActionResult(ShoppingCartCouponResult.lost);
      dispatch(setIsCouponLost(false));
    }
  }, [couponIsLost]);

  const handleCDSAction = async (actionType: "facebook" | "google" | "registration" | "login") => {
    const maintenanceMessage = getMaintenanceMessage();
    if (maintenanceMessage) {
      handleModal(maintenanceMessage);
      return;
    }

    if (accountProvider?.registrationFlow === "b2c") {
      const queryParamsMap: Record<string, { [key: string]: string }> = {
        facebook: { goToSignUp: "true" },
        google: { goToSignUp: "true" },
        registration: { goToSignUp: "true" },
        login: { goToSignIn: "true" },
      };
  
      const params = {
        extraQueryParameters: queryParamsMap[actionType] || {},
      };
  
      const sdk = await initB2Ccds(accountProvider?.key);
      sdk.doRedirect(params);
    } else {
      navigate(CDSLoginRelativeLink());
    }
  };

  const isCouponApplied: boolean = couponCode !== undefined && couponCode !== null && couponCode !== "";
  const couponButtonLabel: string = isCouponApplied ? "Eliminar" : "Aplicar";
  const couponInputState: "success" | "error" | "none" = isCouponApplied
    ? "success"
    : isCouponError(couponActionResult)
    ? "error"
    : "none";
  let couponInputEndAdorment: React.ReactNode;
  switch (couponInputState) {
    case "success":
      couponInputEndAdorment = <img className={classes.iconImage} src={TextSuccessIcon} />;
      break;
    case "error":
      couponInputEndAdorment = <img className={classes.iconImage} src={TextErrorIcon} />;
      break;
    default:
      couponInputEndAdorment = null;
  }

  var couponActionType: "success" | "error" | "info" = "success";
  switch(couponActionResult) {
    case ShoppingCartCouponResult.added:
      couponActionType = "success";
      break;
    case ShoppingCartCouponResult.removed:
      couponActionType = "error";
      break;
    default:
    case ShoppingCartCouponResult.lost:
      couponActionType = "info";
      break;
  }

  // Update CouponMessage and Open it
  useEffect(() => {
    if (couponActionResult !== ShoppingCartCouponResult.none) {
      switch (couponActionResult) {
        case ShoppingCartCouponResult.added:
          setCouponActionMessage("Cupón aplicado exitosamente");
          break;
        case ShoppingCartCouponResult.removed:
          setCouponActionMessage("Cupón eliminado exitosamente");
          setCouponInput("");
          break;
        case ShoppingCartCouponResult.notFound:
          setCouponActionMessage("El cupón no es válido o ha expirado");
          setCouponInput("");
          break;
        case ShoppingCartCouponResult.notValid:
          setCouponActionMessage("El cupón no cumple con los términos y condiciones");
          setCouponInput("");
          break;
        case ShoppingCartCouponResult.lost:
          setCouponInput("");
          break;
        default:
        case ShoppingCartCouponResult.error:
          setCouponActionMessage("El cupón no se ha aplicado");
          setCouponInput("");
      }
      setIsCouponActionMessageOpen(true);
    }
  }, [couponActionResult]);

  // After 5 seconds close CouponMessage
  useEffect(() => {
    if (isCouponActionMessageOpen) {
      if (couponActionTimerId) clearTimeout(couponActionTimerId);

      setCouponActionTimerId(
        setTimeout(() => {
          setIsCouponActionMessageOpen(false);
          setCouponActionResult(ShoppingCartCouponResult.none);
        }, 5000)
      );
    }
  }, [isCouponActionMessageOpen]);

  const onClick = async () => {
    setLoaderMessage(isCouponApplied ? COUPON_DELETE_MESSAGE : COUPON_APPLYING_MESSAGE);
    setIsLoaderActive(true);
    const shoppingCart = await funcShoppingCartPutCoupon(
      conversationContext?.accountProviderId as number,
      registerAccount?.account_id as string,
      cartId,
      isCouponApplied ? "remove" : "add",
      deliveryData.encoded_route_info as string,
      couponInput
    );
    if (shoppingCart) {
      dispatch(loadShoppingCart(shoppingCart));
      setCouponActionResult(shoppingCart.coupon_result ?? ShoppingCartCouponResult.error);
    } else {
      setCouponActionResult(ShoppingCartCouponResult.error);
    }

    setIsLoaderActive(false);
  };

  return (
    <div>
      <Grid container direction="column" spacing={3} className={classes.couponSession}>
        <Grid item>
          <Typography className={classes.couponHeaderMessage}>Descuentos</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          direction="row"
          wrap="nowrap"
          className={classes.couponInputGrid}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={9}>
            <TextField
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelRoot,
                  focused: classes.textFieldLabelFocus,
                  error: classes.textFieldLabelError,
                },
              }}
              FormHelperTextProps={{ classes: helperTextClasses }}
              InputProps={{
                classes: {
                  root: classes.textFieldInputRoot,
                  focused: classes.textFieldInputFocus,
                  notchedOutline: couponInputState === "success" ? classes.textFieldSuccess : undefined,
                  error: classes.textFieldInputError,
                },
                endAdornment: couponInputEndAdorment,
              }}
              value={couponInput}
              error={couponInputState === "error"}
              placeholder="Escribe tu código promocional"
              variant="outlined"
              disabled={isCouponApplied || isUserLoggedIn === userStatus.LoggedOut}
              onChange={(e) => setCouponInput(e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={3} className={classes.applyCouponButtonGrid}>
            <Button
              color="primary"
              className={classes.applyCouponButton}
              onClick={onClick}
              disabled={couponInput === "" || isUserLoggedIn === userStatus.LoggedOut}
            >
              {couponButtonLabel}
            </Button>
          </Grid>
        </Grid>
        {isCouponActionMessageOpen && (
          <Grid item style={{ paddingBottom: "4px" }}>
            <Banner
              type={couponActionType}
              message={couponActionMessage}
              showIcon={true}
            />
          </Grid>
        )}
        {isUserLoggedIn === userStatus.LoggedOut && (
          <Grid item style={{ paddingBottom: "4px" }}>
            <Banner
              type="info"
              showIcon={true}
              message={
                <Typography className={classes.textLink}>
                  Para redimir tu cupón,{" "}
                  <a id="couponsLoginButton" style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => openModal()}>
                    inicia sesión o crea una cuenta
                  </a>{" "}
                </Typography>
              }
            />
          </Grid>
        )}
        <Dialog open={isModalOpen} classes={{ container: classes.modalSocialLogin, paper: classes.modalSocialLoginPaper }}>
          <IconButton
            onClick={() => {
              setIsModalOpen(false);
            }}
            className={classes.modalSocialLoginCloseButton}
            data-tracking="couponsLoginModuleCloseButton"
          >
            <CloseIcon data-tracking="couponsLoginModuleCloseButton"><CloseIcon /></CloseIcon>
          </IconButton>
          <DialogTitle></DialogTitle>
          <DialogContent>
          <Typography variant="h5" className={classes.modalSocialLoginHeader}>
            Para redimir tu cupón,
          </Typography>
          <Typography variant="h5" className={classes.modalSocialLoginHeader}>
            selecciona una opción
          </Typography>
          <Container className={classes.socialLoginSection}>
            <Button className={`${classes.socialLoginFacebookButton} ${classes.socialLoginButton}`} data-tracking="couponsFacebookLoginButton" onClick={() => handleCDSAction("facebook")}>
              <img src={facebookIconLogo} alt="Facebook Logo" className={classes.socialLoginIcon} data-tracking="couponsFacebookLoginButton"/>
              <span className={classes.socialLoginText} data-tracking="couponsFacebookLoginButton">Continúa con Facebook</span>
            </Button>
            <Button className={`${classes.socialLoginGoogleButton} ${classes.socialLoginButton}`} data-tracking="couponsGoogleLoginButton" onClick={() => handleCDSAction("google")}>
              <img src={googleIconLogo} alt="Google Logo" className={classes.socialLoginIcon} data-tracking="couponsGoogleLoginButton"/>
              <span className={classes.socialLoginText} data-tracking="couponsGoogleLoginButton">Continúa con Google</span>
            </Button>
          </Container>
          <div className={classes.customDivider}>
            <hr className={classes.customDividerhr} />
            <span className={classes.customDividerText}>o</span>
            <hr className={classes.customDividerhr} />
          </div>
          <Container className={classes.loginSection}>
            <Typography className={classes.socialRegistrationCokeText}>
              <a id="couponsStandardRegistrationButton" style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleCDSAction("registration")}>
                ¿No tienes una cuenta? Regístrate
              </a>{" "}
            </Typography>
            <Button className={`${classes.socialLoginCokeButton} ${classes.socialLoginButton}`} data-tracking="couponsStandardEmailLoginButton" onClick={() => handleCDSAction("login")}>
              <span className={classes.socialLoginText} data-tracking="couponsStandardEmailLoginButton">Inicia Sesión con Correo</span>
            </Button>
          </Container>  
          </DialogContent>
        </Dialog>
        <Grid item>
          <Divider />
        </Grid>
      </Grid>
      {isLoaderActive && <FullPageLoader type={LoaderType.Authentication} text={loaderMessage} />}
    </div>
  );
};
