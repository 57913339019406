import { AppBar, CssBaseline, makeStyles } from "@material-ui/core";
import { EcommerceNavbar } from "features/main/Navbars/EcommerceNavbar";
import React from "react";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appbar: {
    position: "fixed",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fafafa",
  },
}));

export const EcommercePage = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <AppBar className={classes.appbar}>
        <EcommerceNavbar />
      </AppBar>
      <Outlet />
    </>
  );
};
