// @ts-nocheck
/* eslint-disable no-undef */

import { CDSB2CAuth } from "../../features/common/urlBuilder";

export const initB2Ccds = async (account_provider_id) => {
  const CDS_CLIENT_ID = process.env[`REACT_APP_CDS_PROVIDER_${account_provider_id}_CLIENT_ID`] ?? "";
  const CDS_SCOPE = process.env[`REACT_APP_CDS_PROVIDER_${account_provider_id}_SCOPE`] ?? "";
  const CDS_ENV = process.env[`REACT_APP_CDS_ENV`] ?? "";
  let sdk;

  if (CDS_ENV === "Prod") {
    require("./tcccLoginProd");
    sdk = await new tcccLoginProd.LoginSdk({
      clientID: CDS_CLIENT_ID,
      tokenExchangeUrl: CDSB2CAuth(),
      scope: CDS_SCOPE,
    });
  }
  if (CDS_ENV === "Gamma") {
    require("./tcccLoginGamma");
    sdk = await new tcccLoginGamma.LoginSdk({
      clientID: CDS_CLIENT_ID,
      tokenExchangeUrl: CDSB2CAuth(),
      scope: CDS_SCOPE,
    });
  }

  await sdk.isSDKReady();
  window.sdk = sdk;
  return sdk;
};
