import { Backdrop, Grid, Typography, makeStyles } from "@material-ui/core";
import { LoaderContext } from "features/contexts/loaderContext";
import React, { useContext } from "react";
import ReactDOM from "react-dom";

const useStyles = makeStyles((theme) => ({
  caption: {
    left: "0px",
    position: "fixed",
    color: "white",
    textAlign: "center",
    width: "100%",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#717171AD",
  },
  img: {
    width: "70px",
    [theme.breakpoints.up("sm")]: {
      width: "120px",
    },
  },
}));

export const Loader = () => {
  const { showLoader, loaderImage, loaderMessage } = useContext(LoaderContext);
  const classes = useStyles();
  if (showLoader) {
    return ReactDOM.createPortal(
      <Backdrop className={classes.backdrop} open={showLoader}>
        <Grid container direction="column" alignContent="center" justifyContent="center" alignItems="center">
          <Grid item>
            <img className={classes.img} src={loaderImage} alt="loader" />
          </Grid>
          <Grid item>
            <Typography variant="h5" className={classes.caption}>
              {loaderMessage}
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>,
      document.querySelector("#loader-root")!
    );
  } else return null;
};
