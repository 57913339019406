import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { RootState } from "app/store";
import ChevronDown from "assets/chevron-down-light.svg";
import ChevronRight from "assets/icons/ui/chevron-right.svg";
import { Category, Subcategory } from "features/common/categorySlice";
import { useCategoryDispatcher } from "features/hooks/useCategoryDispatcher";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 340,
  },
  listItem: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  nestedListItem: {
    paddingLeft: theme.spacing(3),
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  categoryIcon: {
    width: "16px",
    height: "auto",
  },
  label: {
    textAlign: "left",
    position: "relative",
    padding: "8px 0",
    width: "171px",
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#232323",
  },
}));

interface CategoryDrawerListProps {
  onCategorySelected: () => void;
}
export const CategoryDrawerList = ({ onCategorySelected }: CategoryDrawerListProps) => {
  const classes = useStyles();
  const { categories } = useSelector((state: RootState) => state.categories);
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const [expanded, setExpanded] = useState<number | false>(false);
  const [categoryIcons, setCategoryIcons] = useState<any>({});
  const { dispatchAllSubcategories, dispatchSubcategory } = useCategoryDispatcher();

  import(`../../data/categories/categories_icons`).then((icons) => {
    setCategoryIcons(icons);
  });

  const handleListExpand = (category: Category) => {
    if(category.subcategories.length === 1 && category.subcategories[0].name === category.name) {
      handleListClick(category);
    } else {
      setExpanded(expanded === category.id? false : category.id);
    }
  };

  const handleListClick = (category: Category) => {
    dispatchAllSubcategories(category);
    onCategorySelected();
  };

  const handleNestedListClick = (subcategory: Subcategory) => {
    dispatchSubcategory(subcategory);
    onCategorySelected();
  };

  const getIconName = (categoryName: string) =>
    categoryName
      .trim()
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replace(/\s/g, "_");
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="h4" className={classes.label}>
            Categorías
          </Typography>
        </ListSubheader>
      }
      className={classes.root}
    >
      {categories.map((category) => {
        return (
          <div style={{paddingLeft: 8, paddingRight: 4}}>
            <ListItem button disableRipple className={classes.listItem} onClick={() => handleListExpand(category)}>
              {accountProvider?.is_using_categories_icon && (
              <ListItemIcon style={{ minWidth: "20px" }}>
                {categoryIcons[getIconName(category.name)]?.render({ className: classes.categoryIcon }) ??
                  categoryIcons.multi_category?.render({ className: classes.categoryIcon })}
              </ListItemIcon>
              )}
              <ListItemText primary={expanded === category.id ? <strong>{category.name}</strong> : category.name} />
              <img style={{height: 16, width: 17, marginLeft: "24px"}} src={expanded === category.id ? ChevronDown : ChevronRight} alt="" />
            </ListItem>
            {category.subcategories.length >= 1 ? (
              <Collapse in={expanded === category.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {category.subcategories.map((subcategory) => (
                    <ListItem
                      button
                      onClick={() => handleNestedListClick(subcategory)}
                      disableRipple
                      className={classes.nestedListItem}
                    >
                      <ListItemText primary={subcategory.name} />
                    </ListItem>
                  ))}
                  <ListItem
                    button
                    disableRipple
                    className={classes.nestedListItem}
                    style={{ textDecoration: "underline" }}
                    onClick={() => handleListClick(category)}
                  >
                    <ListItemText primary="Ver todo" />
                  </ListItem>
                </List>
              </Collapse>
            ) : null}
          </div>
        );
      })}
    </List>
  );
};
