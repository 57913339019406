import { AddressComponents, GeocodingInfo } from "./geocoding";

// Geofencing models
export class AvailabilityZone{
    account_provider_id: number | null;
    account_id: string | null;
    
    route_id: string | null;
    warehouse_code: string | null;
    encoded_route_info: string | null;
    region_id: string | null;
    region_code: string | null;
    
    coordinates: Coordinates;
    address: AvailabilityAddress;

    isComplete() : boolean {
        return [this.route_id, this.address.city, this.address.country_id, this.address.municipality, this.address.region, this.address.street].every((value) => value && value !== '');
    }

    private constructor(
        account_provider_id: number | null,
        account_id: string | null,
        route_id: string | null,
        warehouse_code: string | null,
        encoded_route_info: string | null,
        region_id : string | null,
        region_code: string | null,
        coordinates: Coordinates,
        address: AvailabilityAddress){
        
        this.account_provider_id= account_provider_id;
        this.account_id = account_id;
        this.route_id = route_id;
        this.warehouse_code = warehouse_code;
        this.encoded_route_info = encoded_route_info;
        this.region_id = region_id;
        this.region_code = region_code;
        this.coordinates = coordinates;
        this.address = address;
    };

    static isComplete = (availabilityZone: AvailabilityZone, country_id: string) : boolean => {
        switch(country_id) {
            case 'GT':
                return [availabilityZone.address.city, availabilityZone.address.country_id, availabilityZone.address.municipality, availabilityZone.address.region, availabilityZone.address.street].every((value) => value && value !== 'undefined');
            case 'MX':
            default:
                return [availabilityZone.route_id, availabilityZone.address.city, availabilityZone.address.country_id, availabilityZone.address.municipality, availabilityZone.address.postal_code, availabilityZone.address.region, availabilityZone.address.street].every((value) => value && value !== 'undefined');
        }
    }

    static addressComplete = (availabilityZone: AvailabilityZone) : boolean => {
        return [availabilityZone.address.country_id, availabilityZone.address.region].every((value) => value && value !== 'undefined');
    }
    static getStreet = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('route'))?.long_name || '';  
	};

    static getCity = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('administrative_area_level_2') || component.types.includes('locality') )?.long_name || '';
	};

    static getMunicipality = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('administrative_area_level_2') || component.types.includes('locality') )?.long_name || '';
	};

    static getRegion = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || '';  
	};

    static getPostalCode = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('postal_code'))?.long_name || '';  
	};

    static getCountryId = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('country'))?.short_name || '';  
	};

    static getNeighborhood = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('sublocality') || component.types.includes('sublocality_level_1') || component.types.includes('neighborhood'))?.long_name || '';  
	};

    static getNumberInt = ( addressArray: AddressComponents[] ): string => {
		return addressArray.find((component) => component.types.includes('street_number'))?.long_name || '';
	};

    static fromGeocodingInfo(geocoding: GeocodingInfo): AvailabilityZone{
        return new AvailabilityZone(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            geocoding.geometry.location,
            {
                street: this.getStreet(geocoding.address_components),
                city: this.getCity(geocoding.address_components),
                region: this.getRegion(geocoding.address_components),
                country_id: this.getCountryId(geocoding.address_components),
                postal_code: this.getPostalCode(geocoding.address_components),
                municipality: this.getMunicipality(geocoding.address_components),
                neighborhood: this.getNeighborhood(geocoding.address_components),
                number: this.getNumberInt(geocoding.address_components),
            } as AvailabilityAddress
        );
    };
}

export interface AvailabilityAddress{
    street: string;
    city: string;
    region: string;
    country_id: string;
    postal_code: string;
    municipality: string;
    neighborhood: string;
    number: string;
}

export interface Coordinates{
    lat: number;
    lng: number;
}

export interface ValidateAvailabilityResult{
    response: boolean;
    warehouse_code: string;
}
