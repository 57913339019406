import React, { Component } from "react";
import { FullPageLoader, LoaderType } from "./FullPageLoader";

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state to indicate that an error occurred
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Log or handle the error, e.g., send it to an analytics service
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // Render your fallback UI here, such as an error message
      return <FullPageLoader type={LoaderType.Authentication} text="Ups lo siento algo salio mal por favor intenta de nuevo (refresh)" />;
    }

    // Render the children if there's no error
    return this.props.children;
  }
}

export default ErrorBoundary;