let regions_MX: Region[] = [
  { region_id: 622, region: 'Aguascalientes', region_code: 'AGU' },
  { region_id: 623, region: 'Baja California', region_code: 'BCN' },
  { region_id: 624, region: 'Baja California Sur', region_code: 'BCS' },
  { region_id: 625, region: 'Campeche', region_code: 'CAM' },
  { region_id: 626, region: 'Chiapas', region_code: 'CHP' },
  { region_id: 627, region: 'Chihuahua', region_code: 'CHH' },
  { region_id: 628, region: 'Ciudad de México', region_code: 'CMX' },
  { region_id: 629, region: 'Coahuila de Zaragoza', region_code: 'COA' },
  { region_id: 630, region: 'Colima', region_code: 'COL' },
  { region_id: 631, region: 'Durango', region_code: 'DUR' },
  { region_id: 632, region: 'Estado de México', region_code: 'MEX' },
  { region_id: 633, region: 'Guanajuato', region_code: 'GUA' },
  { region_id: 634, region: 'Guerrero', region_code: 'GRO' },
  { region_id: 635, region: 'Hidalgo', region_code: 'HID' },
  { region_id: 636, region: 'Jalisco', region_code: 'JAL' },
  { region_id: 637, region: 'Michoacán', region_code: 'MIC' },
  { region_id: 638, region: 'Morelos', region_code: 'MOR' },
  { region_id: 639, region: 'Nayarit', region_code: 'NAY' },
  { region_id: 640, region: 'Nuevo León', region_code: 'NLE' },
  { region_id: 641, region: 'Oaxaca', region_code: 'OAX' },
  { region_id: 642, region: 'Puebla', region_code: 'PUE' },
  { region_id: 643, region: 'Querétaro', region_code: 'QUE' },
  { region_id: 644, region: 'Quintana Roo', region_code: 'ROO' },
  { region_id: 645, region: 'San Luis Potosí', region_code: 'SLP' },
  { region_id: 646, region: 'Sinaloa', region_code: 'SIN' },
  { region_id: 647, region: 'Sonora', region_code: 'SON' },
  { region_id: 648, region: 'Tabasco', region_code: 'TAB' },
  { region_id: 649, region: 'Tamaulipas', region_code: 'TAM' },
  { region_id: 650, region: 'Tlaxcala', region_code: 'TLA' },
  { region_id: 651, region: 'Veracruz', region_code: 'VER' },
  { region_id: 652, region: 'Yucatán', region_code: 'YUC' },
  { region_id: 653, region: 'Zacatecas', region_code: 'ZAC' },
]

export default regions_MX;