import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BannerAction, useBannerActions } from "features/hooks/useBannerActions";
import { useBannerImage } from "features/hooks/useBannerImage";
import SwiperCore, { Autoplay, Pagination, Swiper as SwiperClass } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Autoplay, Pagination]);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "700px",
    margin: "auto",
    paddingTop: "25px",
  },
  img: {
    margin: "auto",
    display: "block",
    overflow: "hidden",
    width: "100%",
    borderRadius: "16px",
    cursor: "pointer",
  },
}));

export function PromotionCarousel() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [resizeKey, setResizeKey] = useState(0);

  const banners = useBannerImage("banner");
  const { categoryRedirect, whatsappRedirect, linkRedirect, productRedirect, searchRedirect } = useBannerActions();

  useEffect(() => {
    const handleResize = () => {
      setResizeKey(prevKey => prevKey + 1); // Increase the key to force the update
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSwiperClick = (swiper: SwiperClass) => {
    const { realIndex } = swiper;
    const banner = banners[realIndex];

    if (banner) {
      const { redirect } = banner;
      const payload = JSON.parse(redirect) as BannerAction;

      switch (payload.action) {
        case "category":
          categoryRedirect(payload.category_id);
          break;
        case "whatsapp":
          whatsappRedirect(payload.phone, payload.message);
          break;
        case "link":
          linkRedirect(payload.url, payload.internal);
          break;
        case "product":
          productRedirect(payload.product_name);
          break;
        case "search":
          searchRedirect(payload.search);
          break;
      }
    }
  };

  const maxSteps = banners.length;
  return maxSteps > 0 ? (
    <Grid container className={classes.root}>
      <Swiper
        key={resizeKey}
        onClick={handleSwiperClick}
        slidesPerView={maxSteps > 1 ? (isDesktop ? 1 : 1.2) : 1.1}
        spaceBetween={maxSteps > 1 ? (isDesktop ? 0 : 15) : 0}
        loop={maxSteps > 1}
        watchOverflow={true}
        centeredSlides={maxSteps > 1}
        pagination={
          maxSteps > 1
            ? {
                clickable: true,
                bulletClass: "carousel-pagination-bullet",
                bulletActiveClass: "carousel-pagination-bullet-active",
              }
            : {}
        }
        style={
          maxSteps > 1
            ? {
                paddingBottom: "40px",
              }
            : {}
        }
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <img src={banner.src} className={classes.img} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  ) : null;
}