import { configureStore, getDefaultMiddleware, Action, combineReducers } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers
import { categoriesReducer } from "features/common/categorySlice";
import { productsReducer } from "features/common/productSlice";
import { deliveryReducer } from "features/common/deliverySlice";
import { geofencingReducer } from "features/common/geofencingSlice";
import { accountReducer } from "features/common/accountSlice";

export const rootReducer = combineReducers({
  categories: categoriesReducer,
  products: productsReducer,
  deliveryData: deliveryReducer,
  geofencing: geofencingReducer,
  account: accountReducer,
});

// Persist Config
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
