import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { AppDispatch, RootState } from "app/store";
import { AgeValidationState, setAgeValidation } from "features/common/accountSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentsPageLink } from "features/common/urlBuilder";
import { LogoMain } from "./LogoMain";

const useStyles = makeStyles((theme) => ({
  rootAgeVerification: {
    textAlign: "center",
  },
  textPrimary: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "44px",
    }
  },
  textSecondary: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "16px",
      marginLeft: "0px !important"
    },
    [theme.breakpoints.up("sm")]: {
      width: "44px",
      marginTop: "0px",
      marginLeft: "8px !important"
    },
  },
  ageTitle: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
  },
  ageBoxTitle:{
    position: "relative",
    left: "50%",
    transform: "translate(-50%, -50%)",
    top: "14px",
  },
  ageVerificationCloseButton: {
    cursor:'pointer',
    float:'right',
    width: "30px",
    height: "30px",
  },
  ageHeader: {
    color: "#000000",
    marginTop:"12px",
  },
  ageButtonsDialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
      padding: "8px 24px 0px 24px",
    },
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  policyAge: {
    marginLeft: "8px",
    marginBottom: "24px",
    marginRight: "8px",
    marginTop: "24px",
  },
  logoAge: {
    cursor: "pointer",
    height: "30px",
    maxWidth: "250px"
  },
}));

export const AlcoholicValidationComponent = ({
  render,
  validationPassedCallback,
}: {
  render: (onClickValidation: () => void) => JSX.Element;
  validationPassedCallback: () => void;
}) => {
  const { ageValidation } = useSelector((state: RootState) => state.account);
  const [isAlcoholicValidationOpen, setIsAlcoholicValidationOpen] = useState(false);
  const [isAlcoholicValidationFailedOpen, setIsAlcoholicValidationFailedOpen] = useState(false);

  const onClickValidation = () => {
    switch (ageValidation) {
      case AgeValidationState.Invalid:
        setIsAlcoholicValidationFailedOpen(true);
        setIsAlcoholicValidationOpen(false);
        break;
      case AgeValidationState.Valid:
        setIsAlcoholicValidationFailedOpen(false);
        setIsAlcoholicValidationOpen(false);
        validationPassedCallback();
        break;
      default:
      case AgeValidationState.Incomplete:
        setIsAlcoholicValidationFailedOpen(false);
        setIsAlcoholicValidationOpen(true);
        break;
    }
  };
  return (
    <div>
      {isAlcoholicValidationOpen && (
        <AlcoholicValidationDialog
          setIsDialogVisible={setIsAlcoholicValidationOpen}
          validationPassedCallback={validationPassedCallback}
        />
      )}
      {isAlcoholicValidationFailedOpen && (
        <AlcoholicValidationFailedDialog setIsDialogVisible={setIsAlcoholicValidationFailedOpen} />
      )}
      {render(onClickValidation)}
    </div>
  );
};

export const AlcoholicValidationDialog = ({
  setIsDialogVisible,
  validationPassedCallback,
}: {
  setIsDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  validationPassedCallback: () => void;
}) => {
  const classes = useStyles();
  const { ageValidation, accountProvider } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector(
    (state: RootState) => state.deliveryData
  );
  const [isOpen, setIsOpen] = useState(true);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    (() => {
      if (ageValidation === AgeValidationState.Valid) {
        setIsDialogVisible(false);
        setIsOpen(false);
        validationPassedCallback();
      }
    })();
  }, [ageValidation]);

  var bottlers = JSON.parse(accountProvider?.bottlers || "[]");
  var bottlerShippingPolicyUrl = bottlers.find(
    (bottler: { bottler_name: string | undefined }) => bottler.bottler_name === deliveryData.bottler
  )?.shipping_policy_url;

  return (
    <Dialog open={isOpen} className={classes.rootAgeVerification}>
      <Box className={classes.ageTitle}>
        <Box className={classes.ageBoxTitle} flexGrow={1}>
          <Typography variant="h4">Mayoría de edad</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              setIsDialogVisible(false);
              setIsOpen(false);
            }}
          >
            <CloseIcon className={classes.ageVerificationCloseButton}></CloseIcon>
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <DialogContentText className={classes.ageHeader}>
          <Typography variant="body1">¿Eres mayor de 18 años?</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.ageButtonsDialog}>
        <Button className={classes.textPrimary} color="primary" onClick={() => dispatch(setAgeValidation(true))}>
          Sí
        </Button>
        <Button
          className={classes.textSecondary}
          color="secondary"
          onClick={() => {
            setIsDialogVisible(false);
            dispatch(setAgeValidation(false));
          }}
        >
          No
        </Button>
      </DialogActions>
      <label className={classes.policyAge}>
        <Typography variant="caption">
          La venta se realizará únicamente a mayores de edad. El abuso en el consumo de este producto es nocivo para la salud. EVITA EL EXCESO.
        </Typography>
        <br /> <br />
        <Typography variant="caption">
          Al confirmar que eres mayor de 18 años reconoces estar de acuerdo con las <a href="https://www.coca-colaentuhogar.com/condiciones-de-uso/">Condiciones de uso </a>
          y las <a href={DocumentsPageLink()}>Políticas de envío.</a>
        </Typography>
      </label>
    </Dialog>
  );
};

export const AlcoholicValidationFailedDialog = ({
  setIsDialogVisible,
}: {
  setIsDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const { accountProvider } = useSelector((state: RootState) => state.account);

  return (
    <Dialog open={true} className={classes.rootAgeVerification}>
      <LogoMain className={classes.logoAge} />
      <DialogContent>
        <DialogContentText className={classes.ageHeader}>
          <Typography variant="body1">
            ¡Atención! Bebidas con alcohol están disponibles únicamente para mayores de edad.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.ageButtonsDialog}>
        <Button
          color="primary"
          onClick={() => {
            setIsDialogVisible(false);
          }}
        >
          Enterado
        </Button>
      </DialogActions>
      <label className={classes.policyAge}>
        <Typography variant="caption">
          Si eres mayor de edad y consideras que hay un error con tu fecha de nacimiento, comunícate al{" "}
          {accountProvider?.contact_number}.
        </Typography>
      </label>
    </Dialog>
  );
};
