import { Button, createStyles, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { setRegisterAccount } from "features/common/accountSlice";
import { funcAccountAddressPost, funcRegisterGetByPhone, funcShoppingCartPostDefault } from "features/common/API";
import { setDeliveryData } from "features/common/deliverySlice";
import { GeofencingPageLink, ProductsPageLink } from "features/common/urlBuilder";
import { AvailabilityZone } from "models/geofencing";
import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextErrorIcon from "../../../assets/icons/ui/textInputErrorIcon.svg";
import { FullPageLoader, LoaderType } from "../FullPageLoader";
import { MessageDialog } from "../MessageDialog";
import { EcommerceAddress } from "models/ecommerceAddress";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "";

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: "100%",
      fontWeight: 500,
    },
    inputGrid: {
      marginTop: theme.spacing(2),
    },
    inputGridSeparator: {
      marginTop: theme.spacing(4),
    },
    textField: {
      width: "100%",
    },
    textFieldLabelRoot: {
      ...inputClasses.textFieldLabel.root,
    },
    textFieldLabelFocus: {
      ...inputClasses.textFieldLabel.focus,
    },
    textFieldLabelError: {
      ...inputClasses.textFieldLabel.error,
    },
    textFieldInputRoot: {
      ...inputClasses.textFieldInput.root,
    },
    textFieldInputOutlined: {
      ...inputClasses.textFieldInput.notchedOutline,
    },
    textFieldInputFocus: {
      ...inputClasses.textFieldInput.focus,
    },
    textFieldInputError: {
      ...inputClasses.textFieldInput.error,
    },
    textFieldFilled: {
      width: "100%",
    },
    disabledTextFieldLabelRoot: {
      ...inputClasses.disabledTextFieldLabel.root,
    },
    disabledTextFieldLabelFocus: {
      ...inputClasses.disabledTextFieldLabel.focus,
    },
    disabledTextFieldLabelError: {
      ...inputClasses.disabledTextFieldLabel.error,
    },
    disabledTextFieldInputRoot: {
      ...inputClasses.disabledTextFieldInput.root,
    },
    disabledTextFieldInputOutlined: {
      ...inputClasses.disabledTextFieldInput.notchedOutline,
    },
    disabledTextFieldInputFocus: {
      ...inputClasses.disabledTextFieldInput.focus,
    },
    disabledTextFieldInputError: {
      ...inputClasses.disabledTextFieldInput.error,
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
    iconImage: {
      height: "16px",
      width: "16px",
      marginRight: "4px",
      marginLeft: "4px",
      alignItems: "center",
    },
  })
);

const helperTextStyles = makeStyles((theme) => ({
  ...inputClasses.textFieldHelperText,
}));

// TODO: check if is possible to Refactor in a single component all the address forms.
export const AddressFormGt = () => {
  const classes = useStyles();
  const helperTextClasses = helperTextStyles();
  const navigate = useNavigate();

  // Loader State
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const { deliveryData, deliveryAddresses } = useSelector(
    (state: RootState) => state.deliveryData
  );
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { availabilityZone } = useSelector((state: RootState) => state.geofencing);
  const { cartId } = useSelector((state: RootState) => state.products);
  const dispatch: AppDispatch = useDispatch();

  var initalPhoneNumber: string =
    conversationContext?.cellPhoneNumber.startsWith(accountProvider?.phone_digits_prefix as string) &&
    conversationContext?.cellPhoneNumber.length ===
      (accountProvider?.phone_digits_prefix.length as number) + (accountProvider?.phone_digits_quantity as number)
      ? (conversationContext?.cellPhoneNumber.slice(-1 * (accountProvider?.phone_digits_quantity as number)) as string)
      : "";

  var initialFirstName: string = registerAccount?.name.split(" ")[0] || "";
  var initialLastName: string = registerAccount?.name.split(" ").slice(1).join(" ") || "";

  // Load form fields
  const [street, setStreet] = useState(availabilityZone?.address.street);
  const [neighborhood, setNeighborhood] = useState(availabilityZone?.address.neighborhood || "");
  const [number_int, setNumberInt] = useState("");
  const [postal_code, setPostalCode] = useState(availabilityZone?.address.postal_code ?? "-");

  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [phone, setPhone] = useState(initalPhoneNumber);

  // Load form errors
  const [streetError, setStreetError] = useState("");
  const [neighborhoodError, setNeighborhoodError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [numberIntError, setNumberIntError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  useEffect(() => {
    // When warehouse_code or availabilityZone is missing or incomplete redirect to geofencing page.
    if (
      !deliveryData.warehouse_code ||
      !availabilityZone ||
      !AvailabilityZone.isComplete(availabilityZone, accountProvider?.country_id || "")
    ) {
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo siento 😅, tu sesión ha expirado, presiona regresar para continuar con el flujo de compra.");
      setErrorMessageButtonLink(GeofencingPageLink());
      setErrorMessageButtonText("Regresar");
      return;
    }

    (async () => {
      if (!registerAccount) {
        var registerAccount = await funcRegisterGetByPhone(
          conversationContext?.accountProviderId as number,
          conversationContext?.cellPhoneNumber as string
        );
        dispatch(setRegisterAccount(registerAccount as RegisterAccount));

        var initialFirstName: string = registerAccount?.name.split(" ")[0] || "";
        var initialLastName: string = registerAccount?.name.split(" ").slice(1).join(" ") || "";

        setFirstName(initialFirstName);
        setLastName(initialLastName);
      }
    })();
  }, []);

  const validateForm = () => {
    let isValid = true;
    if (street === "") {
      setStreetError("Este campo es obligatorio.");
      isValid = false;
    } else {
      setStreetError("");
    }

    if (neighborhood === "") {
      setNeighborhoodError("Este campo es obligatorio.");
      isValid = false;
    } else if (neighborhood == "0") {
      setNeighborhoodError("Ingresa un valor válido.");
      isValid = false;
    } else {
      setNeighborhoodError("");
    }

    if (postal_code === "") {
      setPostalCodeError('Este campo es obligatorio. Ingrese "-" si no conoce su código postal.');
      isValid = false;
    } else if (!postal_code?.match("^[- A-Za-z0-9]+$")) {
      setPostalCodeError("El código postal solo puede contener letras, números, espacios o guión medio.");
      isValid = false;
    } else {
      setPostalCodeError("");
    }

    if (phone === "") {
      setPhoneError("Campo obligatorio");
      isValid = false;
    } else if (phone.length !== accountProvider?.phone_digits_quantity) {
      setPhoneError(`El número de teléfono debe tener ${accountProvider?.phone_digits_quantity} dígitos.`);
      isValid = false;
    } else if (!/^\d+$/.test(phone)) {
      setPhoneError(`El número de teléfono solo puede tener dígitos.`);
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (firstName === "") {
      setFirstNameError("Campo obligatorio");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (lastName === "") {
      setLastNameError("Campo obligatorio");
      isValid = false;
    } else {
      setLastNameError("");
    }

    return isValid;
  };

  const onSendClick = async () => {
    if (validateForm()) {
      setIsLoaderActive(true);
      window.onbeforeunload = () => true;
      // Set values that the user put in the input form
      deliveryData.internal_number = number_int;
      deliveryData.neighborhood = neighborhood as string;
      deliveryData.postal_code = postal_code as string;
      deliveryData.street = street as string;
      deliveryData.name = `${firstName} ${lastName}`;
      deliveryData.phone = phone;

      // Set empty placeholder values for the field not used
      deliveryData.references = "references";
      deliveryData.municipality = "municipality";
      deliveryData.number = "undefined";

      var createdAddress: EcommerceAddress | undefined = deliveryAddresses?.map((address) => EcommerceAddress.fromAddress(address))
        .find((deliveryAddress) => EcommerceAddress.toString(deliveryAddress) === EcommerceAddress.toString(deliveryData));

      if (!createdAddress) {
        var response = await funcAccountAddressPost(
          conversationContext?.accountProviderId as number,
          registerAccount?.account_id as string,
          deliveryData
        );

        if (!response || "data" in response) {
          setIsErrorMessageOpen(true);
          setErrorMessage(`Lo sentimos 😔, no he podido guardar tu dirección. Puedes intentarlo nuevamente.`);
          setErrorMessageButtonLink("");
          setErrorMessageButtonText("Ok");
          return;
        }
        createdAddress = EcommerceAddress.fromAddress(response as Address);
      }

      // Call to Switch the CartAddress with the new updated one to update the User Segmentation rules.
      await funcShoppingCartPostDefault(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        cartId,
        createdAddress.encoded_route_info as string,
        true,
        false,
      );

      createdAddress.is_new = false;
      dispatch(setDeliveryData(createdAddress));

      // Go to cart to confirmOrder
      setIsLoaderActive(false);
      window.onbeforeunload = () => undefined;
      window.location.replace(ProductsPageLink() + '#paymentSectionId');
    }
  };

  let addressForm = (
    <>
      <Typography variant="h1" className={classes.title}>
        Dirección de Entrega
      </Typography>

      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: firstNameError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          value={firstName}
          error={firstNameError !== ""}
          helperText={firstNameError}
          label="Nombre"
          variant="outlined"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: lastNameError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          value={lastName}
          error={lastNameError !== ""}
          helperText={lastNameError}
          label="Apellido"
          variant="outlined"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: phoneError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          defaultValue={phone}
          error={phoneError !== ""}
          helperText={phoneError}
          label="Teléfono"
          variant="outlined"
          onChange={(e) => setPhone(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGridSeparator}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
          }}
          className={classes.textFieldFilled}
          required
          label="Dirección"
          defaultValue={street}
          error={streetError !== ""}
          helperText={streetError}
          onChange={(e) => setStreet(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
          }}
          className={classes.textFieldFilled}
          required
          label="Zona"
          defaultValue={neighborhood}
          error={neighborhoodError !== ""}
          helperText={neighborhoodError}
          onChange={(e) => setNeighborhood(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
          }}
          className={classes.textFieldFilled}
          label="Complemento (opcional)"
          defaultValue={number_int}
          error={numberIntError !== ""}
          helperText={numberIntError}
          onChange={(e) => setNumberInt(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: postalCodeError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          className={classes.textFieldFilled}
          required
          label="Código Postal"
          defaultValue={postal_code}
          error={postalCodeError !== ""}
          helperText={postalCodeError}
          onChange={(e) => setPostalCode(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textFieldFilled}
          InputLabelProps={{
            classes: {
              root: classes.disabledTextFieldLabelRoot,
              focused: classes.disabledTextFieldLabelFocus,
              error: classes.disabledTextFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.disabledTextFieldInputRoot,
              focused: classes.disabledTextFieldInputFocus,
              notchedOutline: classes.disabledTextFieldInputOutlined,
              error: classes.disabledTextFieldInputError,
            },
          }}
          required
          disabled
          label="Departamento"
          defaultValue={availabilityZone?.address.region}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textFieldFilled}
          InputLabelProps={{
            classes: {
              root: classes.disabledTextFieldLabelRoot,
              focused: classes.disabledTextFieldLabelFocus,
              error: classes.disabledTextFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.disabledTextFieldInputRoot,
              focused: classes.disabledTextFieldInputFocus,
              notchedOutline: classes.disabledTextFieldInputOutlined,
              error: classes.disabledTextFieldInputError,
            },
          }}
          required
          disabled
          label="Ciudad"
          defaultValue={availabilityZone?.address.city}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <Button
          disabled={isLoaderActive}
          className={classes.mobileStylePrimary}
          color="primary"
          onClick={() => onSendClick()}
        >
          Proceder al Pago
        </Button>
      </Grid>
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );

  return isLoaderActive ? (
    <FullPageLoader
      type={LoaderType.Authentication}
      text="Procesando dirección..."
    />
  ) : (
    addressForm
  );
};
