import { useLoader } from "features/hooks/useLoader";
import { Loader } from "features/main/Loader";
import React, { createContext } from "react";

type LoaderContextProps = {
  showLoader: boolean;
  handleLoader: (content?: string, message?: string) => void;
  loaderImage: string;
  loaderMessage: string;
};
let LoaderContext: React.Context<LoaderContextProps>;
let { Provider } = (LoaderContext = createContext({
  showLoader: false,
  handleLoader: () => {},
  loaderImage: "",
  loaderMessage: "",
} as LoaderContextProps));

type LoaderProviderProps = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};
const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const { showLoader, handleLoader, loaderImage, loaderMessage } = useLoader();
  return (
    <Provider value={{ showLoader, handleLoader, loaderImage, loaderMessage }}>
      <Loader />
      {children}
    </Provider>
  );
};

export { LoaderContext, LoaderProvider };
