import { EcommerceProduct } from "models/ecommerceProduct";
import React from "react";
import TrackVisibility from "react-on-screen";
import { CartItem } from "./CartItem";

export const CartProduct = ({
  product,
  position,
  viewPosition,
}: {
  product: EcommerceProduct;
  position: string;
  viewPosition: number;
}) => {
  return (
    <TrackVisibility once>
      {({ isVisible }) => (
        <CartItem
          product={product}
          linkedProduct={product.linked_product!}
          position={position}
          viewPosition={viewPosition}
          isVisible={isVisible}
        ></CartItem>
      )}
    </TrackVisibility>
  );
};
