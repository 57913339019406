import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { RootState } from "app/store";
import downIcon from "assets/icons/ui/chevron-down.svg";
import enlargeIcon from "assets/icons/ui/enlarge.svg";
import { formatNumber } from "features/common/formatHelpers";
import { handleProductDetailView } from "features/common/gtmEventHandler";
import { EcommerceProduct } from "models/ecommerceProduct";
import { ReturnableTypes } from "models/returnableType";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DiscountBadge } from "./ProductBadges/DiscountBadge";
import { ReturnableBadge } from "./ProductBadges/ReturnableBadge";
import { ProductDetailButtonGroup } from "./ProductButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "195px",
    padding: "10px",
  },
  badgeContainer: {
    textAlign: "center",
    minHeight: "29px",
    flexWrap: "wrap",
    width: "100%",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  listItemText: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  listItemTextTypography: {
    width: "50%",
  },
  productPrice: {
    marginTop: "0px",
  },
  productDiscountPrice: {
    marginTop: "0px",
    color: "#E02020",
  },
  originalPrice: {
    textDecoration: "line-through",
    color: "#807F80",
    fontSize: "10px",
  },
}));

export const ProductDetail = () => {
  const classes = useStyles();
  const location = useLocation();
  const [search] = useSearchParams();

  const { accountProvider } = useSelector((state: RootState) => state.account);

  const { product, position, viewPosition } = location.state;

  const isReturnable = Object.values(ReturnableTypes).includes(product?.returnable_type!);
  useEffect(() => {
    handleProductDetailView(
      product,
      position,
      viewPosition,
      accountProvider?.country_id,
      accountProvider?.currency,
    );
  }, []);
  return (
    <Grid container className={classes.root} justifyContent="space-evenly">
      <Grid item container direction="column" style={{ marginBottom: "16px" }} xs={12} sm={12} md={5} spacing={1}>
        <Grid item className={classes.badgeContainer} container spacing={1}>
          <Grid style={{ borderRadius: "4px" }} item>
            {product?.discountPercent && product?.discountPercent > 0 ? (
              <DiscountBadge discountPercent={product?.discountPercent as number} />
            ) : null}
          </Grid>
          <Grid item style={{ borderRadius: "4px" }}>
            {isReturnable && <ReturnableBadge returnableType={product?.returnable_type!} />}
          </Grid>
        </Grid>
        <Grid item style={{ margin: "auto" }}>
          <div style={{ position: "relative" }}>
            <img style={{ display: "block", margin: "auto" }} src={product?.image_url} alt={product?.shortname} />
            <div style={{ position: "absolute", right: 10, bottom: 10 }}>
              <Link
                to={{ pathname: "/product/image", search: `?contextId=${search.get("contextId")}` }}
                state={{ url: product?.image_url }}
              >
                <img src={enlargeIcon} alt="enlarge" />
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            {product?.discountPercent! > 0 && (
              <Grid item style={{ textAlign: "left", width: "100%" }}>
                <Typography variant="body2" className={classes.originalPrice}>
                  {accountProvider?.currency_symbol}
                  {formatNumber(
                    EcommerceProduct.totalPrice(product!) + EcommerceProduct.totalDiscount(product!),
                    accountProvider?.amount_format,
                    accountProvider?.amount_separator
                  )}
                </Typography>
              </Grid>
            )}
            <Grid item style={{ textAlign: "left", width: "100%" }}>
              <Typography
                variant="h3"
                className={product?.discountPercent! > 0 ? classes.productDiscountPrice : classes.productPrice}
              >
                {accountProvider?.currency_symbol}
                {formatNumber(
                  EcommerceProduct.totalPrice(product!),
                  accountProvider?.amount_format,
                  accountProvider?.amount_separator
                )}
                <span style={{ fontSize: "10px", color: "#807F80" }}>/{product?.net_content}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <ProductDetailButtonGroup product={product} position={position} viewPosition={viewPosition} />
          </Grid>
        </Grid>
        <Grid item>
          <Typography style={{ fontWeight: 400, marginBottom: "4px" }} variant="h2">
            {product?.shortname}
          </Typography>
          <Typography style={{ color: "#807F80", marginTop: "4px", marginBottom: "4px" }} variant="body1">
            {product?.net_content}
          </Typography>
          <Typography style={{ marginTop: "4px" }} variant="body1">
            {product?.packaging}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={12} sm={12} md={6}>
        <Grid item>
          <Accordion
            aria-controls="additional-info-content"
            id="additional-info-header"
            defaultExpanded={!isReturnable}
          >
            <AccordionSummary expandIcon={<img src={downIcon} alt="down" />}>
              <Typography variant="h4">Información adicional</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
              <Grid item>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: product?.long_description }} />
        </Grid>
                <Divider style={{ marginTop: "15px", backgroundColor: "#d4d4d4" }} />
                <Grid item>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        disableTypography={true}
                        className={classes.listItemText}
                        primary={
                          <Typography className={classes.listItemTextTypography} variant="h5">
                            Sabor
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.listItemTextTypography} variant="body2">
                            {product?.flavor}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        disableTypography={true}
                        className={classes.listItemText}
                        primary={
                          <Typography className={classes.listItemTextTypography} variant="h5">
                            Material
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.listItemTextTypography} variant="body2">
                            {product?.material}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        disableTypography={true}
                        className={classes.listItemText}
                        primary={
                          <Typography className={classes.listItemTextTypography} variant="h5">
                            Contenido Neto
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.listItemTextTypography} variant="body2">
                            {product?.net_content}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        disableTypography={true}
                        className={classes.listItemText}
                        primary={
                          <Typography className={classes.listItemTextTypography} variant="h5">
                            SKU
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.listItemTextTypography} variant="body2">
                            {product?.sku}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        disableTypography={true}
                        className={classes.listItemText}
                        primary={
                          <Typography className={classes.listItemTextTypography} variant="h5">
                            Bottler SKU
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.listItemTextTypography} variant="body2">
                            {product?.product_id}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        {isReturnable ? (
          <Grid item>
            <Accordion aria-controls="whats-included-content" id="whats-included-header">
              <AccordionSummary expandIcon={<img src={downIcon} alt="down" />}>
                <Typography variant="h4">Qué está incluido</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  <Grid item>
                    {product?.returnable_type === ReturnableTypes.Liquid && (
                      <Typography variant="body1">
                        El precio incluye solo líquido, no incluye envase. Elige este producto solo si ya tienes tus
                        envases retornables y ahorra en cada pedido. Para más información consulta nuestro menú de FAQ
                        (Retornables) o nuestras políticas de envío.
                      </Typography>
                    )}
                    {product?.returnable_type === ReturnableTypes.StarterPack && (
                      <Typography variant="body1">
                        ¡Te damos la bienvenida al mundo de los retornables! El precio incluye el líquido y su envase
                        retornable. Para más información consulta nuestro menú de FAQ (Retornables) o nuestras políticas
                        de envío.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
