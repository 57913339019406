import { RootState } from 'app/store';
import { setSearchFilter } from 'features/common/accountSlice';
import { funcCatalogueGetById } from 'features/common/API';
import {
  setSelectedSubcategory,
  toggleParentCategory,
  toggleSelectedOption,
  setComboCategories,
  setCategories,
  BaseCategory,
} from 'features/common/categorySlice';
import { GTM_LIST_NAME_CHILD_CATEGORY, GTM_LIST_NAME_PARENT_ALL_SUBCATEGORIES, GTM_LIST_NAME_PARENT_CATEGORY } from 'features/common/gtmEventHandler';
import { setListName } from 'features/common/productSlice';
import { ProductsRelativeLink } from 'features/common/urlBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useProductDispatcher } from './useProductDispatcher';
import { useScroll } from './useScroll';

export const useCategoryDispatcher = () => {
  const { accountProvider, registerAccount } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { scrollToTop } = useScroll();
  const { dispatchProductByCategory, dispatchProductBySubCategory, dispatchProductByAllSubCategories } = useProductDispatcher();

  const dispatchCategory = (category: BaseCategory, listName?: string ) => {
    dispatch(setSearchFilter(''));
    dispatch(setSelectedSubcategory(''));
    dispatch(
      toggleParentCategory({
        name: category?.name,
        id: category.id,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
      })
    );
    dispatch(setListName({ listName: listName ?? `${GTM_LIST_NAME_PARENT_CATEGORY} - ${category.name}` }));
    dispatchProductByCategory(category);

    if (window.location.pathname !== '/products') {
      navigate(ProductsRelativeLink());
    }
    scrollToTop();
  };

  const dispatchAllSubcategories = (category: BaseCategory, listName?: string ) => {
    dispatch(setSearchFilter(''));
    dispatch(setSelectedSubcategory(''));
    dispatch(
      toggleParentCategory({
        name: category?.name,
        id: category.id,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
      })
    );
    dispatch(setListName({ listName: listName ?? `${GTM_LIST_NAME_PARENT_ALL_SUBCATEGORIES} - ${category.name}` }));
    dispatchProductByAllSubCategories(category);

    if (window.location.pathname !== '/products') {
      navigate(ProductsRelativeLink());
    }
    scrollToTop();
  };

  const dispatchSubcategory = (subcategory: BaseCategory, listName?: string) => {
    dispatch(setSearchFilter(''));
    dispatch(toggleSelectedOption({ id: subcategory.id, countryId: accountProvider?.country_id }));
    dispatch(setListName({ listName: listName ?? `${GTM_LIST_NAME_CHILD_CATEGORY} - ${subcategory.name}` }));
    dispatchProductBySubCategory(subcategory);

    if (window.location.pathname !== '/products') {
      navigate(ProductsRelativeLink());
    }
    scrollToTop();
  };

  const fetchCategories = async (errorHandler: () => void = () => {}) => {
    const accountProviderId = accountProvider?.key as number;
    const accountId = registerAccount?.account_id as string;
    const warehouseCode = deliveryData.warehouse_code;
    const Categories: EcommerceCategories[] = (await funcCatalogueGetById(
      accountProvider?.key as number,
      registerAccount?.account_id as string,
      warehouseCode
    )) as EcommerceCategories[];

    if (!Categories) {
      errorHandler();
      return {
        categories: undefined,
        subcategories: undefined,
        comboCategories: undefined,
      };
    }

    var categories = Categories?.filter((category) => category.parent_id === "0");
    const subcategories = Categories?.filter((category) => category.parent_id !== "0");

    // Dont use categories with no child subcategories.
    categories = categories.filter(
      (category) => subcategories.filter((sub) => sub.parent_id === category.child_id).length > 0
    );

    const bottlers: Bottler[] = JSON.parse(accountProvider?.bottlers ?? "[]");
    const comboCategoryId = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.combo_category_id;
    const comboSubcategoryId = "999999999999999";
    const discountSubcategoryId = "9999999999999998";
    const comboName = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.combo_name;
    const categoriesOrder = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.categories_order;

    const comboCategories = {
      comboCategoryId,
      comboSubcategoryId,
      discountSubcategoryId,
      isArtificial: !categories.some((category) => category.child_id === comboCategoryId)
    };

    dispatch(setComboCategories(comboCategories));

    // Sorting categories
    if (categoriesOrder !== undefined) {
      var sortedCategories: EcommerceCategories[] = [];
      var unsortedCategories: EcommerceCategories[] = [];
      categoriesOrder.forEach((e) => {
        var match = categories.find((x) => x.child_id === e.toString());
        if (match !== null && match !== undefined) {
          sortedCategories.push(match);
        }
      });

      // Adding unsorted categories if not especified on the config array.
      categories.forEach((c) => {
        var exist = sortedCategories.find((x) => x.child_id === c.child_id);
        if (exist === null || exist === undefined) {
          unsortedCategories.push(c);
        }
      });

      categories = sortedCategories.concat(unsortedCategories);
    }

    const comboCategoryIndex = categories?.findIndex((category) => category.child_id === comboCategoryId);

    if (comboCategoryIndex !== -1) {
      const comboCategory = categories.splice(comboCategoryIndex, 1);
      categories.unshift(comboCategory[0]);
    } else if (comboCategoryId) {
      // Create artificial promo category
      const comboCategory: EcommerceCategories = {
        account_provider_id: accountProviderId,
        account_id: accountId,
        parent_id: "0",
        group: comboName as string,
        child_id: comboCategoryId,
        order: 0,
        description: comboName as string,
        image_url: "",
        is_alcoholic: false,
        non_alcoholic_description: comboName as string,
      };

      const comboSubCategory: EcommerceCategories[] = [
        {
          account_provider_id: accountProviderId,
          account_id: accountId,
          parent_id: comboCategoryId,
          group: "Descuentos", // comboName as string,
          child_id: discountSubcategoryId,
          order: 0,
          description: "Descuentos", // comboName as string,
          image_url: "",
          is_alcoholic: false,
          non_alcoholic_description: "Descuentos", // comboName as string,
        },
        {
          account_provider_id: accountProviderId,
          account_id: accountId,
          parent_id: comboCategoryId,
          group: "Combos", // comboName as string,
          child_id: comboSubcategoryId,
          order: 0,
          description: "Combos", // comboName as string,
          image_url: "",
          is_alcoholic: false,
          non_alcoholic_description: "Combos", // comboName as string,
        },
      ];

      categories.unshift(comboCategory);
      subcategories.unshift(...comboSubCategory);
    }

    // Set Categories on slice
    dispatch(setCategories({ categories, subcategories }));
    return {
      categories,
      subcategories,
      comboCategories
    };
  }

  return { dispatchCategory, dispatchSubcategory, dispatchAllSubcategories, fetchCategories };
};
