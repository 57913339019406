import { RootState } from 'app/store';
import CokeLoading from 'assets/coke_loading.svg';
import { funcShoppingCartAdd, funcShoppingCartGetDefault, funcShoppingCartUpdate } from 'features/common/API';
import { loadShoppingCart } from 'features/common/productSlice';
import { LoaderContext } from 'features/contexts/loaderContext';
import { ModalContext } from 'features/contexts/modalContext';
import { PutShoppingCartAddItems, ShoppingCart, ShoppingCartProduct } from 'models/ecommerceCart';
import { ErrorRequestResponse } from 'models/errorRequestResponse';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useShoppingCart = () => {
  const { cart, cartId } = useSelector((state: RootState) => state.products);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);
  const { conversationContext, registerAccount } = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  const { handleLoader } = useContext(LoaderContext);

  const saveShoppingCart = async () => {
    const productsToAdd: ShoppingCartProduct[] = [];
    const productsToUpdate: ShoppingCartProduct[] = [];
    Object.keys(cart).forEach((productId) => {
      const product = cart[productId];
      if (product.has_changed) {
        if (product.product_id) productsToUpdate.push(product);
        else productsToAdd.push(product);
      }
    });

    let shoppingCart: ShoppingCart | undefined;
    let addShoppingCart: ShoppingCart | ErrorRequestResponse | undefined;
    let updateShoppingCart: ShoppingCart | ErrorRequestResponse | undefined;
    // Add products to shopping cart
    if (productsToAdd.length !== 0) {
      handleLoader(CokeLoading, 'Cargando productos...');
      var addProductsPayload: PutShoppingCartAddItems = {
        products: productsToAdd,
        cart_id: cartId,
        encoded_route_info: deliveryData.encoded_route_info,
      };

      addShoppingCart = await funcShoppingCartAdd(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        addProductsPayload
      );

      if (addShoppingCart && typeof addShoppingCart.status === 'string') shoppingCart = addShoppingCart as ShoppingCart;
    }

    if (productsToUpdate.length !== 0) {
      handleLoader(CokeLoading, 'Cargando productos...');
      var updateProductsPayload: PutShoppingCartAddItems = {
        products: productsToUpdate,
        cart_id: cartId,
        encoded_route_info: deliveryData.encoded_route_info,
      };

      updateShoppingCart = await funcShoppingCartUpdate(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        updateProductsPayload
      );

      if (updateShoppingCart && typeof updateShoppingCart.status === 'string')
        shoppingCart = updateShoppingCart as ShoppingCart;
    }

    if (
      (addShoppingCart && typeof addShoppingCart.status !== 'string') ||
      (updateShoppingCart && typeof updateShoppingCart.status !== 'string')
    ) {
      const shoppingCartAddError =
        updateShoppingCart && updateShoppingCart.status !== 'string'
          ? (updateShoppingCart as ErrorRequestResponse)
          : (addShoppingCart as ErrorRequestResponse);

      var errorMessage: string = shoppingCartAddError.data.error.toString();
      if (
        shoppingCartAddError &&
        shoppingCartAddError.status === 400 &&
        errorMessage.includes('Error ocurred adding the product with name') === true
      ) {
        var productName = errorMessage.slice(errorMessage.indexOf('name ') + 5);
        if (productName) {
          handleModal(`Lo sentimos 😔, el producto ${productName} no se ha agregado correctamente.`);
        }
      } else {
        // Unexpected error on Checkout call
        handleModal(`Lo sentimos 😔, no se han cargado correctamente los productos.`);
      }

      // Get cart to clear all products from the cart
      shoppingCart = await funcShoppingCartGetDefault(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        cartId
      );
    }

    if (shoppingCart) dispatch(loadShoppingCart(shoppingCart));
    if (productsToAdd.length !== 0 || productsToUpdate.length !== 0) handleLoader();
  };

  return { saveShoppingCart };
};
