import { useTheme } from "@material-ui/core";
import React, { forwardRef, useImperativeHandle } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { StepContent } from "./components/StepContent";
import { Tooltip } from "./components/Tooltip";
import { useTour } from "./hooks/useTour";

export interface MenuTourHandle {
  toggleTour: (open: boolean) => void;
}
interface MenuTourProps {
  menuRef: React.RefObject<HTMLButtonElement>;
}
export const MenuTour = forwardRef<MenuTourHandle, MenuTourProps>(({ menuRef }, ref) => {
  useImperativeHandle(ref, () => ({
    toggleTour: (open: boolean) => {
      setIsTourOpen(open);
      setStepIndex(1);
    },
  }));
  const { isTourOpen, setIsTourOpen, setStatus, stepIndex, setStepIndex } = useTour("MenuTour");
  const theme = useTheme();
  const menuSteps: Step[] = [
    {
      content: (
        <StepContent title="¡Nos actualizamos!" message="¡Puedes encontrar el menú de categorías en este botón!" />
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: theme.zIndex.drawer + 1,
        },
      },
      target: menuRef.current!,
      title: "Menu",
    },
  ];

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === STATUS.FINISHED) {
      setStatus(status);
    }
  };
  return (
    <Joyride
      run={isTourOpen}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      steps={menuSteps}
      tooltipComponent={Tooltip}
      styles={{
        options: {
          arrowColor: "rgba(0,0,0,.7)",
        },
      }}
    />
  );
});
