// @ts-nocheck
/* eslint-disable no-undef */

import $ from 'jquery';
import { funcRegisterPostCDS } from 'features/common/API';
import { CCETHLogin } from 'features/common/urlBuilder';

export const finalizeMagentoLogin = (accountProviderId: number, userData: CDSUser, refererUrl: string, phone: string, action: string, country_id?: string) => {
	const cdsCustomer: PostCDSCustomer = {
		device_id: localStorage.deviceId,
		token_id: localStorage.token || cds.currentUser.getUserToken(),
		user: userData,
		phone: phone,
		action: action,
	};
	$('body').trigger('processStart');
	funcRegisterPostCDS(accountProviderId, userData.email, cdsCustomer)
	.then((res) => {
		if (refererUrl) {
			window.location.replace(refererUrl);
		} else {
			window.location.reload();
		}
	})
	.catch(function(err) {
		$('body').trigger('processStop');
		// redirect to CCETH web in cases like wrong contextId or CDSlogin of outside experiences accounts example: https://stage.sprite.com.mx/registro.html
		window.location.assign(CCETHLogin(country_id))
	});
};
