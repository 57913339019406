export class ErrorRequestResponse {
  public constructor(
    public status: number,
    public data: ErrorRequestResponseData,
  ) {}
  
  static isErrorRequestResponse(value: any) {
    const valueAsError = value as ErrorRequestResponse;
    const valueData = valueAsError?.data as ErrorRequestResponseData;

    return valueAsError?.status !== undefined && typeof valueAsError.status === 'number' && valueData !== undefined && typeof valueData?.error === 'string';
  }
}

export interface ErrorRequestResponseData {
  error: string;
}