import { Card, makeStyles, Typography } from "@material-ui/core";
import { RootState } from "app/store";
import { formatNumber } from "features/common/formatHelpers";
import { useScroll } from "features/hooks/useScroll";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    height: 44,
    position: "fixed",
    bottom: "8px",
    left: "16px",
    right: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
  },
}));

export const CartTotal = () => {
  const classes = useStyles();

  const productsState = useSelector((state: RootState) => state.products);
  const shipping = productsState.shipping || 0;
  const total = productsState.total || 0;
  const { accountProvider } = useSelector((state: RootState) => state.account);

  const [enabled, setEnabled] = useState(
    total + shipping >= (accountProvider?.minimum_order_amount || 0) ? true : false
  );
  const { isBottomScroll, scrollToElement } = useScroll();

  useEffect(() => {
    setEnabled(total + shipping >= (accountProvider?.minimum_order_amount || 0) ? true : false);
  }, [total, shipping]);

  const goCart = () => {
    if (enabled) {
      scrollToElement("cart-container", 160);
    }
  };

  return (
    <Card
      className={classes.root}
      id="totalScrollDownButton"
      style={{
        backgroundColor: enabled ? "#000" : "Gray",
        color: enabled ? "White" : "#000000",
        display: isBottomScroll ? "none" : "flex",
      }}
      onClick={goCart}
      variant="outlined"
    >
      <div>
        <Typography variant="body1">
          Total: {accountProvider?.currency_symbol}
          {formatNumber(total + shipping, accountProvider?.amount_format, accountProvider?.amount_separator)}
        </Typography>
      </div>
    </Card>
  );
};
