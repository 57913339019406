import moment from "moment";

export function formatNumber(number : number, amountFormat?: string, amountSeparator?: string) { 
  var formatedNumber;
  if(amountFormat?.includes('.')){
    var decimalSize = amountFormat?.split('.')[1].length;
    formatedNumber = addSeparator(Number(number).toFixed(decimalSize),amountSeparator);
  }
  else{
    formatedNumber = addSeparator(number.toString(), amountSeparator);
  }
  return formatedNumber?.toString();
};

function addSeparator(formatedNumber : string, amountSeparator?: string) {
  //this regular expression adds separator giving a symbol for thousands
  if(amountSeparator !=""  && amountSeparator != null)
    return formatedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, amountSeparator);
};

export function dateAsInternalToDisplayFormat(
  date: Date,
  displayDateFormat: string | undefined
): string {
  return displayDateFormat
    ? moment(date).format(displayDateFormat.toUpperCase()).toString()
    : date.toString();
}

export const INTERNAL_CHATBOT_DATE_FORMAT = 'MM/DD/YYYY';

export const getProviderDefaultDeliveryDate = (accountProvider?: AccountProvider): EcommereceDeliveryDate[] | undefined => {
  let result: EcommereceDeliveryDate[] | undefined;
  let minDeliveryDateOffset = accountProvider?.parameters?.find((paramter) => paramter.key === 'defaultDeliveryDateMinOffset')?.value;
  let maxDeliveryDateOffset = accountProvider?.parameters?.find((paramter) => paramter.key === 'defaultDeliveryDateMaxOffset')?.value;
  let disabledDates = accountProvider?.parameters?.find((paramter) => paramter.key === 'defaultDeliveryDateDisabledDates')?.value;
  let disabledDays = accountProvider?.parameters?.find((paramter) => paramter.key === 'defaultDeliveryDateDisabledDays')?.value;
  
  if (minDeliveryDateOffset && maxDeliveryDateOffset) {
    const minDay = moment().add(Number(minDeliveryDateOffset), 'days').format(INTERNAL_CHATBOT_DATE_FORMAT);
    const maxDay = moment().add(Number(maxDeliveryDateOffset), 'days').format(INTERNAL_CHATBOT_DATE_FORMAT);
    result = [
      {
        delivery_day: minDay,
        min_day: minDay,
        max_day: maxDay,
        disabled_dates: disabledDates ? disabledDates.split(';') : [],
        disabled_days: disabledDays ? disabledDays.split(';') : [],
      },
    ];
  }
  return result;
}
