import { Theme, makeStyles } from "@material-ui/core";
import scrollUp from "assets/scrollUpButton.svg";
import { useScroll } from "features/hooks/useScroll";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  scrollButtonsvg: {
    height: "50px",
    width: "50px",
    position: "fixed",
    top: "70%",
    right: 2,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const ScrollUp = () => {
  const { isTopScroll, scrollToTop } = useScroll();
  const classes = useStyles();
  return (
    <div
      style={{
        display: isTopScroll ? "none" : "block",
      }}
      onClick={scrollToTop}
    >
      <img id="scrollUpButton" className={classes.scrollButtonsvg} alt="logo" src={scrollUp} />{" "}
    </div>
  );
};
