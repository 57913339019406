import { Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import downArrow from "assets/downArrow.svg";
import React, { useState } from "react";
import { CategoryItem } from "./CategoryItem";

const useStyles = makeStyles((theme) => ({
  grid: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "16px",
  },
  label: {
    textAlign: "left",
    position: "relative",
    padding: "8px 0px 8px 16px",
    width: "171px",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  categoryList: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "16.6%",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
    },
  },
  toggleButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  paddingContent: {
    padding: "0px",
  },
}));

export const CategoryList = ({ categories, show }: { categories: EcommerceCategories[]; show: number }) => {
  const classes = useStyles();

  const [itemsToShow, setItemsToShow] = useState<number>(show);
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <Grid className={classes.grid}>
        <Typography variant="h2" className={classes.label}>
          Categorías
        </Typography>
        <Container className={classes.paddingContent}>
          <Grid item xs={12} sm={8} md={6} container spacing={0} className={classes.categoryList}>
            {categories.slice(0, itemsToShow).map((category) => (
              <Grid item xs={6}>
                <CategoryItem key={category.group} category={category}></CategoryItem>
              </Grid>
            ))}
            <Grid
              item
              style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}
              xs={12}
            >
              <Button
                onClick={() => {
                  expanded ? setItemsToShow(show) : setItemsToShow(categories.length);
                  setExpanded(!expanded);
                }}
                style={{
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {expanded ? (
                  <Typography variant="body2" className={classes.toggleButton}>
                    Ver menos <img style={{ padding: "8px", rotate: "180deg" }} src={downArrow} alt="location" />
                  </Typography>
                ) : (
                  <Typography variant="body2" className={classes.toggleButton}>
                    Ver más <img style={{ padding: "8px" }} src={downArrow} alt="location" />
                  </Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};
