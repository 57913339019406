import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleChildCategoryButtonClick, handleMainCategoryButtonClick } from './gtmEventHandler';

const cokeCatId = 59;

export class BaseCategory {
  public constructor(
    public id: number,
    public name: string,
    public is_alcoholic: boolean,
    public parent_id?: number
  ) {}

  static fromEcommerceCategory (category: EcommerceCategories) {
    return new BaseCategory(Number(category.child_id), category.group, category.is_alcoholic ?? false, category.parent_id ? Number(category.parent_id) : undefined);
  }
}
export interface Category extends BaseCategory {
  subcategories: Subcategory[];
}

export interface Subcategory extends BaseCategory {
  parent_id: number;
}

export interface ComboCategories {
  comboCategoryId?: string,
  comboSubcategoryId?: string,
  discountSubcategoryId?: string,
  isArtificial?: boolean,
}

export interface CategoriesState {
  categories: Category[];
  subcategories: Subcategory[];
  selectedCategories: { [key: string]: boolean };
  selectedSubcategories: number[];
  selectedCategoryName: string;
  selectedSubcategoryName: string;
  comboCategories?: ComboCategories;
}

const initialState: CategoriesState = {
  categories: [],
  subcategories: [],
  selectedCategories: {},
  selectedSubcategories: [cokeCatId],
  selectedCategoryName: '',
  selectedSubcategoryName: '',
  comboCategories: undefined,
};

// Function that returns the updated selectedSubcategories of the state on selection of a subcategory
export const updateSelectedSubcategories = (state: CategoriesState, categoryId: number): number[] => {
  return [categoryId];
};
// Function that returns all the subcategories that are subcategories of the given ParentCategoryName
export const updateSelectedSubcategoriesByParent = (state: CategoriesState, categoryId: number): number[] => {
  const selectedMainCategory: Category | undefined = state.categories.find((cat) => cat.id === categoryId);
  return selectedMainCategory ? [...selectedMainCategory.subcategories.map((sub) => sub.id)] : [];
};

const categories = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoryInitialState: (state: CategoriesState, action: PayloadAction<void>) => {
      return initialState;
    },
    setCategories: (
      state: CategoriesState,
      action: PayloadAction<{ categories: EcommerceCategories[]; subcategories: EcommerceCategories[] }>
    ) => {
      const categories = action.payload.categories;
      const subcategories = action.payload.subcategories;

      // If we have existing categories due to persistance, check if they are different from the current categories
      /*if (state.categories.length !== 0) {
        const currIds = state.categories.map((cat) => cat.id);
        const newIds = action.payload.categories.map((cat) => cat.id);

        // Categories are the same, no need to update
        if (currIds.length === newIds.length && currIds.every((val, i) => val === newIds[i])) return;
      }*/

      // Clear state and Map Categories
      state.selectedCategories = { ...initialState.selectedCategories };
      state.selectedSubcategories = [...initialState.selectedSubcategories];

      state.categories = categories.map((cat) => {
        state.selectedCategories[cat.group] = false;
        return {
          id: Number(cat.child_id),
          name: cat.group,
          is_alcoholic: cat.is_alcoholic ?? false,
          subcategories: subcategories
            .filter((sub) => sub.parent_id === cat.child_id)
            .map((sub) => {
              return {
                name: sub.group,
                id: Number(sub.child_id),
                parent_id: Number(cat.child_id),
                is_alcoholic: sub.is_alcoholic ?? false,
              };
            }),
        };
      });

      state.subcategories = subcategories.map((sub) => {
        return {
          name: sub.group,
          id: Number(sub.child_id),
          is_alcoholic: sub.is_alcoholic ?? false,
          parent_id: Number(sub.parent_id),
        };
      });
    },

    toggleSelectedCategory: (state: CategoriesState, action: PayloadAction<{ name: string; countryId?: string }>) => {
      let categoriesNames = Object.keys(state.selectedCategories);
      categoriesNames.forEach(
        (categoryName) =>
          (state.selectedCategories[categoryName] =
            categoryName !== action.payload.name ? false : !state.selectedCategories[categoryName])
      );
      handleMainCategoryButtonClick(action.payload.name, action.payload.countryId);
    },
    toggleSelectedOption: (state: CategoriesState, action: PayloadAction<{ id: number; countryId?: string }>) => {
      //Update the selected Subcategories
      state.selectedSubcategories = updateSelectedSubcategories(state, action.payload.id);

      // Find the data to send to GTM
      const selectedCategory = state.categories.find(
        (cat) => cat.subcategories.filter((sub) => sub.id === action.payload.id).length !== 0
      );
      const selectedSubcategory = selectedCategory?.subcategories.find((sub) => sub.id === action.payload.id);
      state.selectedCategoryName = selectedCategory?.name ?? '';
      state.selectedSubcategoryName = selectedSubcategory?.name ?? '';

      handleChildCategoryButtonClick(selectedCategory?.name, selectedSubcategory?.name, action.payload.countryId);
    },
    toggleParentCategory: (
      state: CategoriesState,
      action: PayloadAction<{ name: string; id: number; countryId?: string; currency?: string }>
    ) => {
      state.selectedSubcategories = updateSelectedSubcategoriesByParent(state, action.payload.id);
      state.selectedCategoryName = action.payload.name;

      handleMainCategoryButtonClick(action.payload.name, action.payload.countryId);
    },
    setSelectedCategory: (state: CategoriesState, action: PayloadAction<string>) => {
      state.selectedCategoryName = action.payload;
    },
    setSelectedSubcategory: (state: CategoriesState, action: PayloadAction<string>) => {
      state.selectedSubcategoryName = action.payload;
    },
    setComboCategories: (state: CategoriesState, action: PayloadAction<ComboCategories>) => {
      state.comboCategories = action.payload;
    }
  },
});

export const {
  setCategoryInitialState,
  setCategories,
  toggleSelectedCategory,
  toggleSelectedOption,
  toggleParentCategory,
  setSelectedCategory,
  setSelectedSubcategory,
  setComboCategories,
} = categories.actions;

export const categoriesReducer = categories.reducer;
